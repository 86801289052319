/* istanbul ignore file */
import client from '../client'
import UserMapper from '@web/mapper/UserMapper'
import Token from '@web/common/token'
import HeaderMapper from '@web/mapper/HeaderMapper'
import Mapper from '@web/mapper/Mapper'
import { User as IUser } from '@web/store/types/modules/user'

export default class User {
  /**
   * Авторизация пользователя
   *
   * @param email {string}
   * @param password {string}
   * @returns {*}
   */
  static login ({ email, password }): Promise<{
    data: IUser;
    headers: Headers;
  }> {
    return client.post('/auth/login', {
      email,
      password
    })
      .then(response => {
        if (response.data.success) {
          Token.set(response.data.data.token)
          return {
            data: UserMapper.toClient(response.data.data.user),
            headers: HeaderMapper.toClient(response.headers)
          }
        } else {
          throw { errors: response.data.errors } // eslint-disable-line
        }
      })
  }

  /**
   * Авторизация пользователя по appId
   *
   * @param appId {string}
   * @param password {string}
   * @returns {*}
   */
  static loginByAppId ({ appId, password }: { appId: string; password: string }): Promise<{
    data: IUser;
    headers: Headers;
  }> {
    return client.post('/auth/login-by-app-id', {
      // eslint-disable-next-line camelcase
      app_id: appId,
      password
    })
      .then(response => {
        if (response.data.success) {
          Token.set(response.data.data.token)
          return {
            data: UserMapper.toClient(response.data.data.user),
            headers: HeaderMapper.toClient(response.headers)
          }
        } else {
          throw { errors: response.data.errors } // eslint-disable-line
        }
      })
  }

  /**
   * Загрузка данных об авторизованном юзере
   *
   * @param requestStatistic {boolean}
   * @returns {*}
   */
  static request ({ requestStatistic } = {} as { requestStatistic?: boolean }): Promise<{
    data: IUser;
    headers: Headers;
  }> {
    const options: { params?: { expand: string } } = {}
    if (requestStatistic) {
      options.params = {
        expand: 'scores_amount,viewed_hours,completed_courses,completed_tasks,' +
          'viewed_lessons,referral_orders,cashback,discount_for_referral,link_jumps,user_external_auths'
      }
    }
    return client.get('/user', options)
      .then((response) => {
        return {
          data: UserMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  /**
   * Обновление информации о пользователе
   *
   * @param user
   * @returns {*}
   */
  static update (user: Partial<IUser>): Promise<{
    data: IUser;
  }> {
    const formData = new FormData()
    user = UserMapper.toServer(user)
    for (const name in user) {
      if (!Object.prototype.hasOwnProperty.call(user, name) || name === 'avatar') continue
      formData.append(name, user[name])
    }

    return client.request('/user',
      {
        method: 'post',
        body: formData,
        config: {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      })
      .then(async (response) => {
        const data = await response.json()

        return {
          data,
          headers: response.headers
        }
      })
      .then(({ data, headers }) => {
        if (data.success) {
          return {
            headers: HeaderMapper.toClient(headers),
            data: UserMapper.toClient(data.data.user)
          }
        } else {
          throw { errors: data.errors } // eslint-disable-line
        }
      })
  }

  /**
   * Отписка/подписка email-рассылки
   *
   * @returns {*}
   */
  static notification (user: IUser | { disableNotification: boolean; }): Promise<{
    data: IUser;
  }> {
    const formData = new FormData()
    formData.append('disable_notification', UserMapper.toServer(user).disable_notification)

    return client.request('/user/notification',
      {
        method: 'post',
        body: formData,
        config: {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      })
      .then(async (response) => {
        const data = await response.json()

        return {
          data,
          headers: response.headers
        }
      })
      .then(({ data, headers }) => {
        if (data.success) {
          return {
            headers: HeaderMapper.toClient(headers),
            data: UserMapper.toClient(data.data.user)
          }
        } else {
          throw { errors: data.errors } // eslint-disable-line
        }
      })
  }

  /**
   * Обновление фото
   *
   * @returns {*}
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static avatar (user: any): Promise<{
    data: IUser;
  }> {
    const formData = new FormData()
    formData.append('avatar_file_id', user.avatar_file_id)

    return client.request('/user/avatar',
      {
        method: 'post',
        body: formData,
        config: {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      })
      .then(async (response) => {
        const data = await response.json()

        return {
          data,
          headers: response.headers
        }
      })
      .then(({ data, headers }) => {
        if (data.success) {
          return {
            headers: HeaderMapper.toClient(headers),
            data: UserMapper.toClient(data.data.user)
          }
        } else {
          throw { errors: data.errors } // eslint-disable-line
        }
      })
  }

  /**
   * Обновление или изменение пароля
   *
   * @param payload
   * @returns {*}
   */
  static password (payload) {
    return client.post('/user/update-password', UserMapper.toServer(payload))
      .then((response) => {
        if (response.data.success) {
          return {
            data: response.data.data,
            headers: HeaderMapper.toClient(response.headers)
          }
        } else {
          throw { errors: response.data.errors } // eslint-disable-line
        }
      })
  }

  /**
   * Восстановление пароля
   *
   * @param email
   * @returns {*}
   */
  static reset (email) {
    return client.post('/auth/request-password-reset', {
      email
    })
      .then((response) => {
        if (response.data.success) {
          return {
            data: response.data.data,
            headers: HeaderMapper.toClient(response.headers)
          }
        } else {
          throw { errors: response.data.errors } // eslint-disable-line
        }
      })
  }

  /**
   * Создание пользователя
   *
   * @param email
   * @param password
   * @param timezone
   */
  static create ({ email, password, timezone }) {
    const formData = new FormData()
    formData.append('username', email)
    formData.append('email', email)
    formData.append('password', password)
    formData.append('password_repeat', password)
    formData.append('timezone', timezone)

    return client.request('/auth/signup-by-email',
      {
        method: 'post',
        body: formData,
        config: {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      })
      .then(async (response) => {
        const data = await response.json()

        return {
          data,
          headers: response.headers
        }
      })
      .then(({ data, headers }) => {
        if (data.success) {
          Token.set(data.data.token)
          return {
            headers: HeaderMapper.toClient(headers),
            data: UserMapper.toClient(data.data.user)
          }
        } else {
          throw { errors: data.errors } // eslint-disable-line
        }
      })
  }

  /**
   * Создание пользователя по appId
   *
   * @param appId
   * @param password
   */
  static createByAppId (appId, password) {
    const formData = new FormData()
    formData.append('app_id', appId)
    formData.append('password', password)
    formData.append('password_repeat', password)

    return client.request('/auth/signup-by-app-id',
      {
        method: 'post',
        body: formData,
        config: {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      })
      .then(async (response) => {
        const data = await response.json()

        return {
          data,
          headers: response.headers
        }
      })
      .then(({ data, headers }) => {
        if (data.success) {
          Token.set(data.data.token)
          return {
            headers: HeaderMapper.toClient(headers),
            data: UserMapper.toClient(data.data.user)
          }
        } else {
          throw { errors: data.errors } // eslint-disable-line
        }
      })
  }

  static validateEmail (email): Promise<void> {
    return client.post('/auth/validate-signup-form', { email })
      .then((response) => {
        if (response.data['signupform-email']) {
          throw { errors: response.data['signupform-email'] } // eslint-disable-line
        }
      })
  }

  static updateEmail (email) {
    return client.post('/user/update-user', { email })
      .then((response) => {
        if (response.data.success) {
          return {
            data: UserMapper.toClient(response.data.data.user)
          }
        } else {
          throw { errors: response.data.errors } // eslint-disable-line
        }
      })
  }

  static updateStatus (status) {
    return client.post('/user/update-user', { status })
      .then((response) => {
        if (response.data.success) {
          return {
            data: UserMapper.toClient(response.data)
          }
        } else {
          throw { errors: response.data.errors } // eslint-disable-line
        }
      })
  }

  static validateAppId (appId): Promise<boolean> {
    return client.post('/auth/validate-signup-form', {
      // eslint-disable-next-line camelcase
      app_id: appId
    })
      .then((response) => {
        return !!response.data['signupform-app_id']
      })
  }

  /**
   * Получение привязанных соцсетей
   *
   * @returns {*}
   */
  static fetchExternalAuth () {
    return client.get('/user-external-auth')
      .then((response) => {
        return {
          data: response.data.map(Mapper.toClient),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  /**
   * Удаление привязки соцсети
   *
   * @param id - id external auth
   * @returns {*}
   */
  static deleteExternalAuth (id: number) {
    return client.delete(`/user-external-auths/${id}`)
      .then((response) => {
        return {
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  /**
   * Удаление пользователя
   *
   * @param user
   * @returns {*}
   */
  static delete (user: Partial<IUser>): void {
    const formData = new FormData()
    user = UserMapper.toServer(user)
    for (const name in user) {
      if (!Object.prototype.hasOwnProperty.call(user, name) || name === 'avatar') continue
      formData.append(name, user[name])
    }

    client.request('/user',
      {
        method: 'delete',
        body: formData,
        config: {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      })
  }
}
