/* istanbul ignore file */
export default class InvalidParamError extends Error {
  constructor (...params: string[]) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InvalidParamError)
    }

    this.name = 'InvalidParamError'
  }
}
