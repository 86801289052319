
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    notice: {
      type: [String, Array],
      default: ''
    }
  }
})
