
import { defineComponent, computed } from 'vue'

import { useStore } from '@web/store'

export default defineComponent({
  name: 'AsSupportNotAuth',
  setup () {
    const store = useStore()

    const whatsAppNumbers = {
      iqface: 'https://wa.me/79260214182',
      anyclass: 'https://wa.me/79252407332'
    }

    const projectTitle = computed<string>(() => store.getters['assets/projectTitle'])
    const supportEmail = computed<string>(() => store.getters['assets/supportEmail'])
    const whatsAppNumber = computed<string>(() => store.getters['assets/whatsAppNumber'] || whatsAppNumbers[projectTitle.value.toLowerCase()])

    return {
      supportEmail,
      whatsAppNumber
    }
  }
})
