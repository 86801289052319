/* istanbul ignore file */
import Logger from 'js-logger'

let defaultLevel = Logger.OFF

if (process.env.NODE_ENV === 'development') {
  defaultLevel = Logger.TRACE
}

if (process.env.VUE_APP_LOG_LEVEL && Logger[process.env.VUE_APP_LOG_LEVEL]) {
  defaultLevel = Logger[process.env.VUE_APP_LOG_LEVEL]
}

const url = new URL(window.location.href)
if (url.searchParams.has('debug')) {
  defaultLevel = Logger.TRACE
}

Logger.useDefaults({
  defaultLevel
})

export default Logger
