import Mapper from './Mapper'
import ISO6391 from 'iso-639-1'

interface VideoF extends FileVideo {
  subs: Subs[];
  jsPlayerConfig?: {
    playlist: {
      sources: {
        file: string;
      }[];
    };
    image: string;
  };
  videoUrls?: {
    hlsPlaylist: string;
    preview: string;
    mp4Playlist: {
      [key: number]: string;
    };
  };
}
/* istanbul ignore next */
export default class VideoMapper extends Mapper {
  static toClient (data: Dictionary<unknown>): FileVideo {
    const video = Mapper.converter({
      data,
      rules: {
        // eslint-disable-next-line camelcase
        created_at: {
          cb: value => value * 1000
        },
        url: {
          baseUrl: true
        },
        duration: {
          cb: value => value * 1000
        },
        subtitles: {
          rename: 'subs',
          cb: value => {
            if (!value) {
              return []
            }

            let subs = []
            if (!Array.isArray(value)) {
              subs = Object.values(value)
            }

            return subs.map((s: {
              // eslint-disable-next-line camelcase
              language_code: string;
              link: string;
              name: string;
            }) => {
              const languageCode = s.language_code.replace(/_/g, '-')
              const code = languageCode.split('-')[0]
              const label = ISO6391.getNativeName(code) || languageCode
              return {
                languageCode,
                label,
                name: s.name,
                url: s.link
              }
            })
          }
        }
      },
      defaults: {
        courseIds: [],
        subs: []
      }
    }) as VideoF
    if (video.jsPlayerConfig) {
      delete video.jsPlayerConfig
    }

    if (video.videoUrls) {
      video.m3u8 = video.videoUrls.hlsPlaylist
      video.preview = video.videoUrls.preview
      video.mp4 = video.videoUrls.mp4Playlist

      delete video.videoUrls
    }

    return video
  }
}
