import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["data-analytics-element-target", "data-analytics-element-id", "data-analytics-element-name", "data-cy", "onClick"]
const _hoisted_2 = { class: "asMenu_text" }
const _hoisted_3 = { class: "asMenu_name" }
const _hoisted_4 = {
  key: 0,
  class: "asMenu_note"
}
const _hoisted_5 = ["href", "target", "data-analytics-element-target", "data-analytics-element-id", "data-analytics-element-name", "data-cy"]
const _hoisted_6 = { class: "asMenu_text" }
const _hoisted_7 = { class: "asMenu_name" }
const _hoisted_8 = {
  key: 0,
  class: "asMenu_note"
}
const _hoisted_9 = ["onClick", "data-analytics-element-target", "data-analytics-element-id", "data-analytics-element-name", "data-cy"]
const _hoisted_10 = { class: "asMenu_text" }
const _hoisted_11 = { class: "asMenu_name" }
const _hoisted_12 = {
  key: 0,
  class: "asMenu_note"
}
const _hoisted_13 = { class: "asMenu_text" }
const _hoisted_14 = { class: "asMenu_name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_external_document = _resolveComponent("as-external-document")!
  const _component_modal_choose_club = _resolveComponent("modal-choose-club")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(['asMenu', `${_ctx.mini ? '-mini' : ''}`, _ctx.classes])
        }, [
          (_ctx.minify)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["asMenu_arrow", { '-rotate': _ctx.mini }]),
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handlerMinify && _ctx.handlerMinify(...args))),
                "data-analytics": "",
                "data-analytics-element-type": "other",
                "data-analytics-element-id": "toolbar_btn"
              }, null, 2))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (!item.type || item.type === 'router-link')
                ? (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["asMenu_item", [
              `-${i}`,
               _ctx.mini ? '-mini' : ''
            ]]),
                    key: i,
                    "data-analytics": "",
                    "data-analytics-element-type": "menu_sidebar",
                    "data-analytics-element-target": typeof item.link === 'string' ? item.link : item.link.name,
                    "data-analytics-element-id": i,
                    "data-analytics-element-name": item.name,
                    "data-cy": `menu_sidebar_${i}`,
                    onClick: ($event: any) => (_ctx.goTo(i, item))
                  }, [
                    _createElementVNode("i", {
                      class: _normalizeClass(["asMenu_icon", item.icon])
                    }, null, 2),
                    _createElementVNode("span", _hoisted_2, [
                      _createElementVNode("span", _hoisted_3, _toDisplayString(item.name), 1),
                      (item.note)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item.note), 1))
                        : _createCommentVNode("", true)
                    ])
                  ], 10, _hoisted_1))
                : _createCommentVNode("", true),
              (item.type === 'link')
                ? (_openBlock(), _createElementBlock("a", {
                    class: _normalizeClass(["asMenu_item", [
              `-${i}`,
               _ctx.mini ? '-mini' : ''
            ]]),
                    href: item.link,
                    target: item.target,
                    key: i,
                    "data-analytics": "",
                    "data-analytics-element-type": "menu_sidebar",
                    "data-analytics-element-target": typeof item.link === 'string' ? item.link : item.link.name,
                    "data-analytics-element-id": i,
                    "data-analytics-element-name": item.name,
                    "data-cy": `menu_sidebar_${i}`
                  }, [
                    _createElementVNode("i", {
                      class: _normalizeClass(["asMenu_icon", item.icon])
                    }, null, 2),
                    _createElementVNode("span", _hoisted_6, [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(item.name), 1),
                      (item.note)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(item.note), 1))
                        : _createCommentVNode("", true)
                    ])
                  ], 10, _hoisted_5))
                : _createCommentVNode("", true),
              (item.type === 'action')
                ? (_openBlock(), _createElementBlock("span", {
                    class: _normalizeClass(["asMenu_item", [
              `-${i}`,
               _ctx.mini ? '-mini' : ''
            ]]),
                    onClick: item.action,
                    key: i,
                    "data-analytics": "",
                    "data-analytics-element-type": "menu_sidebar",
                    "data-analytics-element-target": typeof item.link === 'string' ? item.link : item.link.name,
                    "data-analytics-element-id": i,
                    "data-analytics-element-name": item.name,
                    "data-cy": `menu_sidebar_${i}`
                  }, [
                    _createElementVNode("i", {
                      class: _normalizeClass(["asMenu_icon", item.icon])
                    }, null, 2),
                    _createElementVNode("span", _hoisted_10, [
                      _createElementVNode("span", _hoisted_11, _toDisplayString(item.name), 1),
                      (item.note)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(item.note), 1))
                        : _createCommentVNode("", true)
                    ])
                  ], 10, _hoisted_9))
                : _createCommentVNode("", true),
              (item.type === 'modal')
                ? (_openBlock(), _createElementBlock("div", {
                    key: i,
                    class: _normalizeClass(["asMenu_item", [
              `-${i}`,
               _ctx.mini ? '-mini' : ''
            ]])
                  }, [
                    _createElementVNode("i", {
                      class: _normalizeClass(["asMenu_icon", `${item.icon}`])
                    }, null, 2),
                    _createVNode(_component_as_external_document, {
                      doc: {
                url: item.link,
                title: item.name
              },
                      "is-modal": true,
                      icon: false,
                      class: "asMenu_text"
                    }, null, 8, ["doc"])
                  ], 2))
                : _createCommentVNode("", true),
              (item.type === 'club')
                ? (_openBlock(), _createElementBlock("div", {
                    key: i,
                    class: _normalizeClass(["asMenu_item", [
              `-${i}`,
               _ctx.mini ? '-mini' : ''
            ]]),
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handlerClubLookAll && _ctx.handlerClubLookAll(...args)))
                  }, [
                    _createElementVNode("i", {
                      class: _normalizeClass(["asMenu_icon", item.icon])
                    }, null, 2),
                    _createElementVNode("span", _hoisted_13, [
                      _createElementVNode("span", _hoisted_14, _toDisplayString(item.name), 1)
                    ])
                  ], 2))
                : _createCommentVNode("", true)
            ], 64))
          }), 256))
        ], 2)
      ]),
      _: 1
    }),
    _createVNode(_component_modal_choose_club, {
      modelValue: _ctx.showModalChooseClub,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showModalChooseClub) = $event)),
      communities: _ctx.communitiesModalChooseClub,
      onChooseClub: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handlerChooseClub($event)))
    }, null, 8, ["modelValue", "communities"])
  ], 64))
}