import Mapper from './Mapper'
import getBaseUrl from '@web/common/getBaseUrl'

/* istanbul ignore next */
export default class CatalogMapper extends Mapper {
  static toClient (data) {
    return Mapper.converter({
      data,
      rules: {
        image: {
          cb: (image): string => {
            if (image.indexOf(getBaseUrl() || '') !== 0) {
              return getBaseUrl(image)
            }

            return image
          }
        }
      }
    })
  }
}
