import VideoConsultationsApi from '@web/api/modules/VideoConsultations'

import {
  VideoConsultationState,
  VideoConsultationGettersTree,
  VideoConsultationMutationsTree,
  VideoConsultationActionsTree
} from '@web/store/types/modules/videoConsultation'
import InvalidParamError from '@web/common/errors/InvalidParamError'

const state: VideoConsultationState = {
  purchasedVideoConsultations: {},
  availableVideoConsultations: [],
  isLoadedAllPurchased: false,
  isLoadedAllAvailable: false
}

const getters: VideoConsultationGettersTree = {
  purchasedVideoConsultations: ({ purchasedVideoConsultations }) => Object.values(purchasedVideoConsultations),
  getPurchasedVideoConsultationsById: ({ purchasedVideoConsultations }) => (id: number) => Object.values(purchasedVideoConsultations).find(c => c.id === id),
  availableVideoConsultations: ({ availableVideoConsultations }) => availableVideoConsultations,
  getAvailableVideoConsultationById: ({ availableVideoConsultations }) => (id: number) => availableVideoConsultations.find(c => c.id === id),
  isLoadedAllPurchased: ({ isLoadedAllPurchased }) => isLoadedAllPurchased,
  isLoadedAllAvailable: ({ isLoadedAllAvailable }) => isLoadedAllAvailable
}

const addPurchasedVideoConsultation = (state, videoConsultation) => {
  state.purchasedVideoConsultations[videoConsultation.id] = videoConsultation
}

const mutations: VideoConsultationMutationsTree = {
  setPurchasedVideoConsultations (state, videoConsultations) {
    videoConsultations.forEach(videoConsultation => {
      addPurchasedVideoConsultation(state, videoConsultation)
    })
  },
  setPurchasedVideoConsultation: addPurchasedVideoConsultation,
  setAvailableVideoConsultations (state, videoConsultations) {
    state.availableVideoConsultations = videoConsultations
  },
  setStatusLoadAllPurchased (state, status) {
    state.isLoadedAllPurchased = status
  },
  setStatusLoadAllAvailable (state, status) {
    state.isLoadedAllAvailable = status
  },
  logout (state) {
    state.purchasedVideoConsultations = {}
    state.availableVideoConsultations = []
    state.isLoadedAllPurchased = false
    state.isLoadedAllAvailable = false
  }
}

/* istanbul ignore next */
const actions: VideoConsultationActionsTree = {
  fetchPurchasedVideoConsultations ({ commit }) {
    return VideoConsultationsApi.fetchAll()
      .then(({ data }) => {
        commit('setPurchasedVideoConsultations', data)
        commit('setStatusLoadAllPurchased', true)
      })
      .catch(e => {
        throw e
      })
  },
  fetchPurchasedVideoConsultationsCached ({ getters, dispatch }) {
    if (!getters.isLoadedAllPurchased) {
      return dispatch('fetchPurchasedVideoConsultations')
    }
  },
  fetchAvailableVideoConsultations ({ commit }) {
    return VideoConsultationsApi.fetchAvailableVideoConsultations()
      .then(({ data }) => {
        commit('setAvailableVideoConsultations', data)
        commit('setStatusLoadAllAvailable', true)
      })
      .catch(e => {
        throw e
      })
  },
  fetchAvailableVideoConsultationsCached ({ getters, dispatch }) {
    if (!getters.isLoadedAllAvailable) {
      return dispatch('fetchAvailableVideoConsultations')
    }
  },
  updateVideoConsultations ({ commit }, { id, time, note, phone }) {
    if (!id) {
      throw new InvalidParamError('Id must be set')
    }

    return VideoConsultationsApi.update({ id, time, note, phone })
      .then(({ data }) => {
        commit('setPurchasedVideoConsultation', data)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
