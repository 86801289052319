import Mapper from './Mapper'
import uniq from 'lodash.uniq'

export enum InlinePosition {
  desktopCoursesTop = 1,
  desktopCoursesBottom = 2,
  desktopCoursesSidebar = 3,
  desktopCoursesBeforeCourse = 4,
  desktopCalendarTop = 5,
  desktopCalendarBottom = 6,
  desktopCalendarSidebar = 7,
  desktopDayTop = 8,
  desktopDayBottom = 9,
  desktopDayBeforeLessons = 10,
  desktopDayInsteadVideo = 11,
  desktopHomeworksTop = 12,
  desktopHomeworksBottom = 13,
  desktopHomeworksSidebar = 14,
  desktopVideoconsultationsTop = 15,
  desktopVideoconsultationsBottom = 16,
  desktopVideoconsultationsSidebar = 17,
  desktopSupportTop = 18,
  desktopSupportBottom = 19,
  desktopSupportSidebar = 20,
  desktopSettingsTop = 21,
  desktopSettingsBottom = 22,
  desktopSettingsSidebar = 23,
  mobileCoursesCatalog = 24,
  mobileCalendarBeforeStage = 25,
  mobileDayInsteadVideo = 26,
  mobileDayAfterVideo = 27,
  mobileDayBottom = 28,
  mobileHomeworksCatalog = 29,
  mobileVideoconsultationsCatalog = 30,
  mobileSupportCatalog = 31,
  mobileAppCourses = 32,
  mobileAppCourseDay = 33,
  mobileAppAll = 34,
  desktopMainPage = 10001,
  mobileMainPage = 20001
}

export enum Platform {
  desktop = 'desktop',
  tablet = 'tablet',
  mobile = 'mobile',
  app = 'app'
}

export enum Position {
  // web
  home = 'home',
  courses = 'courses',
  calendar = 'calendar',
  day = 'day',
  homeworks = 'homeworks',
  videoconsultations = 'videoconsultations',
  support = 'support',
  settings = 'settings',
  // app
  all = 'all',
  // TODO пока оставить
  mobileCourse = 'mobilecourse',
  mobileDay = 'mobileday',
  mobileAll = 'mobileall',
  Catalog = 'Catalog'
}

export enum Placement {
  top = 'top',
  sidebar = 'sidebar',
  beforeCourse = 'beforeCourse',
  beforeStage = 'beforeStage',
  beforeLessons = 'beforeLessons',
  insteadVideo = 'insteadVideo',
  afterVideo = 'afterVideo',
  bottom = 'bottom',
  catalog = 'catalog',
  mainPage = 'mainPage'
}

interface DestructurizationPosition {
  platform: string,
  position?: string,
  placement?: string,
}

function getDestructurizationPosition (position: number): DestructurizationPosition {
  switch (position) {
    case InlinePosition.desktopCoursesTop:
      return {
        platform: Platform.desktop,
        position: Position.courses,
        placement: Placement.top
      }
    case InlinePosition.desktopCoursesBottom:
      return {
        platform: Platform.desktop,
        position: Position.courses,
        placement: Placement.bottom
      }
    case InlinePosition.desktopCoursesSidebar:
      return {
        platform: Platform.desktop,
        position: Position.courses,
        placement: Placement.sidebar
      }
    case InlinePosition.desktopCoursesBeforeCourse:
      return {
        platform: Platform.desktop,
        position: Position.courses,
        placement: Placement.beforeCourse
      }
    case InlinePosition.desktopCalendarTop:
      return {
        platform: Platform.desktop,
        position: Position.calendar,
        placement: Placement.top
      }
    case InlinePosition.desktopCalendarBottom:
      return {
        platform: Platform.desktop,
        position: Position.calendar,
        placement: Placement.bottom
      }
    case InlinePosition.desktopCalendarSidebar:
      return {
        platform: Platform.desktop,
        position: Position.calendar,
        placement: Placement.sidebar
      }
    case InlinePosition.desktopDayTop:
      return {
        platform: Platform.desktop,
        position: Position.day,
        placement: Placement.top
      }
    case InlinePosition.desktopDayBottom:
      return {
        platform: Platform.desktop,
        position: Position.day,
        placement: Placement.bottom
      }
    case InlinePosition.desktopDayBeforeLessons:
      return {
        platform: Platform.desktop,
        position: Position.day,
        placement: Placement.beforeLessons
      }
    case InlinePosition.desktopDayInsteadVideo:
      return {
        platform: Platform.desktop,
        position: Position.day,
        placement: Placement.insteadVideo
      }
    case InlinePosition.desktopHomeworksTop:
      return {
        platform: Platform.desktop,
        position: Position.homeworks,
        placement: Placement.top
      }
    case InlinePosition.desktopHomeworksBottom:
      return {
        platform: Platform.desktop,
        position: Position.homeworks,
        placement: Placement.bottom
      }
    case InlinePosition.desktopHomeworksSidebar:
      return {
        platform: Platform.desktop,
        position: Position.homeworks,
        placement: Placement.sidebar
      }
    case InlinePosition.desktopVideoconsultationsTop:
      return {
        platform: Platform.desktop,
        position: Position.videoconsultations,
        placement: Placement.top
      }
    case InlinePosition.desktopVideoconsultationsBottom:
      return {
        platform: Platform.desktop,
        position: Position.videoconsultations,
        placement: Placement.bottom
      }
    case InlinePosition.desktopVideoconsultationsSidebar:
      return {
        platform: Platform.desktop,
        position: Position.videoconsultations,
        placement: Placement.sidebar
      }
    case InlinePosition.desktopSupportTop:
      return {
        platform: Platform.desktop,
        position: Position.support,
        placement: Placement.top
      }
    case InlinePosition.desktopSupportBottom:
      return {
        platform: Platform.desktop,
        position: Position.support,
        placement: Placement.bottom
      }
    case InlinePosition.desktopSupportSidebar:
      return {
        platform: Platform.desktop,
        position: Position.support,
        placement: Placement.sidebar
      }
    case InlinePosition.desktopSettingsTop:
      return {
        platform: Platform.desktop,
        position: Position.settings,
        placement: Placement.top
      }
    case InlinePosition.desktopSettingsBottom:
      return {
        platform: Platform.desktop,
        position: Position.settings,
        placement: Placement.bottom
      }
    case InlinePosition.desktopSettingsSidebar:
      return {
        platform: Platform.desktop,
        position: Position.settings,
        placement: Placement.sidebar
      }
    case InlinePosition.mobileCoursesCatalog:
      return {
        platform: Platform.mobile,
        position: Position.courses,
        placement: Placement.catalog
      }
    case InlinePosition.mobileCalendarBeforeStage:
      return {
        platform: Platform.mobile,
        position: Position.calendar,
        placement: Placement.beforeStage
      }
    case InlinePosition.mobileDayInsteadVideo:
      return {
        platform: Platform.mobile,
        position: Position.day,
        placement: Placement.insteadVideo
      }
    case InlinePosition.mobileDayAfterVideo:
      return {
        platform: Platform.mobile,
        position: Position.day,
        placement: Placement.afterVideo
      }
    case InlinePosition.mobileDayBottom:
      return {
        platform: Platform.mobile,
        position: Position.day,
        placement: Placement.bottom
      }
    case InlinePosition.mobileHomeworksCatalog:
      return {
        platform: Platform.mobile,
        position: Position.homeworks,
        placement: Placement.catalog
      }
    case InlinePosition.mobileVideoconsultationsCatalog:
      return {
        platform: Platform.mobile,
        position: Position.videoconsultations,
        placement: Placement.catalog
      }
    case InlinePosition.mobileSupportCatalog:
      return {
        platform: Platform.mobile,
        position: Position.support,
        placement: Placement.catalog
      }
    case InlinePosition.mobileAppCourses:
      return {
        platform: Platform.app,
        position: Position.courses
      }
    case InlinePosition.mobileAppCourseDay:
      return {
        platform: Platform.app,
        position: Position.day
      }
    case InlinePosition.mobileAppAll:
      return {
        platform: Platform.app,
        position: Position.all
      }
    case InlinePosition.desktopMainPage:
      return {
        platform: Platform.desktop,
        position: Position.home
      }
    case InlinePosition.mobileMainPage:
      return {
        platform: Platform.mobile,
        position: Position.home
      }
    default:
      return {
        platform: Platform.desktop,
        position: Position.courses,
        placement: Placement.top
      }
  }
}

/* istanbul ignore next */
export default class BannerMapper extends Mapper {
  static toClient (data): Banner {
    const banner = Mapper.converter({
      data,
      rules: {
        positions: {
          rename: 'positionsIds',
          cb: positionsIds => positionsIds
            .map(parseFloat)
            .filter(id => !isNaN(id))
        },
        file: {
          rename: 'image',
          image: true
        },
        course_ids: {
          cb: ids => ids
            ?.filter(id => id)
            ?.map(parseFloat) || []
        },
        community_ids: {
          cb: ids => ids
            ?.filter(id => id)
            ?.map(parseFloat) || []
        },
        not_display_community_ids: {
          cb: ids => ids
            ?.filter(id => id)
            ?.map(parseFloat) || []
        },
        not_display_course_ids: {
          cb: ids => ids
            ?.filter(id => id)
            ?.map(parseFloat) || []
        },
        application_ids: {
          cb: ids => ids
            ?.filter(id => id)
            ?.map(parseFloat) || []
        },
        not_display_application_ids: {
          cb: ids => ids
            ?.filter(id => id)
            ?.map(parseFloat) || []
        },
        stage_day_ids: {
          cb: ids => ids
            ?.filter(id => id)
            ?.map(parseFloat) || []
        }
      }
    }) as Banner

    const positions: string[] = []
    const platforms: string[] = []
    const placements: string[] = []
    banner.positionsIds
      .map(p => getDestructurizationPosition(p))
      .forEach(p => {
        if (p.position) {
          positions.push(p.position)
        }
        if (p.placement) {
          placements.push(p.placement)
        }
        platforms.push(p.platform)
      })

    return {
      ...banner,
      positions: uniq(positions),
      placements: uniq(placements),
      platforms: uniq(platforms)
    }
  }
}
