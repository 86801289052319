
import { defineComponent, PropType, ref } from 'vue'
import AcSwitch from '@ui-kit/components/AcSwitch.vue'

export default defineComponent({
  name: 'AcCookieModalBlock',
  components: {
    AcSwitch
  },
  props: {
    cookie: {
      type: Object as PropType<Cookies>,
      default: () => ({})
    }
  },
  emits: ['toggle'],
  setup (props, ctx) {
    const showBlock = ref(false)

    function toggleCookie (type: 'essential' | 'analytics' | 'marketing') {
      ctx.emit('toggle', type)
    }

    function toggleBlock () {
      showBlock.value = !showBlock.value
    }

    return {
      showBlock,
      toggleCookie,
      toggleBlock
    }
  }
})
