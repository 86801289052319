import {
  RouterOptions,
  RouteRecordRaw,
  createWebHistory,
  createWebHashHistory,
  RouteLocationRaw,
  RouteLocation,
  RouteLocationNormalized,
  NavigationGuardNext
} from 'vue-router'

import Login from '@web/views/Auth/Login.vue'
import Token from '@web/common/token'
import i18n from '@web/plugins/i18n'
import { isRegistrationEnable } from '@web/common/ExternalConfig/getters'
import { useStore } from '@web/store'
import PAGE_NAME from '@web/consts/PAGE_NAME'

/**
 * Для маршрутов с обязательной регистрацией установлен параметр `requiresAuth: false`
 * Для маршрутов доступных только гостям установлен параметр `guest: true`
 * @type {RouterOptions}
 */

let base = process.env.NODE_ENV === 'development' ? '/wds/' : '/class/'
if (process.env.PLATFORM) {
  base = '/'
}
if (process.env.VUE_APP_ROUTER_BASE) {
  base = process.env.VUE_APP_ROUTER_BASE
}

const store = useStore()

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {
      guest: true,
      title: i18n.global.t('Login')
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import(/* webpackChunkName: 'base' */ '../views/Auth/SignUp.vue'),
    meta: {
      guest: true,
      title: i18n.global.t('signUp')
    },
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      return isRegistrationEnable() ? next() : next({ name: 'login' })
    }
  },
  {
    path: '/auto-login',
    name: 'auto-login',
    redirect: (to: RouteLocation): RouteLocationRaw => {
      if (to.query.token) {
        store.dispatch('logout')
        Token.set(String(to.query.token))
        if (to.query.returnUrl) {
          window.location.href = to.query.returnUrl.toString()
        } else {
          return { name: PAGE_NAME.HOME }
        }
      }

      return { name: 'login' }
    }
  },
  {
    path: '/reset',
    name: 'accessRecovery',
    component: () => import(/* webpackChunkName: 'base' */ '../views/Auth/AccessRecovery.vue'),
    meta: {
      guest: true,
      title: i18n.global.t('restoringPassword')
    }
  },
  {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: 'user' */ '../views/Courses.vue'),
    meta: {
      requiresAuth: true
    },
    // Убираем возможность попасть на старую страницу
    redirect: (): RouteLocationRaw => {
      return { name: 'home' }
    }
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: 'base' */ '../views/Auth/ResetPassword.vue'),
    meta: {
      title: 'Forced password change'
    }
  },
  {
    path: '/updateProfile',
    name: 'updateProfile',
    component: () => import(/* webpackChunkName: 'base' */ '../views/Auth/UpdateProfile.vue'),
    meta: {
      requiresAuth: true,
      title: i18n.global.t('crumb.editInformation')
    }
  },
  {
    path: '/linksocialnetworks',
    name: 'linkSocialNetworks',
    component: () => import(/* webpackChunkName: 'linkSocialNetworks' */ '../views/Auth/LinkSocialNetworks.vue'),
    meta: {
      requiresAuth: true,
      skip: true,
      title: i18n.global.t('socialNetworks')
    }
  },
  {
    path: '/user/social-networks',
    name: 'socialNetworks',
    component: () => import(/* webpackChunkName: 'socialNetworks' */ '../views/SocialNetworks.vue'),
    meta: {
      requiresAuth: true,
      title: i18n.global.t('linkSocialNetworks'),
      crumbs: [
        {
          name: 'userSettings',
          title: i18n.global.t('Settings')
        }
      ]
    }
  },
  {
    path: '/user/settings/password',
    name: 'userPassword',
    component: () => import(/* webpackChunkName: 'password' */ '../views/EditPassword.vue'),
    meta: {
      requiresAuth: true,
      crumbs: [
        {
          name: 'userSettings',
          title: i18n.global.t('Settings')
        }
      ],
      title: i18n.global.t('changePassword')
    }
  },
  {
    path: '/user/settings/notifications',
    name: 'settingsNotifications',
    component: () => import(/* webpackChunkName: 'settingsNotifications' */ '../views/Notifications.vue'),
    meta: {
      requiresAuth: true,
      crumbs: [
        {
          name: 'userSettings',
          title: i18n.global.t('Settings')
        }
      ],
      title: 'Notification settings'
    }
  },
  {
    path: '/user/settings/reminders',
    name: 'settingsReminders',
    component: () => import(/* webpackChunkName: 'settingsReminders' */ '@web/views/RemindTrainings.vue'),
    meta: {
      requiresAuth: true,
      bottomMenu: true,
      crumbs: [
        {
          name: 'userSettings',
          title: i18n.global.t('Settings')
        }
      ],
      title: 'Training Reminders'
    }
  },
  {
    path: '/user/settings/reminders/new',
    name: 'settingsReminderNew',
    component: () => import(/* webpackChunkName: 'settingsReminders' */ '@web/views/RemindTraining.vue'),
    meta: {
      requiresAuth: true,
      bottomMenu: true,
      crumbs: [
        {
          name: 'userSettings',
          title: i18n.global.t('Settings')
        },
        {
          name: 'settingsReminders',
          title: i18n.global.t('reminderTraining')
        }
      ],
      title: 'Training Reminder'
    }
  },
  {
    path: '/user/settings/reminders/:id',
    name: 'settingsReminderUpdate',
    component: () => import(/* webpackChunkName: 'settingsReminders' */ '@web/views/RemindTraining.vue'),
    meta: {
      requiresAuth: true,
      bottomMenu: true,
      crumbs: [
        {
          name: 'userSettings',
          title: i18n.global.t('Settings')
        },
        {
          name: 'settingsReminders',
          title: i18n.global.t('reminderTraining')
        }
      ],
      title: 'Training Reminder'
    }
  },
  {
    path: '/user/edit',
    name: 'userEdit',
    component: () => import(/* webpackChunkName: 'userEdit' */ '../views/EditUserInfo.vue'),
    meta: {
      requiresAuth: true,
      title: i18n.global.t('crumb.editInformation'),
      crumbs: [
        {
          name: 'userSettings',
          title: i18n.global.t('Settings')
        }
      ]
    }
  },
  {
    path: '/user/settings',
    name: 'userSettings',
    component: () => import(/* webpackChunkName: 'userSettings' */ '../views/Settings.vue'),
    meta: {
      requiresAuth: true,
      title: i18n.global.t('Settings')
    }
  },
  {
    path: '/user/subscriptions',
    name: 'subscriptions',
    component: () => import(/* webpackChunkName: 'subscriptions' */ '../views.v2/Subscriptions.vue'),
    meta: {
      requiresAuth: true,
      title: i18n.global.t('Subscriptions'),
      crumbs: [
        {
          name: 'userSettings',
          title: i18n.global.t('Settings')
        }
      ]
    }
  },
  {
    path: '/user/installments',
    name: 'installments',
    component: () => import(/* webpackChunkName: 'Installments' */ '../views.v2/Installments.vue'),
    meta: {
      requiresAuth: true,
      title: i18n.global.t('installments.shortTitle'),
      crumbs: [
        {
          name: 'userSettings',
          title: i18n.global.t('Settings')
        }
      ]
    }
  },
  {
    path: '/subscriptions',
    redirect: (): RouteLocationRaw => {
      return { name: 'subscriptions', params: { warning: 'true' } }
    }
  },
  {
    path: '/user/subscription/:id',
    name: 'subscription',
    component: () => import(/* webpackChunkName: 'subscription' */ '../views.v2/Subscription.vue'),
    meta: {
      requiresAuth: true,
      title: i18n.global.t('Subscription'),
      crumbs: [
        {
          name: 'userSettings',
          title: i18n.global.t('Settings')
        },
        {
          name: 'subscriptions',
          title: i18n.global.t('Subscriptions')
        }
      ]
    }
  },
  {
    path: '/user/settings/cookie',
    name: 'settingsCookiePolicy',
    component: () => import(/* webpackChunkName: 'settingsCookiePolicy' */ '../views.v2/CookiePolicy.vue'),
    meta: {
      requiresAuth: true,
      crumbs: [
        {
          name: 'userSettings',
          title: i18n.global.t('Settings')
        }
      ],
      title: 'Cookie Policy'
    }
  },
  {
    path: '/delete',
    name: 'delete',
    redirect: (to: RouteLocation): RouteLocationRaw => {
      store.dispatch('user/delete')
      store.dispatch('logout')
      if (to.query.returnUrl) {
        store.commit('setReturnUrl', String(to.query.returnUrl))
      }

      return { name: 'login' }
    }
  },
  {
    path: '/logout',
    name: 'logout',
    redirect: (to: RouteLocation): RouteLocationRaw => {
      store.dispatch('logout')
      if (to.query.returnUrl) {
        store.commit('setReturnUrl', String(to.query.returnUrl))
      }
      return { name: 'login' }
    }
  },
  {
    path: '/user/setting',
    name: 'userSetting',
    component: () => import(/* webpackChunkName: 'userSetting' */ '../views/Setting.vue'),
    meta: {
      requiresAuth: true,
      title: i18n.global.t('menu')
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: 'home' */ '../views.v2/Home.vue'),
    meta: {
      requiresAuth: true,
      title: 'Home page'
    }
  },
  {
    path: '/courses',
    name: 'home-courses',
    component: () => import(/* webpackChunkName: 'home-courses' */ '../views.v2/HomeAll.vue'),
    meta: {
      requiresAuth: true,
      title: i18n.global.t('myCourses')
    }
  },
  {
    path: '/events',
    name: 'home-events',
    component: () => import(/* webpackChunkName: 'home-events' */ '../views.v2/HomeAll.vue'),
    meta: {
      requiresAuth: true,
      title: 'Events'
    }
  },
  {
    path: '/catalog',
    name: 'home-catalog',
    component: () => import(/* webpackChunkName: 'home-catalog' */ '../views.v2/HomeAll.vue'),
    meta: {
      requiresAuth: true,
      title: 'Course catalog'
    }
  },
  {
    path: '/course',
    name: 'courses',
    component: () => import(/* webpackChunkName: 'courses' */ '../views/Courses.vue'),
    meta: {
      requiresAuth: true,
      title: i18n.global.t('myCourses')
    },
    // Убираем возможность попасть на старую страницу
    redirect: (): RouteLocationRaw => {
      return { name: 'home' }
    }
  },
  {
    path: '/course/:streamId/stages',
    redirect: (to: RouteLocation): RouteLocationRaw => {
      return { name: 'calendar', params: to.params }
    }
  },
  {
    path: '/course/:streamId/calendar',
    name: 'calendar',
    component: () => import(/* webpackChunkName: 'calendar' */ '../views.v2/Calendar.vue'),
    meta: {
      requiresAuth: true,
      savePosition: true,
      title: i18n.global.t('scheduleCourse')
    },
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      return to.params.streamId ? next() : next({ name: 'courses' })
    }
  },
  {
    path: '/course/:streamId/freeze',
    name: 'courseFreeze',
    component: () => import(/* webpackChunkName: 'courseFreeze' */ '../views/CourseFreeze.vue'),
    meta: {
      requiresAuth: true,
      savePosition: true,
      title: i18n.global.t('freezeCourse'),
      crumbs: [
        {
          name: 'calendar',
          title: i18n.global.t('scheduleCourse')
        }
      ]
    },
    // TODO Пока не показывать заморозку
    redirect: (to: RouteLocation): RouteLocationRaw => {
      return { name: 'calendar', params: to.params }
    },
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      return to.params.streamId ? next() : next({ name: 'courses' })
    }
  },
  {
    path: '/course/:streamId/stages/:stageId/days/:dayId',
    redirect: (to: RouteLocation): RouteLocationRaw => {
      return { name: 'day', params: to.params }
    }
  },
  {
    path: '/course/:streamId/calendar/:dayNumber',
    name: 'day',
    component: () => import(/* webpackChunkName: 'day' */ '../views.v2/Day.vue'),
    meta: {
      requiresAuth: true,
      crumbs: [
        {
          name: 'calendar',
          title: i18n.global.t('scheduleCourse')
        }
      ],
      title: i18n.global.t('Day')
    },
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      const { streamId } = to.params
      if (!streamId) {
        return next({ name: 'courses' })
      }

      return next()
    }
  },
  {
    path: '/homework',
    name: 'homeworks',
    component: () => import(/* webpackChunkName: 'homeworks' */ '../views/Homeworks.vue'),
    meta: {
      requiresAuth: true,
      title: i18n.global.t('homeworks')
    }
  },
  {
    path: '/homework/:streamId/:homeworkId',
    name: 'homework',
    component: () => import(/* webpackChunkName: 'homework' */ '../views/Homework.vue'),
    meta: {
      requiresAuth: true,
      crumbs: [
        {
          name: 'homeworks',
          title: i18n.global.t('homeworks')
        }
      ],
      title: i18n.global.t('homework')
    }
  },
  {
    path: '/chat',
    name: 'chats',
    component: () => import(/* webpackChunkName: 'chats' */ '../views/Chats.vue'),
    meta: {
      requiresAuth: true,
      crumbs: [
        {
          name: 'chats',
          title: i18n.global.t('messages')
        }
      ],
      title: i18n.global.t('messages')
    }
  },
  {
    path: '/chat/:chatId',
    name: 'chat',
    component: () => import(/* webpackChunkName: 'chat' */ '../views/Chats.vue'),
    meta: {
      requiresAuth: true,
      crumbs: [
        {
          name: 'chats',
          title: i18n.global.t('messages')
        }
      ],
      title: i18n.global.t('chat')
    }
  },
  {
    path: '/videoconsultations',
    name: 'videoconsultations',
    component: () => import(/* webpackChunkName: 'videoConsultations' */ '../views/VideoConsultations.vue'),
    meta: {
      requiresAuth: true,
      title: i18n.global.t('videoConsultations.VideoConsultations')
    }
  },
  {
    path: '/videoconsultations/:id',
    name: 'videoconsultation',
    component: () => import(/* webpackChunkName: 'videoConsultation' */ '../views/VideoConsultationInfo.vue'),
    meta: {
      requiresAuth: true,
      title: i18n.global.t('videoConsultations.VideoConsultation')
    }
  },
  {
    path: '/support/:categoryId/:streamId',
    name: 'support-category-stream',
    component: () => import(/* webpackChunkName: 'supportCategoryStream' */ '../views.v2/Support.vue'),
    meta: {
      requiresAuth: true,
      title: `${i18n.global.t('techSupport')} - ${i18n.global.t('myCourses')}`
    }
  },
  {
    path: '/support/:categoryId',
    name: 'support-category',
    component: () => import(/* webpackChunkName: 'supportCategory' */ '../views.v2/Support.vue'),
    meta: {
      requiresAuth: true,
      title: `${i18n.global.t('techSupport')} - category`
    }
  },
  {
    path: '/support',
    name: 'support',
    component: () => import(/* webpackChunkName: 'support' */ '../views.v2/Support.vue'),
    meta: {
      requiresAuth: true,
      title: i18n.global.t('techSupport')
    }
  },
  {
    path: '/banners',
    name: 'banners',
    component: () => import(/* webpackChunkName: 'banners' */ '../views/Banners.vue'),
    meta: {
      requiresAuth: true,
      title: i18n.global.t('Banner')
    }
  },

  {
    path: '/user/points',
    name: 'points',
    component: () => import(/* webpackChunkName: 'points' */ '../views.v2/Points.vue'),
    meta: {
      requiresAuth: true,
      title: i18n.global.t('Points')
    }
  },
  {
    path: '/user/points/history',
    name: 'points-history',
    component: () => import(/* webpackChunkName: 'pointsHistory' */ '../views.v2/PointsHistory.vue'),
    meta: {
      requiresAuth: true,
      title: i18n.global.t('History'),
      crumbs: [
        {
          name: 'points',
          title: i18n.global.t('Points')
        }
      ]
    }
  },
  {
    path: '/course/:streamId/materials',
    name: 'materials',
    component: () => import(/* webpackChunkName: 'materials' */ '../views.v2/Materials.vue'),
    meta: {
      requiresAuth: true,
      title: i18n.global.t('Materials')
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('../views/404.vue'),
    meta: {
      requiresAuth: false
    }
  }
]
const config: RouterOptions = {
  history: process.env.PLATFORM ? createWebHashHistory(base) : createWebHistory(base),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    return { left: 0, top: 0 }
  }
}

export default config
