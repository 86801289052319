/* eslint-disable camelcase */
const EXTERNAL_AUTHS_ERRORS: {
  [id: string]: string;
} = {
  common_no_social_id: 'common_no_social_id',
  login_no_link: 'login_no_link',
  login_no_user_from_link: 'login_no_user_from_link',
  login_unknown_error: 'unknown_error',
  link_user_unauthorised: 'link_user_unauthorised',
  link_social_id_already_taken: 'link_social_id_already_taken',
  link_unknown_error: 'unknown_error'
}

export default EXTERNAL_AUTHS_ERRORS
