
import { defineComponent, ref, computed } from 'vue'

import Token from '@web/common/token'
import { useStore } from '@web/store'

import AsExternalDocument from '@web/components/AsExternalDocument.vue'
import AcPointsLabel from '@web/components.v2/PointsLabel.vue'

import PAGE_NAME from '@web/consts/PAGE_NAME'
import Analytics from '@web/services/Analytics/Analytics'

export default defineComponent({
  components: {
    AcPointsLabel,
    AsExternalDocument
  },
  setup () {
    const store = useStore()

    const menuOpen = ref(false)
    const user = computed(() => store.getters['user/user'])
    const username = computed(() => store.getters['user/username'])
    const logo = computed(() => store.getters['assets/logo'])
    const menu = computed(() => store.getters['menu/setting'])
    const userScoreAmount = computed(() => store.getters['userScore/amount'])
    const urlAdmin = computed(() => `/admin/login?token=${Token.get()}`)

    function closeMenu () {
      menuOpen.value = false
      document.body.removeEventListener('click', closeMenu)
      Analytics.send({
        action: 'close_burger',
        project: 'platform'
      })
    }

    function openMenu () {
      menuOpen.value = true
      document.body.addEventListener('click', closeMenu)
      Analytics.send({
        action: 'open_burger',
        project: 'platform'
      })
    }

    function toggleMenu () {
      if (menuOpen.value) {
        closeMenu()
      } else {
        openMenu()
      }
    }

    return {
      menuOpen,
      user,
      username,
      logo,
      menu,
      userScoreAmount,
      urlAdmin,

      toggleMenu,
      PAGE_NAME
    }
  }
})
