import Mapper from '@web/mapper/Mapper'
import { Article } from '@web/types/Article'
import { communityUrlFrontend } from '@web/common/ExternalConfig/getters'

export default class ArticleMapper extends Mapper {
  static toClient (data): Article {
    function convertDate (date: string): number {
      const re = /^202\d-\d\d-\d\d/
      if (re.test(date)) {
        const [year, month, day] = date.split('-')
        return (new Date(Number(year), Number(month) - 1, Number(day))).getTime()
      }

      return Number(date)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const article: any = ArticleMapper.converter({
      data,
      rules: {
        created_at: {
          cb: convertDate
        },
        published_at: {
          cb: convertDate
        },
        preview_image: {
          cb: (image): string => image?.thumbnail ? decodeURIComponent(image?.thumbnail) : require('@web/assets/images/articleImage.png')
        },
        views: {
          rename: 'countViews'
        }
      },
      defaults: {
        sort: 0
      }
    })

    if (article.new) {
      article.labels = ['NEW']
      delete article.new
    }

    // эту ссылка должна приходить с бекенда, пока нету буду генерировать сам
    if (!Object.prototype.hasOwnProperty.call(article, 'url')) {
      const club = data.main_category?.community?.slug ? `/${data.main_category?.community?.slug}` : ''
      article.url = `${communityUrlFrontend()}${club}/articles/${article.id}`
    }

    return article as Article
  }
}
