import CookieApi from '@web/api/modules/Cookie'
import {
  CookieState,
  CookieGettersTree,
  CookieMutationsTree,
  CookieActionsTree
} from '@web/store/types/modules/cookie'

const state: CookieState = {
  cookies: [],
  policy: {
    essential: true,
    analytics: false,
    marketing: false
  },
  isEnabled: true
}

const getters: CookieGettersTree = {
  isEnabled: ({ isEnabled }) => isEnabled,
  cookies: ({ cookies }) => cookies,
  policy: ({ policy, isEnabled }) => {
    if (!isEnabled) {
      return {
        essential: true,
        analytics: true,
        marketing: true
      }
    }
    return policy
  }
}

const mutations: CookieMutationsTree = {
  setCookies (state, payload) {
    state.cookies = payload
  },
  setCookiesEnabled (state, payload) {
    state.isEnabled = payload
  },
  setPolicy (state, payload) {
    state.policy = payload
  },
  logout (state) {
    state.cookies = []
    state.policy = {
      essential: false,
      analytics: false,
      marketing: false
    }
  }
}

const actions: CookieActionsTree = {
  fetchCookies ({ commit }) {
    return CookieApi.fetchCookies()
      .then(({ data }) => {
        commit('setCookiesEnabled', data.isEnabled)
        commit('setCookies', data.items)

        return Promise.resolve()
      })
  },
  fetchPolicy ({ commit }) {
    return CookieApi.fetchPolicy()
      .then(({ data }) => {
        commit('setPolicy', data)

        return Promise.resolve()
      })
  },
  updatePolicy ({ commit }, payload) {
    return CookieApi.updatePolicy(payload)
      .then(({ data }) => {
        commit('setPolicy', data)

        return Promise.resolve()
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
// eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
