/* istanbul ignore file */
import client from '../client'
import HomeworkStatusMapper from '@web/mapper/HomeworkStatusMapper'
import HeaderMapper from '@web/mapper/HeaderMapper'

const options = {
  params: {
    expand: [
      'scores'
    ].toString()
  }
}

export default class Homework {
  static fetchStatuses (streamId) {
    return client.get(`/user-course-streams/${streamId}/user-homework`, options)
      .then((response) => {
        return {
          data: response.data.map(HomeworkStatusMapper.toClient),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static fetchHomeworks () {
    return client.get('/homework', options)
      .then((response) => {
        return {
          data: response.data,
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }
}
