import flatten from 'lodash.flatten'
import { DayLesson } from '@web/store/types/modules/day'
import i18n from '@web/plugins/i18n'

/**
 * Количество повторений набора уроков
 * не может быть больше или меньше диапозона
 *
 * @param {number} repeatCount
 * @param {number} userRepeatRangeFrom
 * @param {number} userRepeatRangeTo
 * @returns {number}
 */
export const validateRepeatCount = ({
  repeatCount,
  userRepeatRangeFrom,
  userRepeatRangeTo
}: {
  repeatCount: number;
  userRepeatRangeFrom: number;
  userRepeatRangeTo: number;
}): number => {
  if (repeatCount < userRepeatRangeFrom) {
    repeatCount = userRepeatRangeFrom
  }
  if (repeatCount > userRepeatRangeTo) {
    repeatCount = userRepeatRangeTo
  }
  return repeatCount
}

/**
 * Вернет все уроки из дня с нужным количеством повторений
 *
 * @param day
 * @returns {<any>Array}
 */
export const getRepeatLesson = (day: Day): LessonSetLesson[] => {
  if (!Array.isArray(day.lessonSets) || day.lessonSets.length === 0) return []

  const lessonSets: LessonSetLesson[] = []
  const lessonSetsSorted: LessonSet[] = [...day.lessonSets].sort((a, b) => a.sort - b.sort)
  for (let i = 0; i < lessonSetsSorted.length; i++) {
    const repeatCount = validateRepeatCount(day.lessonSets[i])
    const lessons = [...day.lessonSets[i].lessons]
      .sort((a, b) => a.sort - b.sort)

    for (let j = 0; j < repeatCount; j++) {
      lessonSets.push(...lessons)
    }
  }

  const lessons: LessonSetLesson[] = []
  const dayRepeatCount = day.repeatCount > 0 ? day.repeatCount : 1
  for (let i = 0; i < dayRepeatCount; i++) {
    lessons.push(...lessonSets)
  }

  return lessons
}

/**
 * Вернет уроки с типом
 *
 * @param lessons
 * @returns {Array}
 */
export const getLessonWithTypes = (lessons: {
  lessonSetLesson: LessonSetLesson;
  lesson: Lesson;
  localVideoFile: FileVideo | undefined;
  verticalVideo: FileVideo | undefined;
  localDescriptionVideoFile: FileVideo | undefined;
  verticalDescriptionVideo: FileVideo | undefined;
}[]): DayLesson[] => {
  return flatten(lessons.map(item => {
    let lessonHorizontal: DayLesson | null = null
    if (item.localVideoFile) {
      lessonHorizontal = {
        lessonSetLesson: item.lessonSetLesson,
        lesson: item.lesson,
        video: item.localVideoFile,
        meta: {
          type: item.localDescriptionVideoFile ? 'simple' : 'default',
          orientation: 'horizontal'
        }
      }
    }

    let lessonAnalysis: DayLesson | null = null
    if (item.localDescriptionVideoFile) {
      lessonAnalysis = {
        lessonSetLesson: {
          ...item.lessonSetLesson,
          surveyFormUrl: lessonHorizontal ? '' : item.lessonSetLesson?.surveyFormUrl
        },
        lesson: {
          ...item.lesson,
          subtitle: i18n.global.t('lessonType.playback')
        },
        video: item.localDescriptionVideoFile,
        meta: {
          type: item.localVideoFile ? 'analysis' : 'default',
          orientation: 'horizontal'
        }
      }
    }

    let lessonVertical: DayLesson | null = null
    if (item.verticalVideo) {
      lessonVertical = {
        lessonSetLesson: item.lessonSetLesson,
        lesson: item.lesson,
        video: item.verticalVideo,
        meta: {
          type: item.verticalDescriptionVideo ? 'simple' : 'default',
          orientation: 'vertical'
        }
      }
    }

    let lessonVerticalAnalysis: DayLesson | null = null
    if (item.verticalDescriptionVideo) {
      lessonVerticalAnalysis = {
        lessonSetLesson: {
          ...item.lessonSetLesson,
          surveyFormUrl: lessonVertical ? '' : item.lessonSetLesson?.surveyFormUrl
        },
        lesson: {
          ...item.lesson,
          subtitle: i18n.global.t('lessonType.playback')
        },
        video: item.verticalDescriptionVideo,
        meta: {
          type: item.verticalVideo ? 'analysis' : 'default',
          orientation: 'vertical'
        }
      }
    }

    const _l: DayLesson[] = []
    // TODO: добавить сортировку: в порядке вертикальные -> горизонтальные -> разбор -> исполнение
    if (lessonVerticalAnalysis) {
      _l.push(lessonVerticalAnalysis)
    }
    if (lessonVertical) {
      _l.push(lessonVertical)
    }

    if (lessonAnalysis) {
      _l.push(lessonAnalysis)
    }
    if (lessonHorizontal) {
      _l.push(lessonHorizontal)
    }
    return _l
  }))
}
