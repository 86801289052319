
import { defineComponent, ref } from 'vue'

import uuid from 'uuid/v4'

export default defineComponent({
  name: 'AcSwitch',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'click'],
  setup (props, ctx) {
    const switchId = ref(`switch-${uuid()}`)
    function onClick () {
      if (!props.loading) {
        const nVal = !props.modelValue
        ctx.emit('update:modelValue', nVal)
        ctx.emit('click', nVal)
      }
    }

    return {
      switchId,
      onClick
    }
  }
})
