import Mapper from './Mapper'
import DayStatusMapper from './DayStatusMapper'
import StageStatus from '@web/consts/StageStatus'
import HomeworkStatusMapper from '@web/mapper/HomeworkStatusMapper'
import { Status } from '@web/store/types/modules/status'
import { ChatChannelMeta, LessonStatus } from '@web/store/types/modules/stream'

/* istanbul ignore next */
export default class StatusesMapper extends Mapper {
  static toClient (data): Status {
    const statuses: Status = {
      stages: [],
      days: [],
      homeworks: [],
      lessons: [],
      consultations: [],
      chatChannelMeta: {}
    }

    if (Array.isArray(data.stages)) {
      statuses.stages = data.stages.map(Mapper.toClient)

      statuses.stages = statuses.stages.map((status, i) => {
        const prevStage: StageStatus | undefined | false = i > 0 && statuses.stages[i - 1]
        const isPrevStageOpen = prevStage && StageStatus.OPEN
        const requestHomeworks = (prevStage && prevStage.requestHomeworks) || []
        const requestDays = (prevStage && prevStage.requestDays) || []
        const isPrevStageRequestHomeworks = isPrevStageOpen && requestHomeworks.length
        const isPrevStageRequestDays = isPrevStageOpen && requestDays.length
        return {
          ...status,
          requestHomeworks: isPrevStageRequestHomeworks ? status.requestHomeworks.concat(requestHomeworks) : status.requestHomeworks,
          requestDays: isPrevStageRequestDays ? status.requestDays.concat(requestDays) : status.requestDays
        }
      })
    }

    if (Array.isArray(data.days)) {
      statuses.days = data.days.map(DayStatusMapper.toClient)
    }

    if (Array.isArray(data.homeworks)) {
      statuses.homeworks = data.homeworks.map(HomeworkStatusMapper.toClient)
    }

    if (Array.isArray(data.consultations)) {
      statuses.consultations = data.consultations.map(Mapper.toClient)
    }

    if (data.chatChannelMeta) {
      statuses.chatChannelMeta = Mapper.toClient(data.chatChannelMeta) as ChatChannelMeta
    }

    if (Array.isArray(data.lessons)) {
      statuses.lessons = data.lessons.map(item => {
        return Mapper.converter({
          data: item,
          rules: {

            viewed_seconds: {
              cb: value => value * 1000
            }
          }
        }) as LessonStatus
      })
    }

    if (data.current_training_stage_id && data.current_training_day) {
      statuses.currentDay = {
        stageId: data.current_training_stage_id,
        dayId: data.current_training_day.day_id,
        title: data.current_training_day.title,
        excerpt: data.current_training_day.description_short
      }
    }

    return statuses
  }
}
