import Token from '@web/common/token'
import client from '@web/api/client'
import OrderMapper from '@web/mapper/OrderMapper'

export default class Order {
  static create (serviceId: number) {
    const token = Token.get()
    const url = `/order/${serviceId}?token=${token}`
    return client.post(url)
      .then(response => OrderMapper.toClient(response.data))
  }
}
