import { ExternalConfig } from '@web/store/types/modules/assets'

export interface ProxyExternalConfig {
  get (prop: string | number): unknown;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set (prop: string | number, value: any): unknown;
}

export default function proxyExternalConfig (state: ExternalConfig): ProxyExternalConfig {
  return {
    get (prop): unknown {
      if (prop in state) {
        return state[prop]
      }

      if (typeof prop === 'string') {
        const names = prop.split('.').filter(v => v)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const get = (obj: Dictionary<any>, names: string[]) => {
          const [name, ...rest] = names
          if (!rest.length) {
            return obj[name]
          }
          return name in obj ? get(obj[name], rest) : undefined
        }
        return get(state, names)
      }

      return undefined
    },
    set (prop, value): boolean {
      if (prop in state) {
        state[prop] = value
        return true
      }

      if (typeof prop === 'string') {
        const names = prop.split('.').filter(v => v)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const set = (obj: Dictionary<any>, value: unknown, names: string[]) => {
          const [name, ...rest] = names
          if (name in obj) {
            if (rest.length) {
              return set(obj[name], value, rest)
            } else {
              obj[name] = value
              return true
            }
          } else {
            return false
          }
        }
        return set(state, value, names)
      }

      return false
    }
  }
}
