import camelCase from 'lodash.camelcase'
import getBaseUrl from '@web/common/getBaseUrl'

const baseUrl = getBaseUrl()

export default class Mapper {
  /**
   *
   * @param data - исходный объект
   * @param rules - правила по которым конвертировать
   * @param defaults - значение по-умолчанию
   * @return {object}
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static converter ({ data, rules = {}, defaults = {} }): unknown {
    const result: Dictionary<unknown> = {}

    for (const key in data) {
      if (!Object.prototype.hasOwnProperty.call(data, key)) continue
      let newKey = camelCase(key)
      let value = data[key]

      const rule = rules[key] || {}

      if (rule.cb && typeof rule.cb === 'function') {
        value = rule.cb(value)
      }

      if (rule.rename) {
        newKey = rule.rename
      }

      if (rule.baseUrl && value) {
        value = baseUrl + value
      }

      if (rule.type) {
        if (rule.type === 'number') {
          value = Number(value)
        }

        if (rule.type === 'boolean') {
          value = Boolean(value)
        }

        if (rule.type === 'timestamp') {
          value = 1000 * value
        }
      }

      if (rule.image) {
        if (value && value.urls) {
          value = {
            large: value.urls.large ? baseUrl + value.urls.large : '',
            medium: value.urls.medium ? baseUrl + value.urls.medium : '',
            small: value.urls.small ? baseUrl + value.urls.small : ''
          }
        } else {
          value = {
            large: '',
            medium: '',
            small: ''
          }
        }
      }

      if (rule.baseUrls && value) {
        value = {
          large: value.large ? baseUrl + value.large : '',
          medium: value.medium ? baseUrl + value.medium : '',
          small: value.small ? baseUrl + value.small : ''
        }
      }

      result[newKey] = value
    }

    return {
      ...defaults,
      ...result
    }
  }

  /**
   * Маппинг данных с клиента на сервер
   *
   * @param data {object}
   * @return {object}
   */
  static toServer (data: Dictionary<unknown>): unknown {
    return data
  }

  /**
   * Маппинг данных с сервера на клиент
   *
   * @param data {object}
   * @return {object}
   */
  static toClient (data): unknown {
    return Mapper.converter({
      data,
      rules: {
        // eslint-disable-next-line camelcase
        created_at: { cb: value => value * 1000 },
        // eslint-disable-next-line camelcase
        updated_at: { cb: value => value * 1000 }
      }
    })
  }
}
