/* istanbul ignore file */
import client from '../client'
import VideoConsultationsMapper from '@web/mapper/VideoConsultationsMapper'
import HeaderMapper from '@web/mapper/HeaderMapper'

export default class VideoConsultations {
  static fetchAll () {
    const expand: string[] = []
    expand.push('course')

    return client.get('/online-consultation', {
      params: {
        expand: expand.join(',')
      }
    })
      .then((response) => {
        return {
          data: response.data.map(VideoConsultationsMapper.toClient),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static fetchAvailableVideoConsultations () {
    return client.get('/course/online-consultations')
      .then((response) => {
        return {
          data: response.data.map(VideoConsultationsMapper.toClient),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static update ({ id, time, note, phone }) {
    return client.put(`/online-consultation/update?id=${id}`, {

      start_date_time: time,
      note,
      phone
    })
  }
}
