import { MainState, MainGettersTree, MainMutationsTree, MainActionsTree } from '@web/store/types'
import Token from '@web/common/token'
import { performanceEnd } from '@web/common/Utils'
import Logger from '@web/common/Logger'
import DOWNLOAD_STATUS from '@web/consts/DownloadStatus'
import Order from '@web/api/modules/Order'
import Analytics from '@web/services/Analytics/Analytics'
import * as AnalyticEvents from '@web/services/Analytics/events'
import { IS_MOBILE } from '@web/common/config'
import { getState } from '@web/common/ExternalConfig/ExternalConfig'

const state: MainState = {
  lang: 'ru',
  returnUrl: '',
  loadAllDataStatus: DOWNLOAD_STATUS.PENDING,
  isShowPreloader: false,
  countFailuresStart: 0
}

const getters: MainGettersTree = {
  isLoadedAllData: state => state.loadAllDataStatus === DOWNLOAD_STATUS.LOADED,
  isShowPreloader: ({ isShowPreloader }) => isShowPreloader,
  countFailuresStart: ({ countFailuresStart }) => countFailuresStart
}

const mutations: MainMutationsTree = {
  setReturnUrl (state, name) {
    if (name === '/') return
    state.returnUrl = name
  },
  setShowPreloaderStatus (state, status) {
    state.isShowPreloader = status
  },
  setLoadedAllDataStatus (state, status) {
    state.loadAllDataStatus = status
  },
  logout (state): void {
    state.returnUrl = ''
    state.isShowPreloader = false
    state.loadAllDataStatus = DOWNLOAD_STATUS.PENDING
    state.countFailuresStart = 0
  },
  incCountFailuresStart (state) {
    state.countFailuresStart++
  },
  resetCountFailuresStart (state) {
    state.countFailuresStart = 0
  }
}

/* istanbul ignore next */
const actions: MainActionsTree = {
  /**
   * Все actions для очистки store должны быть тут
   */
  async logout ({ commit, rootGetters }) {
    if (rootGetters['user/user']?.id) {
      AnalyticEvents.logout({
        userId: rootGetters['user/user'].id
      })
    }

    Token.remove()

    commit('logout')
    commit('user/logout')
    commit('userScore/logout')
    commit('chat/logout')
    commit('stream/logout')
    commit('course/logout')
    commit('stage/logout')
    commit('day/logout')
    commit('homework/logout')
    commit('consultation/logout')
    commit('router/logout')
    commit('extend/logout')
    commit('lesson/logout')
    commit('faq/logout')
    commit('videoConsultation/logout')
    commit('schedule/logout')
    commit('banner/logout')
    commit('appStore/logout')
    commit('article/logout')
    commit('subscription/logout')
    commit('catalog/logout')
    commit('cookie/logout')
    commit('installments/logout')
    commit('community/logout')

    if (IS_MOBILE) {
      commit('file/logout')
    }

    if (window.OneSignal && window.OneSignal.push) {
      window.OneSignal.push(() => {
        window.OneSignal.removeExternalUserId()
      })
    }

    Analytics.setUserId(null)
  },
  async fetchAllData ({ getters, commit, dispatch, state }, reFetch = false) {
    const loadStart = performance.now()

    if (!Token.get()) {
      return
    }

    if (getters.isLoadedAllData) {
      dispatch('syncAllData')
      return
    }

    if (state.loadAllDataStatus === DOWNLOAD_STATUS.LOADING) {
      return
    }

    commit('setLoadedAllDataStatus', DOWNLOAD_STATUS.LOADING)
    commit('setShowPreloaderStatus', true)
    let streams
    let config

    try {
      await dispatch('user/fetch')
      streams = await dispatch('stream/fetchUserCourseStreams')
      config = getState()
      await dispatch('chat/fetchChats')
      commit('setLoadedAllDataStatus', DOWNLOAD_STATUS.LOADED)
    } catch (e) {
      commit('setLoadedAllDataStatus', DOWNLOAD_STATUS.FAIL)
      Logger.error(e)
      throw e
    } finally {
      commit('setShowPreloaderStatus', false)
      const time = performanceEnd(loadStart)

      const demoCourseServiceId = config?.data.demoCourseServiceId
      const isDemoAccessible = config?.data.isDemoAccessible
      if (isDemoAccessible && !streams?.length && demoCourseServiceId) {
        Order.create(demoCourseServiceId)
          .then(() => {
            dispatch('service/fetchService', { id: demoCourseServiceId })
              .then(service => {
                AnalyticEvents.addDemoCourse({ serviceId: demoCourseServiceId, courseId: Number(service?.courseId) })
              })

            if (!reFetch) {
              commit('setLoadedAllDataStatus', DOWNLOAD_STATUS.FAIL)
              dispatch('fetchAllData', true)
            }
          })
      }

      Logger.info(`[WPO] fetchAllData: ${time} ms`)
    }
  },

  async syncAllData ({ dispatch }) {
    const loadStart = performance.now()

    await dispatch('user/fetch') // для проверки авторизации
    await dispatch('stream/fetchUserCourseStreamsCached')

    const time = performanceEnd(loadStart)
    Logger.info(`[WPO] syncAllData: ${time} ms`)
  }
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
