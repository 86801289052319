
import { defineComponent } from 'vue'

import AsModal from '@web/components/AsModal.vue'

import useExternalDocument, { props } from '@web/composition/useExternalDocument'

import getBaseUrl from '@web/common/getBaseUrl'

export default defineComponent({
  components: {
    AsModal
  },
  props,
  setup (props) {
    return {
      // TODO: передавать явно пропсы
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...useExternalDocument(props),
      getBaseUrl
    }
  }
})
