/* istanbul ignore file */
import amplitude from 'amplitude-js'
import { APP_VERSION, AMPLITUDE_API_KEY, IS_WEB, APPLICATION_TYPE } from '@web/common/config'
import Logger from '@web/common/Logger'
import { Router } from 'vue-router'
import snakeCase from 'lodash.snakecase'

export default class Amplitude {
  private static initiated = false

  static init (): void {
    const init = () => {
      const options: Record<string, string | number> = {
        // eslint-disable-next-line camelcase
        app_version: APP_VERSION,
        platform: APPLICATION_TYPE
      }

      Amplitude.initiated = true
      amplitude.getInstance().init(AMPLITUDE_API_KEY, '', options)
    }

    if (IS_WEB) {
      init()
    } else {
      document.addEventListener('deviceready', init, false)
    }
  }

  static setUserId (id: string): void {
    if (!Amplitude.initiated) {
      Logger.info('amplitude not init')
      return
    }

    amplitude.getInstance().setUserId(id)
  }

  static logEvent (event: EventAmplitude): Promise<unknown> {
    if (!Amplitude.initiated) {
      Logger.info('amplitude not init')
      return Promise.resolve()
    }

    return new Promise(resolve => {
      amplitude.getInstance().logEvent(event.type, event.properties, resolve)
    })
  }

  static setVersionName (version: string): void {
    if (!Amplitude.initiated) {
      Logger.info('amplitude not init')
      return
    }

    amplitude.getInstance().setVersionName(version)
  }

  static pageView (router: Router) {
    if (!Amplitude.initiated) {
      Logger.info('amplitude not init')
      return
    }

    const params = {}
    for (const name in router.currentRoute.value.params) {
      params[snakeCase(name)] = String(router.currentRoute.value.params[name])
    }
    Amplitude.logEvent({
      type: 'page_view',
      properties: {
        ...params,
        // eslint-disable-next-line camelcase
        page_title: router.currentRoute.value.meta?.title || '',
        // eslint-disable-next-line camelcase
        page_location: window.location.href,
        // eslint-disable-next-line camelcase
        page_path: IS_WEB ? window.location.pathname : router
      }
    })
  }

  static setDeviceId (deviceId: string): void {
    amplitude.getInstance().setDeviceId(deviceId)
  }
}
