import { computed } from 'vue'
import { useStore } from '@web/store'
import { Article } from '@web/types/Article'
import { communityUrlApi } from '@web/common/ExternalConfig/getters'
import { getUrlWithAuthParamsCommunity } from '@web/common/Utils'

export default function useArticles () {
  const store = useStore()
  const communities = computed(() => store.getters['community/communities'].filter(c => c.userCommunity || c.type === 'recommended'))
  const articles = computed<Article[]>(() => store.getters['article/articles'])
  const previewLoaded = computed<boolean>(() => store.getters['article/previewLoaded'])
  async function fetchArticles () {
    if (communityUrlApi()) {
      await store.dispatch('community/fetchCachedCommunities')
      return store.dispatch('article/fetchCachedArticles')
    }

    return Promise.reject(new Error('communityUrlApi must be set'))
  }

  const isShowArticles = computed<boolean>(() => store.getters['community/communities'].filter(c => c.userCommunity || c.type === 'recommended').filter(community => !community.chatOnly).length > 0 && !!communityUrlApi())

  const clubLink = computed(() => getUrlWithAuthParamsCommunity(communityUrlApi()))

  return {
    communities,
    articles,
    previewLoaded,
    fetchArticles,
    isShowArticles,
    clubLink
  }
}
