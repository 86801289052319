/* istanbul ignore file */
import { defineRule, configure } from 'vee-validate'
import { required, email, confirmed, min, max } from '@vee-validate/rules'
import { localize, setLocale } from '@vee-validate/i18n'
import _en from '@vee-validate/i18n/dist/locale/en.json'
import _ru from '@vee-validate/i18n/dist/locale/ru.json'
import _es from '@vee-validate/i18n/dist/locale/es.json'

import locale from '@web/common/locale'
import Time from '@web/common/Time'
import i18n from '@web/plugins/i18n'

const en = _en
en.messages.confirmed = i18n.global.t('errorMessages.confirmed').replace('<b>field</b>', '<b>{field}</b>')
const ru = _ru
ru.messages.confirmed = i18n.global.t('errorMessages.confirmed').replace('<b>field</b>', '<b>{field}</b>')
const es = _es
es.messages.confirmed = i18n.global.t('errorMessages.confirmed').replace('<b>field</b>', '<b>{field}</b>')

defineRule('required', required)
defineRule('email', email)
defineRule('confirmed', confirmed)
defineRule('min', min)
defineRule('max', max)
defineRule('instagram', value => {
  if (!value.length) {
    return true
  }
  if (value[0] === '.') {
    return i18n.global.t('error.instagramFirstDot')
  }
  if (value[value.length - 1] === '.') {
    return i18n.global.t('error.instagramLastDot')
  }
  if (value.indexOf('..') > -1) {
    return i18n.global.t('error.instagramMoreDot')
  }
  const regExp = /^[A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))$/
  if (value.length === 1) {
    if (!/[A-Za-z0-9_]/.test(value)) {
      return i18n.global.t('error.instagram')
    }
  } else if (!regExp.test(value)) {
    return i18n.global.t('error.instagram')
  }
  if (value === 'anyclass.faceonline') {
    return i18n.global.t('error.instagramLikeExample')
  }
  return true
})
defineRule('birthday', value => {
  let _date
  if (!value || !value.length) {
    return true
  }

  if (value.length === 10) {
    _date = Time(value, 'DD.MM.YYYY')
  }

  if ((_date && !_date.isValid()) || value.length < 10) {
    return i18n.global.t('error.birthday')
  }

  return true
})

configure({
  generateMessage: localize({
    en,
    ru,
    es
  })
})

setLocale(locale.get())
