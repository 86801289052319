import i18n from '@web/plugins/i18n'

import {
  MenuState,
  ItemMenu,
  Menu,
  MenuGettersTree
} from '@web/store/types/modules/menu'
import {
  isVideoConsultationSupport,
  guidelineUrl,
  instructionUrl,
  communityUrlFrontend
} from '@web/common/ExternalConfig/getters'
import { getUrlWithAuthParamsCommunity } from '@web/common/Utils'
import PAGE_NAME from '@web/consts/PAGE_NAME'

const state: MenuState = {
  home: {
    icon: '-course',
    name: String(i18n.global.t('myCourses')),
    link: {
      name: PAGE_NAME.HOME
    },
    note: '',
    type: 'router-link'
  },
  course: {
    icon: '-course',
    name: String(i18n.global.t('myCourses')),
    link: {
      name: 'courses'
    },
    note: '',
    type: 'router-link'
  },
  chat: {
    icon: '-chat',
    name: String(i18n.global.t('messages')),
    link: {
      name: 'chats'
    },
    note: '',
    type: 'router-link'
  },
  homework: {
    icon: '-homework',
    name: String(i18n.global.t('homeworks')),
    link: {
      name: 'homeworks'
    },
    note: '',
    type: 'router-link'
  },
  videoConsultations: {
    icon: '-videoconsultations',
    name: String(i18n.global.t('videoConsultations.VideoConsultations')),
    link: {
      name: 'videoconsultations'
    },
    note: '',
    type: 'router-link'
  },
  support: {
    icon: '-support',
    name: String(i18n.global.t('supportCenter')),
    link: { name: 'support' },
    note: '',
    type: 'router-link'
  },
  edit: {
    icon: '-userEdit',
    name: String(i18n.global.t('editProfile')),
    link: {
      name: 'userEdit'
    },
    note: '',
    type: 'router-link'
  },
  socialNetworks: {
    icon: '-link',
    name: String(i18n.global.t('linkSocialNetworks')),
    link: {
      name: 'socialNetworks'
    },
    note: '',
    type: 'router-link'
  },
  settings: {
    icon: '-settings',
    name: String(i18n.global.t('Settings')),
    link: {
      name: 'userSettings'
    },
    note: '',
    type: 'router-link'
  },
  password: {
    icon: '-password',
    name: String(i18n.global.t('password')),
    link: {
      name: 'userPassword'
    },
    note: '',
    type: 'router-link'
  },
  catalog: {
    icon: '-catalog',
    name: String(i18n.global.t('catalog')),
    link: {
      name: 'catalog'
    },
    note: '',
    type: 'router-link'
  },
  points: {
    icon: '-points',
    name: String(i18n.global.t('Points')),
    link: {
      name: 'points'
    },
    type: 'router-link'
  },
  deleteMenu: {
    icon: '-delete',
    name: String(i18n.global.t('Delete')),
    link: {
      name: 'delete'
    },
    type: 'router-link'
  },
  exit: {
    icon: '-exit',
    name: String(i18n.global.t('accountExit')),
    link: {
      name: 'logout'
    },
    type: 'router-link'
  }
}

const getters: MenuGettersTree = {
  general ({ home, homework, chat, videoConsultations, support }, getters, rootState, rootGetters) {
    const menu: Menu = {
      home,
      community: {
        icon: '-community',
        name: String(i18n.global.t('Community')),
        link: getUrlWithAuthParamsCommunity(communityUrlFrontend()),
        type: 'club'
      },
      homework,
      chat,
      videoConsultations,
      support
    }

    const communities = rootGetters['community/communities']
      .filter(community => community.userCommunity)
      .filter(community => !community.chatOnly)

    if (!communityUrlFrontend() || !communities.length) {
      delete menu.community
    }

    return menu
  },

  setting ({ support, edit, settings, socialNetworks, points, exit }, getters, rootState, rootGetters) {
    const menu: Menu = {
      support,
      edit,
      settings,
      points
    }

    if (guidelineUrl()) {
      menu.guideline = {
        icon: '-manual',
        name: String(i18n.global.t('UserManual')),
        link: guidelineUrl(),
        type: 'modal'
      }
    }

    if (instructionUrl()) {
      menu.instruction = {
        icon: '-instruction',
        name: String(i18n.global.t('instruction')),
        link: instructionUrl(),
        type: 'modal'
      }
    }

    if (rootGetters['assets/authCollection'].length) {
      menu.socialNetworks = socialNetworks
    }

    menu.exit = exit

    return menu
  },

  mobile ({ support }, { general, setting }) {
    return {
      support,
      ...general,
      ...setting
    }
  },
  mobileProfile ({ support, edit, settings, socialNetworks, videoConsultations, catalog, deleteMenu }, getters, rootState, rootGetters) {
    let menu: ItemMenu[] = [
      {
        ...edit,
        name: String(i18n.global.t('myInformation'))
      },
      socialNetworks,
      settings,
      videoConsultations,
      support,
      catalog,
      deleteMenu
    ]

    if (!rootGetters['assets/authCollection'].length) {
      menu = menu.filter(({ link }) => typeof link !== 'string' && link.name !== 'socialNetworks')
    }

    if (isVideoConsultationSupport()) {
      menu = menu.filter(({ link }) => typeof link !== 'string' && link.name !== 'videoconsultations')
    }

    return menu
  }
}

export default {
  namespaced: true,
  state,
  getters
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
