import { useStore } from '@web/store'
import sentry from '@web/common/sentry'

const store = useStore()

export function showWarningSubscription (): boolean {
  const user = store.getters['user/user']
  const isLoadedSubscriptions = store.getters['subscription/isLoaded']
  const hasSubscription = store.getters['subscription/hasSubscription']
  const subscriptions = store.getters['subscription/rawSubscriptions']
  const isLoadedStreams = store.getters['stream/isLoadedAll']
  const streams = store.getters['stream/streams']

  if (!isLoadedSubscriptions || hasSubscription) {
    // Not show warning
    return false
  }

  if (isLoadedStreams && streams.every(stream => stream.isOptionalPurchase)) {
    if (subscriptions.length) {
      // Show warning
      return true
    }

    sentry.captureMessage(`Error. No subscriptions, but user have stream isOptionalPurchase. User ID: ${user.id}`)
  }

  // Not show warning
  return false
}
