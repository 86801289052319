export const APP_VERSION = process.env.VUE_APP_VERSION || '0.0.0'
export const APP_NAME = process.env.VUE_APP_APP_NAME
export const APPLICATION_TYPE = process.env.VUE_APP_PLATFORM || 'browser'
export const IS_WEB = Boolean(!process.env.VUE_APP_PLATFORM || process.env.VUE_APP_PLATFORM === 'browser')
export const IS_MOBILE = Boolean(process.env.VUE_APP_PLATFORM && process.env.VUE_APP_PLATFORM !== 'browser')
export const IS_ANDROID = process.env.VUE_APP_PLATFORM === 'android'
export const IS_IOS = process.env.VUE_APP_PLATFORM === 'ios'
export const APP_ID = process.env.VUE_APP_ID || 'web'
export const APPS_FLYER_DEV_KEY = 'PM4sqAbuRzDQNb3UvpQHr9'
export const BASE_URL = (process.env.VUE_APP_AXIOS_BASE_URL || '').replace(/\/$/g, '') || '/spa/api/v1'

export const APP_IDS = {
  DEV: 'com.anyclass.dev',
  ANYCLASS: 'face.anyclass',
  FACE: 'com.anyclass',
  MIND: 'mind.anyclass',
  WEB: 'web',
  IQFACE: 'ru.iqface',
  LOCAL: 'local',
  TAKECLASS: 'me.takeclass'
}

function getAppleId (): number | null {
  switch (APP_ID) {
    case APP_IDS.DEV:
      return 1526471494
    case APP_IDS.FACE:
      return 1535652676
    case APP_IDS.ANYCLASS:
      return 1523064047
    case APP_IDS.MIND:
      return 1535046022
    case APP_IDS.IQFACE:
      return 1564066406
    case APP_IDS.TAKECLASS:
      return 0 // TODO: заполнить, когда создадим приложение в appstore
    default:
      return null
  }
}

function getUrlScheme (): string {
  switch (APP_ID) {
    case APP_IDS.DEV:
      return 'devany'
    case APP_IDS.FACE:
      return 'comanyclass'
    case APP_IDS.ANYCLASS:
      return 'faceanyclass'
    case APP_IDS.MIND:
      return 'mindanyclass'
    case APP_IDS.IQFACE:
      return 'iqface'
    case APP_IDS.TAKECLASS:
      return 'takeclass'
    default:
      return ''
  }
}

function getAppStoreProductPrefix (): string {
  switch (APP_ID) {
    case APP_IDS.DEV:
      return 'com_anyclass_dev_'
    case APP_IDS.FACE:
      return 'com_anyclass_'
    case APP_IDS.ANYCLASS:
      return 'face_anyclass_'
    case APP_IDS.MIND:
      return 'mind_anyclass_'
    case APP_IDS.IQFACE:
      return 'iqface_'
    case APP_IDS.TAKECLASS:
      return 'takeclass_'
    default:
      return 'face_anyclass_'
  }
}

function getAmplitudeApiKey (): string {
  switch (APP_ID) {
    case APP_IDS.FACE:
      return ''
    case APP_IDS.ANYCLASS:
      return ''
    case APP_IDS.TAKECLASS:
      return ''
    case APP_IDS.MIND:
      return '3f3dc99f9b1300902b15b77c671cea44'
    case APP_IDS.WEB:
      return ''
    case APP_IDS.DEV:
    case APP_IDS.LOCAL:
      return ''
    default:
      return ''
  }
}

export const APPLE_ID = getAppleId()
export const URL_SCHEME = getUrlScheme()
export const APP_STORE_PRODUCT_PREFIX = getAppStoreProductPrefix()
export const AMPLITUDE_API_KEY = getAmplitudeApiKey()
export const SENTRY_DSN = String(process.env.VUE_APP_SENTRY_DSN)
