import Mapper from './Mapper'
import { Extend } from '@web/store/types/modules/extend'

interface Discount {
  success: boolean;
}
/* istanbul ignore next */
export default class ExtendMapper extends Mapper {
  static toClient (data): Extend {
    return Mapper.toClient(data) as Extend
  }

  static toClientCheckDiscount (data): Discount {
    return Mapper.toClient(data) as Discount
  }
}
