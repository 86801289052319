import CommunityApi from '@web/api/modules/Community'
import {
  CommunityActionsTree,
  CommunityGettersTree,
  CommunityMutationsTree,
  CommunityState
} from '@web/store/types/modules/community'
import OneSignal from '@web/common/OneSignal'
import Token from '@web/common/token'
import { Community, UserCommunity } from '@web/types/Community'
import Time from '@web/common/Time'
import { Subscription } from '@web/types/Subscription'

const state: CommunityState = {
  all: new Map(),
  userCommunities: new Map(),
  loaded: true
}

function getType ({ community, courseIds, hasSubscription, activeSubscriptions }: { community: Community, courseIds: number[]; hasSubscription: boolean; activeSubscriptions: Subscription[] }): 'recommended' | 'owned' | 'expired' | 'hidden' {
  if (!community?.userCommunity && community.courseIds?.some(courseId => courseIds.includes(courseId))) {
    return 'recommended'
  }

  if (community?.userCommunity) {
    if (community?.userCommunity.isOptionalPurchase) {
      // Если клуб куплен через встроенную покупку
      // то проверяем есть ли подписка
      if (!hasSubscription) {
        return 'expired'
      }

      const hasCommunity = activeSubscriptions.find(s => s.raw.content.communities.find(c => c.id === community?.userCommunity?.communityId))
      return hasCommunity ? 'owned' : 'expired'
    } else {
      const now = Time().unix() * 1000
      // Иначе, если клуб куплен НЕ через встроенную покупку, то просто проверяем по датам
      // Если текущая дата меньше даты старта клуба
      if (now < community?.userCommunity.startDate) {
        return 'hidden'
      }

      // Если текущая дата больше даты старта клуба и меньше даты окончания клуба
      if (now >= community?.userCommunity.startDate && now <= community?.userCommunity.endDate) {
        return 'owned'
      }

      return 'expired'
    }
  }

  return 'hidden'
}

const getters: CommunityGettersTree = {
  communities: (state, getters, rootState, rootGetters) => {
    const activeSubscriptions = rootGetters['subscription/activeSubscriptions']
    const hasSubscription = rootGetters['subscription/hasSubscription']
    const courseIds = Object.keys(rootGetters['course/courses'] || []).map(n => Number(n))
    return Array.from(state.all.values())
      .map((community) => {
        const userCommunity: UserCommunity | undefined = state.userCommunities.get(community.id)
        const _community: Community = {
          ...community
        }
        if (userCommunity) {
          _community.userCommunity = userCommunity
        }
        return {
          ..._community,
          type: getType({ community: _community, courseIds, hasSubscription, activeSubscriptions })
        }
      })
  }
}

const mutations: CommunityMutationsTree = {
  receiveCommunities (state, payload) {
    for (const community of payload) {
      state.all.set(community.id, community)
    }
  },
  receiveUserCommunities (state, payload) {
    for (const userCommunity of payload) {
      state.userCommunities.set(userCommunity.communityId, userCommunity)
    }
  },
  setLoaded (state, payload) {
    state.loaded = payload
  },
  logout (state) {
    state.all.clear()
    state.userCommunities.clear()
    state.loaded = true
  }
}

const actions: CommunityActionsTree = {
  fetchCommunities ({ getters, commit, state }) {
    if (!Token.get() || !state.loaded) {
      return Promise.resolve(false)
    }

    commit('setLoaded', false)

    return CommunityApi
      .fetchAll()
      .then(response => {
        commit('receiveCommunities', response.data)
        commit('setLoaded', true)
        return Promise.resolve(true)
      })
      .finally(() => {
        commit('setLoaded', true)
        getters.communities.forEach(c => {
          if (['recommended', 'owned', 'expired'].includes(c.type)) {
            OneSignal.prepareTags({ [`community_${c.id}`]: c.type })
          }
        })
        OneSignal.readyTags.community = true
        OneSignal.sendPrepareTags()
      })
  },
  fetchUserCommunities ({ commit, state }) {
    if (!Token.get() || !state.loaded) {
      return Promise.resolve(false)
    }
    return CommunityApi
      .fetchUserCommunity()
      .then(response => {
        commit('receiveUserCommunities', response.data)
        return Promise.resolve(true)
      })
  },
  async fetchCachedCommunities ({ getters, dispatch }) {
    if (!Token.get()) {
      return Promise.resolve(false)
    }

    if (getters.communities.length) {
      return Promise.resolve(true)
    }

    await dispatch('fetchUserCommunities')
    return dispatch('fetchCommunities')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
// eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
