/* istanbul ignore file */
import client from '../client'
import ExtendMapper from '@web/mapper/ExtendMapper'
import HeaderMapper from '@web/mapper/HeaderMapper'

import { ExtendParams } from '@web/store/types/modules/extend'

export default class Extend {
  static fetchAll ({ streamId }) {
    return client.get(`/extend/${streamId}`)
      .then((response) => {
        return {
          data: response.data.map(ExtendMapper.toClient),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static createExtend ({
    streamId,
    serviceId,
    discount,
    phone,
    name,
    email
  }: ExtendParams) {
    return client.post(`/extend/${streamId}/course-service/${serviceId}`, {
      phone,
      name,
      email,
      promocode: discount
    })
      .then((response) => {
        return {
          data: ExtendMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static checkDiscount ({ serviceId, discount }) {
    return client.get(`/discount/check/code/${discount}/course-service/${serviceId}`)
      .then((response) => {
        return {
          data: ExtendMapper.toClientCheckDiscount(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }
}
