import STREAM_STATUS, { USER_COURSE_STREAM_FREEZE_STATUS } from '@web/consts/StreamStatus'
import EXTERNAL_AUTHS_ERRORS from '@web/consts/ExternalAuthsErrors'
import md5 from 'locutus/php/strings/md5'

import Logger from '@web/common/Logger'
import { IS_MOBILE } from '@web/common/config'
import Time from '@web/common/Time'
import isPromise from 'is-promise'
import Token from '@web/common/token'
import urlParse from 'url-parse'
import LocalStorage from '@web/common/LocalStorage'
import { Stream } from '@web/store/types/modules/stream'
import i18n from '@web/plugins/i18n'

export const getCountDayRemaining = ({ status, startDate, endDate }: { status: string; startDate: number, endDate: number }): number => {
  const getTime = time => Math.abs(Date.now() - time)
  const time = status === STREAM_STATUS.OPEN ? getTime(endDate) : getTime(startDate)
  return Math.ceil(time / 24 / 60 / 60 / 1000)
}

/* istanbul ignore next */
export const loadScript = (src: string, onload?: ((this: GlobalEventHandlers, ev: Event) => unknown) | null): void => {
  const script = document.createElement('script')
  script.setAttribute('src', src)
  if (onload) {
    script.onload = onload
  }
  document.head.appendChild(script)
}

/* istanbul ignore next */
export const isOnline = (): boolean => window.navigator.onLine
/* istanbul ignore next */
export const isOffline = (): boolean => !window.navigator.onLine

interface NumberDecliner {
  n: number;
  single?: string;
  multiple2?: string;
  multiple: string;
}

export function getNumberDecliner ({ n, single, multiple, multiple2 }: NumberDecliner): string {
  const absNum = Math.abs(n)
  const cases = [2, 0, 1, 1, 1, 2]
  const optionsArray = [single || multiple, multiple2 || multiple, multiple]
  return optionsArray[(absNum % 1 !== 0) ? 1 : (absNum % 100 > 4 && absNum % 100 < 20) ? 2 : cases[(absNum % 10 < 5) ? absNum % 10 : 5]]
}

export const prettyHoursMinutes = (value: number): string => {
  const time: number = value / 60 / 1000
  const hoursValue: number = time / 60 > 1 ? Math.floor(time / 60) : 0
  let hoursLabel = ''
  const minutesValue: number = Math.ceil(time % 60)
  let minutesLabel = ''

  hoursLabel = getNumberDecliner({
    n: hoursValue,
    single: i18n.global.t('hour'),
    multiple: i18n.global.t('hours5'),
    multiple2: i18n.global.t('hours')
  })

  minutesLabel = getNumberDecliner({
    n: minutesValue,
    single: i18n.global.t('minute'),
    multiple: i18n.global.t('minutes5'),
    multiple2: i18n.global.t('minutes')
  })

  let prettyDate = ''
  if (hoursValue > 0) {
    prettyDate += `${hoursValue} ${hoursLabel}`
  }
  if (minutesValue > 0) {
    prettyDate += ` ${minutesValue} ${minutesLabel}`
  }
  return prettyDate
}

export const performanceEnd = (start: number, end = performance.now()): number => parseFloat((end - start).toFixed(2))

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFileUploadErrorMessage = async (error: any): Promise<string> => {
  if (error.response) {
    const response = error.response
    if (response?.data?.message) {
      return error.response.data.message
    }
    if (response?.json) {
      const data = await response.json()
      if (Array.isArray(data) && data.length > 0 && data[0]?.message) {
        return data.map(m => m.message).join('; ')
      }
    }
  }

  if (error.name && error.message) {
    return `${error.name} ${error.message}}`
  }

  return i18n.global.t('error.unknownError')
}

export function converterSpaAbsoluteToRelativePath (url: string): string {
  return url.replace(/^http.+\..+class/, '')
}

export const getNoteCountDayRemaining = (stream: Stream, until = false): string => {
  const countDay = getCountDayRemaining(stream)
  const textDays = getNumberDecliner({
    n: countDay,
    single: i18n.global.t('day'),
    multiple: i18n.global.t('days'),
    multiple2: i18n.global.t('days2')
  })

  if (stream.status === STREAM_STATUS.NOT_STARTED) {
    return `${i18n.global.t('beforeCourseStart')} ${countDay} ${textDays}`
  }

  if (stream.status === STREAM_STATUS.FREEZE && stream.userCourseStreamFreeze?.status === USER_COURSE_STREAM_FREEZE_STATUS.active) {
    return `${i18n.global.t('courseFrozenUntil')} ${Time(stream.userCourseStreamFreeze?.endDate, 'YYYY-MM-DD').format('DD MMMM YYYY')}`
  }

  if (stream.status === STREAM_STATUS.STOPPED) {
    return `${i18n.global.t('accessStopped')} ${Time(stream.endDate).format('DD MMMM YYYY')}`
  }

  if (stream.status === STREAM_STATUS.ENDED) {
    return `${i18n.global.t('accessEnded')} ${Time(stream.endDate).format('DD MMMM YYYY')}`
  }

  if (until) {
    return `${i18n.global.t('beforeCourseAccessEnded')} ${countDay} ${textDays}`
  }
  return `${i18n.global.t('beforeCourseEnded')} ${Time(stream.endDate).format('DD MMMM YYYY')}`
}

/* istanbul ignore next */
export function openExternalLink (nodeEl: HTMLElement): void {
  nodeEl.addEventListener('click', event => {
    if (IS_MOBILE) {
      event.preventDefault()
      event.stopPropagation()
      const el: HTMLAnchorElement | null = (event.target as HTMLElement).closest('[href]')
      const url: string | undefined = el?.href
      if (url) {
        if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
          if (window?.cordova?.InAppBrowser) {
            window.cordova.InAppBrowser.open(url, '_system')
            event.preventDefault()
            return true
          }
        }
      }
    }
  })
}

export function asyncForEach<T = unknown> (coll: T[], fn: (payload: T) => unknown): Promise<void> {
  return new Promise((resolve, reject) => {
    if (!coll.length) {
      return resolve()
    }

    const iter = (coll: T[]) => {
      const [head, ...rest] = coll
      setTimeout(() => {
        const response: unknown = fn(head)
        const data = isPromise(response) ? (response as Promise<unknown>) : Promise.resolve(response)
        data
          .then(() => {
            if (rest.length > 0) {
              iter(rest)
            } else {
              resolve()
            }
          })
          .catch(reject)
      }, 0)
    }
    iter(coll)
  })
}

export const getUrlWithAuthParams = (link: string): string => {
  const urlInstance = urlParse(link, true)
  urlInstance.set('query', {
    ...urlInstance.query,
    token: Token.get(),
    _uah: md5(window.navigator.userAgent)
  })
  return urlInstance.toString()
}

export const getUrlWithAuthParamsCommunity = (link: string): string => {
  const urlInstance = urlParse(link, true)
  urlInstance.set('query', {
    ...urlInstance.query,
    token: Token.get(),
    _uah: md5(window.navigator.userAgent)
  })
  return urlInstance.toString()
}

export async function asyncCalcPerformance (func: () => Promise<unknown>): Promise<number> {
  const start = performance.now()
  await func()
  return performanceEnd(start)
}

export async function asyncPerformance (name: string, func: () => Promise<unknown>): Promise<void> {
  const time = await asyncCalcPerformance(func)
  Logger.info(`[WPO] Действие "${name}" заняло ${time} мс`)
}

export function getExternalAuthsError ({ errorCode, errorMessage }: { errorCode: string; errorMessage: string }): string {
  return i18n.global.t(`externalAuthErrors.${EXTERNAL_AUTHS_ERRORS[errorCode] || 'unknown_error'}`, { errorCode, errorMessage })
}

// See: https://stackoverflow.com/questions/1497481/javascript-password-generator
export function generatePassword (
  length = 8,
  charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
): string {
  let retVal = ''
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n))
  }
  return retVal
}

export function rememberUserCredentials (email: string, password: string): void {
  LocalStorage.set('u_email', email)
  LocalStorage.set('u_password', password)
}
