import Mapper from '@web/mapper/Mapper'
import { Installment } from '@web/types/Installment'

export default class InstallmentMapper extends Mapper {
  static toClient (data: unknown): Installment {
    const convertedData = InstallmentMapper.converter({
      data
    }) as Installment

    return convertedData
  }
}
