import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "asTextarea" }
const _hoisted_2 = {
  key: 0,
  class: "asTextarea_label"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["autofocus"]
const _hoisted_5 = ["value", "placeholder", "maxlength", "autofocus"]
const _hoisted_6 = {
  key: 3,
  class: "asTextarea_error"
}
const _hoisted_7 = {
  key: 4,
  class: "asTextarea_note"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.modelValue.length > 0 && _ctx.maxlength > 0)
            ? (_openBlock(), _createElementBlock("small", _hoisted_3, "(" + _toDisplayString(`${_ctx.modelValue.length} / ${_ctx.maxlength}`) + ")", 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.autoResizable)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          ref: "inputRef",
          class: _normalizeClass(["asTextarea_input", {
        '-isAutoResizable': _ctx.autoResizable
      }]),
          contenteditable: "",
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.input && _ctx.input(...args))),
          autofocus: _ctx.autofocus,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.triggerFocus && _ctx.triggerFocus(...args)))
        }, null, 42, _hoisted_4))
      : (_openBlock(), _createElementBlock("textarea", {
          key: 2,
          ref: "inputRef",
          class: "asTextarea_input",
          value: _ctx.modelValue,
          placeholder: _ctx.placeholder,
          maxlength: _ctx.maxlength,
          onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.input && _ctx.input(...args))),
          autofocus: _ctx.autofocus
        }, null, 40, _hoisted_5)),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.error), 1))
      : (_ctx.note)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.note), 1))
        : _createCommentVNode("", true)
  ]))
}