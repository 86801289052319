import Mapper from './Mapper'
import { AvailableVideoConsultation, PurchasedVideoConsultation } from '@web/store/types/modules/videoConsultation'

/* istanbul ignore next */
export default class VideoConsultationsMapper extends Mapper {
  static toClient (data): PurchasedVideoConsultation | AvailableVideoConsultation {
    return Mapper.converter({
      data
    }) as PurchasedVideoConsultation | AvailableVideoConsultation
  }
}
