import Mapper from './Mapper'
import { UserScore } from '@web/store/types/modules/userScore'

/* istanbul ignore next */
export default class UserScoreMapper extends Mapper {
  static toClient (data): UserScore {
    if (typeof data !== 'object') {
      throw new Error('[UserScoreMapper] UserScore data must be object')
    }

    const userScore = UserScoreMapper.converter({
      data,
      rules: {
        updated_at: {
          cb: value => value * 1000
        }
      }
    }) as UserScore

    userScore.type = userScore.amount > 0 ? 'add' : 'debit'
    userScore.amount = Math.abs(userScore.amount)

    return userScore
  }
}
