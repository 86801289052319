import Mapper from './Mapper'
import { Message } from '@web/store/types/modules/chat'
import FileMapper from '@web/mapper/FileMapper'

/* istanbul ignore next */
export default class MessageMapper extends Mapper {
  static toClient (data): Message {
    const message: Message = Mapper.converter({
      data,
      rules: {
        // eslint-disable-next-line camelcase
        is_viewed: { cb: v => v === 1 },
        // eslint-disable-next-line camelcase
        created_at: { cb: value => value * 1000 },
        // eslint-disable-next-line camelcase
        updated_at: { cb: value => value * 1000 },
        employee: {
          cb: value => {
            return Mapper.converter({
              data: value,
              rules: {
                // eslint-disable-next-line camelcase
                is_expert: {
                  type: 'boolean'
                },
                file: {
                  cb: value => value && FileMapper.toClient(value)
                }
              }
            })
          }
        }
      }
    }) as Message
    if (Array.isArray(message.files)) {
      message.files = message.files.map(file => {
        return Mapper.converter({
          data: file,
          rules: {
            url: {
              baseUrl: true
            },
            urls: {
              baseUrls: true
            }
          }
        }) as File
      })
    }
    return message
  }

  static toServer (data) {
    const message = { ...data }

    if (data.isViewed) {
      // eslint-disable-next-line camelcase
      message.is_viewed = data.isViewed ? 1 : 0
      delete message.isViewed
    }
    if (data.userId) {
      // eslint-disable-next-line camelcase
      message.user_id = data.userId
      delete message.userId
    }
    if (data.dialogId) {
      // eslint-disable-next-line camelcase
      message.dialog_id = data.dialogId
      delete message.dialogId
    }
    if (data.files && data.files.length) {
      message.files = data.files
    }

    return message
  }
}
