/* istanbul ignore file */
import client from '../client'
import StreamMapper from '@web/mapper/StreamMapper'
import StatusesMapper from '@web/mapper/StatusesMapper'
import HeaderMapper from '@web/mapper/HeaderMapper'
import { Service } from '@web/store/types/modules/service'
import { UserCourseStreamFreeze, Stream as IStream } from '@web/store/types/modules/stream'

interface Params {
  fields?: string[] | string;
  expand?: string[] | string;
}

const getParams = ({ fields, expand = [] }: Params = {}) => {
  const params = {
    expand: '',
    fields: ''
  }

  if (!Array.isArray(expand)) {
    expand = expand.split(',').filter((el) => el.length > 0)
  }

  if (expand.length > 0) {
    params.expand = expand.toString()
  }

  if (Array.isArray(fields)) {
    params.fields = fields.join(',')
  }

  return params
}

export default class Stream {
  static fetchAll (params: Params): Promise<{
    data: {
      stream: IStream;
      course: Course | undefined;
      stages: Stage[];
      stageHomeworks: StageHomework[];
      days: Day[];
      dayHomeworks: DayHomework[];
      lessons: Lesson[];
      videos: FileVideo[];
      homeworks: Homework[];
      service: Service | undefined;
    }[];
    headers: Headers;
  }> {
    return client.get('/user-course-streams', { params: getParams(params) })
      .then((response) => {
        return {
          data: response.data.map(StreamMapper.toClient),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static fetch ({ id, ...params }: { id: number } & Params): Promise<{
    data: {
      stream: Stream;
      course: Course | undefined;
      stages: Stage[];
      stageHomeworks: StageHomework[];
      days: Day[];
      dayHomeworks: DayHomework[];
      lessons: Lesson[];
      videos: FileVideo[];
      homeworks: Homework[];
      service: Service | undefined;
    };
    headers: Headers;
  }> {
    return client.get(`/user-course-streams/${id}`, { params: getParams(params) })
      .then(response => {
        return {
          data: StreamMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static fetchUpdateAll (ids: string[] | number[] = []): Promise<{
    data: {
      stream: Stream;
      course: Course | undefined;
      stages: Stage[];
      stageHomeworks: StageHomework[];
      days: Day[];
      dayHomeworks: DayHomework[];
      lessons: Lesson[];
      videos: FileVideo[];
      homeworks: Homework[];
      service: Service | undefined;
    }[];
    headers: Headers;
  }> {
    return client.get('/user-course-streams', {
      params: {
        'filter[id][]': ids.join(','),
        fields: 'id,progress'
      }
    })
      .then((response) => {
        return {
          data: response.data.map(StreamMapper.toClient),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static fetchUpdate (id: number): Promise<{
    data: {
      stream: Stream;
      course: Course | undefined;
      stages: Stage[];
      stageHomeworks: StageHomework[];
      days: Day[];
      dayHomeworks: DayHomework[];
      lessons: Lesson[];
      videos: FileVideo[];
      homeworks: Homework[];
      service: Service | undefined;
    };
    headers: Headers;
  }> {
    return client.get(`/user-course-streams/${id}`, {
      params: {
        fields: 'id,progress'
      }
    })
      .then((response) => {
        return {
          data: StreamMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static fetchStatuses ({ id, requestAll, ...params }: { id: number; requestAll?: boolean; } & Params) {
    const options = getParams(params)

    if (requestAll) {
      options.expand = 'days,homeworks,stages,lessons,stages,consultations,chatChannelMeta,current_training_stage_id,current_training_day'
    }

    return client.get(`/user-course-streams/${id}/status`, { params: options })
      .then((response) => {
        return {
          data: StatusesMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static freezeStream ({ streamId, startDate, endDate }: { streamId: number; startDate: string; endDate: string }): Promise<{
    data: UserCourseStreamFreeze;
    headers: Headers;
  }> {
    return client.post(`/user-course-streams/${streamId}/freeze/create`, {
      start_date: startDate,
      end_date: endDate
    })
      .then(response => {
        return {
          data: StreamMapper.userCourseStreamFreezeToClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static unfreezeStream ({ streamId, ...params }: { streamId: number; } & Params): Promise<{
    data: UserCourseStreamFreeze;
    headers: Headers;
  }> {
    return client.get(`/user-course-streams/${streamId}/freeze/cancel`, { params: getParams(params) })
      .then(response => {
        return {
          data: StreamMapper.userCourseStreamFreezeToClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }
}
