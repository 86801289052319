import Mapper from './Mapper'
import { DayStatus } from '@web/store/types/modules/stream'

export default class DayStatusMapper extends Mapper {
  static toClient (data): DayStatus {
    return Mapper.converter({
      data,
      rules: {
        // eslint-disable-next-line camelcase
        user_course_stream_id: {
          rename: 'streamId'
        },
        // eslint-disable-next-line camelcase
        start_time: {
          type: 'number'
        },
        // eslint-disable-next-line camelcase
        end_time: {
          type: 'number',
          cb: value => value * 1000
        },
        // eslint-disable-next-line camelcase
        stage_day_started_at: {
          type: 'number',
          cb: value => value * 1000
        }
      },
      defaults: {
        isLocked: false
      }
    }) as DayStatus
  }
}
