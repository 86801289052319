import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { IS_MOBILE, SENTRY_DSN } from '@web/common/config'
import { Options, TracingOptions, Vue } from '@sentry/vue/dist/types'
import { Router } from 'vue-router'
import { User } from '@web/store/types/modules/user'
import { ignoreErrors } from '@web/common/sentry/ingoreErrors'

export const options: Partial<Omit<Options, 'tracingOptions'> & { tracingOptions: Partial<TracingOptions>; }> = {
  dsn: SENTRY_DSN,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  ignoreErrors
}

if (IS_MOBILE) {
  options.release = process.env.VUE_APP_VERSION
}

export function init (app: Vue, router: Router) {
  if (SENTRY_DSN) {
    Sentry.init({
      app,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        })
      ],
      ...options
    })
  }
}

export function sentryConfigure (user: User, username: string): void {
  Sentry.configureScope(scope => {
    const { id, email, ip } = user
    if (id) {
      scope.setUser({
        id: String(id),
        email,
        username,

        ip_address: ip
      })
    }
  })
}

export default Sentry
