import InstallmentMapper from '@web/mapper/InstallmentMapper'
import { Installment } from '@web/types/Installment'
import client from '@web/api/client'

export default class InstallmentApi {
  static async fetchAll (): Promise<Installment[]> {
    const response = await client.get('/installment')
    return response.data.map(item => InstallmentMapper.toClient(item))
  }

  static async fetch (id: number): Promise<Installment> {
    const params = {
      expand: 'cancelPopupText'
    }
    const response = await client.get(`/installment/${id}`, {
      params
    })
    return InstallmentMapper.toClient(response.data)
  }
}
