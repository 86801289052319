import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "asInput_note"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["type", "value", "placeholder", "maxlength", "id", "name", "readonly", "data-cy"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_imask = _resolveDirective("imask")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["asInput", { '-error': _ctx.error }])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "asInput_label",
          for: _ctx.id
        }, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.note)
            ? (_openBlock(), _createElementBlock("small", _hoisted_2, _toDisplayString(_ctx.note), 1))
            : _createCommentVNode("", true),
          (_ctx.showCountLetter)
            ? (_openBlock(), _createElementBlock("small", _hoisted_3, "(" + _toDisplayString(`${_ctx.modelValue.length} / ${_ctx.maxlength}`) + ")", 1))
            : _createCommentVNode("", true)
        ], 8, _hoisted_1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      ref: "inputRef",
      class: "asInput_input",
      type: _ctx.type,
      value: _ctx.modelValue,
      placeholder: _ctx.placeholder,
      maxlength: _ctx.maxlength,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
      onAccept: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onAccept && _ctx.onAccept(...args))),
      onComplete: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onComplete && _ctx.onComplete(...args))),
      onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
      id: _ctx.id,
      name: _ctx.name,
      onFocus: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
      onBlur: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
      readonly: _ctx.readonly,
      "data-cy": `input-${_ctx.name}`
    }, null, 40, _hoisted_4), [
      [_directive_imask, _ctx.mask]
    ]),
    (_ctx.appendIcon)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["asInput_icon", '-' + _ctx.appendIcon]),
          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.clickAppendIcon && _ctx.clickAppendIcon(...args)))
        }, null, 2))
      : _createCommentVNode("", true),
    (_ctx.showHint)
      ? (_openBlock(), _createElementBlock("span", {
          key: 2,
          class: "asInput_hint",
          innerHTML: _ctx.hint
        }, null, 8, _hoisted_5))
      : _createCommentVNode("", true),
    _createElementVNode("span", {
      class: "asInput_error",
      innerHTML: _ctx.error
    }, null, 8, _hoisted_6)
  ], 2))
}