import client from '@web/api/client'
import HeaderMapper from '@web/mapper/HeaderMapper'
import { Community, UserCommunity } from '@web/types/Community'
import CommunityMapper from '@web/mapper/CommunityMapper'

export default class {
  static fetchAll (): Promise<{
    headers: Dictionary<string>;
    data: Community[]
  }> {
    return client.get('/community')
      .then((response) => {
        return {
          data: response.data.map(CommunityMapper.toClient) as Community[],
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static fetchUserCommunity (): Promise<{
    headers: Dictionary<string>;
    data: UserCommunity[]
  }> {
    return client.get('/user-community')
      .then((response) => {
        return {
          data: response.data.map(CommunityMapper.userCommunity) as UserCommunity[],
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }
}
