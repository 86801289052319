import StageApi from '@web/api/modules/Stage'
import keyBy from 'lodash.keyby'
import maxBy from 'lodash.maxby'
import InvalidParamError from '@web/common/errors/InvalidParamError'

import {
  StageState,
  StageGettersTree,
  StageMutationsTree,
  StageActionsTree
} from '@web/store/types/modules/stage'
import Logger from '@web/common/Logger'
import { performanceEnd } from '@web/common/Utils'
import { isDefined } from 'ts-is-present'

const state: StageState = {
  all: {},
  courseStages: {}
}

const getters: StageGettersTree = {
  stages: ({ all }) => all,
  getStageById: ({ all }) => id => all[id],
  getLastDay: (state, getters, rootState) => id => {
    const stage: Stage | undefined = getters.getStageById(id)
    if (!stage) {
      return
    }
    const days: Day[] = stage.dayIds
      .map<Day | undefined>(id => rootState.day.all[id])
      .filter(isDefined)
      .filter(day => !day.dayOfRest)

    return maxBy(days, 'dayNumber')
  }
}

const mutations: StageMutationsTree = {
  receiveStages (state, stages) {
    state.all = {
      ...state.all,
      ...keyBy(stages, 'id')
    }
  },

  receiveStage (state, stage) {
    state.all[stage.id] = stage
  },

  receiveCourseStages (state, { courseId, stageIds }) {
    state.courseStages[courseId] = stageIds
  },

  logout (state) {
    state.all = {}
    state.courseStages = {}
  }
}

/* istanbul ignore next */
const actions: StageActionsTree = {
  setStages ({ commit }, { stages }) {
    commit('receiveStages', stages)
  },
  setStage ({ commit }, { stage }) {
    commit('receiveStage', stage)
  },
  setData ({ commit }, { courseId, data }) {
    const startTime = performance.now()
    const stages: Stage[] = []
    const days: Day[] = []
    const dayHomeworks: DayHomework[] = []
    const stageHomeworks: StageHomework[] = []
    const homeworks: Homework[] = []
    const stageIds: number[] = []
    for (const item of data) {
      stages.push(item.stage)
      days.push(...item.days)
      dayHomeworks.push(...item.dayHomeworks)
      stageHomeworks.push(...item.stageHomeworks)
      homeworks.push(...item.homeworks)
      stageIds.push(item.stage.id)
    }

    commit('receiveStages', stages)
    commit('receiveCourseStages', { courseId, stageIds })
    commit('course/addStages', { courseId, stageIds }, { root: true })
    commit('day/receiveDays', days, { root: true })
    commit('homework/addDayHomeworks', dayHomeworks, { root: true })
    commit('homework/addStageHomeworks', stageHomeworks, { root: true })
    commit('homework/addHomeworks', homeworks, { root: true })

    const endTime = performanceEnd(startTime)
    Logger.info(`[WDO] dispatch(stage/setStages) ${endTime} ms`)
    return stages
  },
  fetchStages ({ dispatch }, { courseId }) {
    if (!courseId) {
      throw new InvalidParamError('courseId must be set')
    }
    return StageApi.fetchAll({ courseId })
      .then(({ data }) => {
        return dispatch('setData', { data, courseId })
      })
  },
  fetchStagesCached ({ state, dispatch }, { courseId }) {
    if (!state.courseStages[courseId]) {
      return dispatch('fetchStages', { courseId })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
