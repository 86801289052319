
import { defineComponent } from 'vue'
import AcButton from '@ui-kit/components/AcButton.vue'

export default defineComponent({
  name: 'AcCookieModalContent',
  components: {
    AcButton
  },
  props: {
    showTitle: {
      type: Boolean,
      default: false
    },
    showCloseBtn: {
      type: Boolean,
      default: false
    },
    isModal: {
      type: Boolean,
      default: true
    },
    disabledAcceptBtn: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'dismiss', 'accept'],
  setup (props, ctx) {
    function close () {
      ctx.emit('close')
    }

    function dismiss () {
      ctx.emit('dismiss')
    }

    function accept () {
      ctx.emit('accept')
    }

    return {
      close,
      dismiss,
      accept
    }
  }
})
