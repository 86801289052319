import { computed, ref, watch } from 'vue'
import { isOffline, getExternalAuthsError, rememberUserCredentials } from '@web/common/Utils'
import Analytics from '@web/services/Analytics/Analytics'
import * as AnalyticEvents from '@web/services/Analytics/events'
import OneSignal from '@web/common/OneSignal'
import sentry, { sentryConfigure } from '@web/common/sentry'
import { useStore } from '@web/store'
import Token from '@web/common/token'
import urlParse from 'url-parse'
import LocalStorage from '@web/common/LocalStorage'
import { IS_WEB } from '@web/common/config'
import Swal from 'sweetalert2'
import i18n from '@web/plugins/i18n'
import PAGE_NAME from '@web/consts/PAGE_NAME'

const store = useStore()

export const useNotice = () => computed({
  get () {
    return store.getters['user/notice']
  },
  set (value: string) {
    store.commit('user/setNotice', value)
  }
})

export const useUsername = email => computed({
  get () {
    return email.value
  },
  set (value: string) {
    email.value = value.toLowerCase()
  }
})

export default function ({ router, serverErrors, displayServerErrors, isLoadDataAfterSuccessLogin }) {
  const email = ref('')
  const password = ref('')
  const save = ref(true)
  const showPassword = ref(false)
  const logo = computed(() => store.getters['assets/logo'])
  const clients = computed(() => store.getters['assets/authCollection'])
  const username = useUsername(email)
  const notice = useNotice()
  const showRestorePasswordButton = ref(false)
  // const serverErrors = ref({})

  const isLoginBtnDisabled = computed(() => !email.value.length || !password.value.length)

  function redirectAfterLogin () {
    const store = useStore()

    if (isLoadDataAfterSuccessLogin) {
      store.dispatch('fetchAllData')
    }
    const returnUrlFromQuery: string | null = router.currentRoute.value.query.returnUrl
    const returnUrlFromStore: string = store.state.main.returnUrl

    function openExternal (url: string): void {
      const URLParseInstance = urlParse(url, true)
      URLParseInstance.set('query', {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...URLParseInstance.query,
        token: Token.get()
      })
      window.location.href = URLParseInstance.toString()
    }

    function openInternal (route): void {
      router.push(route)
    }

    if (returnUrlFromQuery) {
      openExternal(returnUrlFromQuery)
    } else if (/^https?:\/\//.test(returnUrlFromStore)) {
      openExternal(returnUrlFromStore)
    } else if (returnUrlFromStore && returnUrlFromStore !== '/') {
      openInternal(returnUrlFromStore)
    } else {
      openInternal({ name: PAGE_NAME.HOME })
    }
  }

  email.value = email.value || store.getters['user/email']
  watch(email, (value) => {
    store.commit('user/setField', { key: 'email', value })
  })

  function afterLogin (user) {
    redirectAfterLogin()
    if (IS_WEB) {
      OneSignal.setExternalUserId(user.id)
    }
  }

  function catchError (e) {
    if (e.errors) {
      serverErrors.value = e.errors
      if (!Object.keys(e.errors).includes('password')) {
        showRestorePasswordButton.value = false
      } else {
        Analytics.send({
          category: 'restore_password',
          action: 'error_from_server',
          label: displayServerErrors(e.errors)[0]
        })
        showRestorePasswordButton.value = true
      }
    } else {
      notice.value = i18n.global.t('error.signIn')
      sentry.captureException(e)
    }
  }

  const submit = async (errors?: { [key: string]: string }) => {
    if (isLoginBtnDisabled.value || (errors && Object.keys(errors).length)) {
      return
    }

    try {
      if (!save.value) return

      save.value = false
      serverErrors.value = []
      const user = await store.dispatch('user/login', { email: email.value, password: password.value })
      AnalyticEvents.login({
        method: 'email',
        status: 'success',
        userId: user.id
      })
      store.dispatch('community/fetchCachedCommunities')
      sentryConfigure(user, store.getters['user/username'])

      afterLogin(user)
    } catch (e) {
      AnalyticEvents.login({
        method: 'email',
        status: 'fail'
      })
      catchError(e)
    } finally {
      save.value = true
    }
  }

  function autologin (): boolean {
    const emailLocal = LocalStorage.get('u_email')
    const passwordLocal = LocalStorage.get('u_password')

    if (!!emailLocal && !!passwordLocal) {
      email.value = emailLocal
      password.value = passwordLocal

      if (isOffline()) {
        return false
      }
      submit()
      return true
    }

    return false
  }

  function rememberUser () {
    if (email.value && password.value) {
      rememberUserCredentials(email.value, password.value)
    }
  }

  watch(router.currentRoute, (newRoute) => {
    const query: Dictionary<string> = newRoute.query as Dictionary<string>
    const socNetworks = {
      apple: 'Apple',
      google: 'Google',
      facebook: 'Facebook',
      vkontakte: 'Вконтакте'
    }
    if (query.action === 'login') {
      if (query.status === 'fail') {
        let msg = getExternalAuthsError({ errorCode: query.errorCode, errorMessage: query.errorMessage })
        if (query.errorCode === 'login_no_link') {
          msg = i18n.global.t('loginSocialNetworks', { socNetworks: socNetworks[query.method] || query.method })
        }
        Swal.fire({
          title: i18n.global.t('Error'),
          text: msg,
          confirmButtonText: i18n.global.t('good'),
          icon: 'error'
        })

        router.replace({ query: {} })
      }
      if (query.status === 'success') {
        Token.set(String(query.token))
        store.dispatch('user/fetch')
          .then(user => {
            AnalyticEvents.login({
              userId: user.id,
              method: query.method,
              status: 'success'
            })

            router.replace({ query: {} })
            afterLogin(user)
          })
      }
    }
  }, {
    immediate: true
  })

  return {
    email,
    username,
    password,
    isLoginBtnDisabled,
    save,
    showPassword,
    showRestorePasswordButton,
    logo,
    clients,
    notice,
    submit,
    serverErrors,
    autologin,
    rememberUser
  }
}
