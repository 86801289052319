
import { defineComponent, computed, ref, watch } from 'vue'
import { useStore } from '@web/store'
import AcButton from '@ui-kit/components/AcButton.vue'
import CookieModalContent from '@ui-kit/components/AcCookie/CookieModalContent.vue'
import CookieModalBlock from '@ui-kit/components/AcCookie/CookieModalBlock.vue'

import Cookies from 'js-cookie'
import sentry from '@web/common/sentry'
import Logger from '@web/common/Logger'

import { cookiePolicyLink } from '@web/common/ExternalConfig/getters'
import AcModal from '@ui-kit/components/AcModal.vue'
import Analytics from '@web/services/Analytics/Analytics'

export default defineComponent({
  name: 'AcCookie',
  components: {
    CookieModalContent,
    AcModal,
    CookieModalBlock,
    AcButton
  },
  setup () {
    const store = useStore()

    const KEY = 'is_cookies_policy_set'
    const isCookiesPolicySet = ref(Cookies.get(KEY))

    const isShowModal = ref(false)
    const localCookiePolicy = ref({
      essential: true,
      analytics: false,
      marketing: false
    })

    const cookiesIsEnabled = computed(() => store.getters['cookie/isEnabled'])
    const cookies = computed(() => store.getters['cookie/cookies'])
    const cookiePolicy = computed(() => store.getters['cookie/policy'])
    const mappedCookies = computed(() => {
      return Object.keys(cookiePolicy.value)
        .map((type) => {
          const _cookies = Object.values(cookies.value).find((c) => c.key === type)
          return _cookies
            ? {
                ..._cookies,
                enabled: cookiePolicy.value[type]
              }
            : undefined
        })
        .filter(c => c)
    })

    const isShow = computed(() => !isCookiesPolicySet.value && !isShowModal.value && cookiesIsEnabled.value)

    async function accept (type: 'dismiss' | 'accept' | 'acceptAll') {
      if (type === 'dismiss') {
        await store.dispatch('cookie/updatePolicy', {
          essential: true,
          analytics: false,
          marketing: false
        })
      } else if (type === 'acceptAll') {
        await store.dispatch('cookie/updatePolicy', {
          essential: true,
          analytics: true,
          marketing: true
        })
      } else {
        await store.dispatch('cookie/updatePolicy', localCookiePolicy.value)
      }
      Analytics.send({
        category: 'cookie_modal',
        action: `cookie_${type}`,
        label: 'platform'
      })
      Cookies.set(KEY, 'true', { sameSite: 'Strict', expires: 365 })
      isCookiesPolicySet.value = true
      isShowModal.value = false
    }

    function settings () {
      Analytics.send({
        category: 'cookie_modal',
        action: 'cookie_settings',
        label: 'platform'
      })
      isShowModal.value = true
    }

    function closeSettings () {
      isShowModal.value = false
    }

    async function init () {
      try {
        await store.dispatch('cookie/fetchCookies')
        await store.dispatch('cookie/fetchPolicy')
      } catch (e) {
        sentry.captureException(e)
        Logger.error(e)
      }
    }

    // if (!isCookiesPolicySet.value) {
    init()
    // }

    function toggleCookie (type: 'essential' | 'analytics' | 'marketing') {
      if (type !== 'essential') {
        localCookiePolicy.value[type] = !localCookiePolicy.value[type]
      }
    }

    watch(cookiePolicy, () => {
      localCookiePolicy.value = cookiePolicy.value
    }, {
      immediate: true
    })

    return {
      cookiePolicyLink: cookiePolicyLink(),
      isShow,
      isShowModal,
      mappedCookies,
      localCookiePolicy,
      accept,
      settings,
      closeSettings,
      toggleCookie
    }
  }
})
