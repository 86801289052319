import { computed, onMounted, PropType, ref, SetupContext } from 'vue'
import IMask from 'imask'

export const props = {
  mask: {
    type: Object as PropType<IMask.AnyMaskedOptions>,
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  },
  name: {
    type: String
  },
  label: {
    type: String
  },
  placeholder: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'text'
  },
  modelValue: {
    type: [String, Number],
    default: ''
  },
  error: {
    type: String,
    default: ''
  },
  appendIcon: {
    type: String,
    default: ''
  },
  maxlength: {
    type: [String, Number],
    default: ''
  },
  hint: {
    type: String,
    default: ''
  },
  readonly: {
    type: Boolean,
    default: false
  },
  note: {
    type: String,
    default: ''
  },
  min: {
    type: [String, Number],
    default: null
  },
  max: {
    type: [String, Number],
    default: null
  }
}

export interface Props {
  mask: IMask.AnyMaskedOptions | null;
  disabled: boolean;
  name?: string;
  label?: string;
  placeholder: string;
  type: string;
  modelValue: string | number;
  error: string;
  appendIcon: string;
  maxlength: string | number;
  hint: string;
  readonly: boolean;
  note: string;
}

export const emits = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  accept: (payload: string): boolean => true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  complete: (payload: string): boolean => true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  focus: (payload: Event): boolean => true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  blur: (payload: Event): boolean => true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  change: (payload: string): boolean => true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  'click:append': (payload: Event): boolean => true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  'update:modelValue': (payload: string): boolean => true
}

export default function (props: Props, emit: SetupContext<typeof emits>['emit']) {
  const inputRef = ref<HTMLInputElement | null>(null)
  const visibility = ref(true)
  const id = ref(`input${Math.ceil(Math.random() * 1000)}`)
  const showCountLetter = computed(() => props.modelValue && props.modelValue.toString().length > 0 && props.maxlength > 0)
  const showHint = computed(() => !props.error.length && props.modelValue.toString().length && props.hint.length)

  function onInput (event) {
    if (inputRef.value) {
      inputRef.value.setAttribute('value', event.target.value)
    }
    emit('update:modelValue', event.target.value)
  }
  function onAccept (event) {
    emit('accept', event.target.value)
  }
  function onComplete (event) {
    emit('complete', event.target.value)
  }
  function onFocus (event) {
    emit('focus', event)
  }
  function onBlur (event) {
    emit('blur', event)
  }
  function onChange (event) {
    emit('change', event.target.value)
  }
  function clickAppendIcon (e) {
    emit('click:append', e)
  }

  onMounted(() => {
    if (inputRef.value) {
      inputRef.value.setAttribute('value', props.modelValue.toString())
    }
  })

  return {
    visibility,
    id,
    showCountLetter,
    showHint,

    onInput,
    onAccept,
    onComplete,
    onFocus,
    onBlur,
    onChange,
    clickAppendIcon
  }
}
