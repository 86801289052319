import DayApi from '@web/api/modules/Day'
import { getLessonWithTypes, getRepeatLesson } from '@web/common/Day'
import InvalidParamError from '@web/common/errors/InvalidParamError'

import { DayActionsTree, DayGettersTree, DayMutationsTree, DayState } from '@web/store/types/modules/day'
import { isDefined } from 'ts-is-present'
import keyBy from 'lodash.keyby'

const state: DayState = {
  all: {}
}

const getters: DayGettersTree = {
  days: ({ all }, { getDayById }) => {
    const days = {}
    for (const id in all) {
      if (!Object.prototype.hasOwnProperty.call(all, id)) continue

      days[id] = getDayById(Number(id))
    }
    return days
  },
  getDayById: ({ all }, getter, rootState, rootGetters) => id => {
    const day = all[id]
    if (!day) {
      return
    }

    const banners: Banner[] = []
    if (day.bannerIds) {
      day.bannerIds.forEach(bannerId => {
        const banner: Banner | undefined = rootGetters['banner/bannerById'](bannerId)
        if (banner) {
          banners.push(banner)
        }
      })
    }

    return {
      ...day,
      banners
    }
  },
  getDayByHomeworkId: ({ all }) => id => Object.values(all).find(({ dayHomeworkIds }) => dayHomeworkIds.includes(id)),
  getLessonsByDayId: ({ all }, getter, rootState, rootGetters) => id => {
    const day = all[id]
    if (!day) {
      return {
        lessons: [],
        hasDifferentLessonOrientation: false,
        hasDifferentLessonType: false
      }
    }
    const lessonSetLessons = getRepeatLesson(day)
    const lessonSetLessonsWithLesson: {
      lessonSetLesson: LessonSetLesson;
      lesson: Lesson;
      localVideoFile: FileVideo | undefined;
      verticalVideo: FileVideo | undefined;
      localDescriptionVideoFile: FileVideo | undefined;
      verticalDescriptionVideo: FileVideo | undefined;
    }[] = lessonSetLessons
      .map(l => {
        const t = rootGetters['lesson/lesson'](l.lessonId)
        if (t) {
          return {
            lessonSetLesson: l,
            ...t
          }
        }
        return undefined
      })
      .filter(isDefined)
    const lessonWithTypes = getLessonWithTypes(lessonSetLessonsWithLesson)
    const types = new Map()
    const orientations = new Map()
    lessonWithTypes.forEach(l => {
      if (types.size > 1 && orientations.size > 1) {
        return
      }
      types.set(l.meta.type, true)
      orientations.set(l.meta.orientation, true)
    })

    return {
      lessons: lessonWithTypes,
      hasDifferentLessonType: types.size > 1,
      hasDifferentLessonOrientation: orientations.size > 1
    }
  },
  getBannersByDayId: ({ all }, getter, rootState, rootGetters) => id => {
    const day = all[id]
    if (!day || !Array.isArray(day.bannerIds)) {
      return []
    }

    return day.bannerIds
      .map(rootGetters['banner/bannerById'])
      .filter(isDefined)
  },
  getDayByDayNumberAndCourseId: ({ all }, getters, rootState, rootGetters) => ({ dayNumber, courseId }) => {
    const stageIds: number[] = rootGetters['course/getCourseStageIds'](courseId)
    if (!stageIds.length) {
      return
    }
    return Object.values(all).find(day => {
      return day.dayNumber === dayNumber && stageIds.includes(day.stageId)
    })
  },
  getDaysByCourseId: ({ all }, getters, rootState, rootGetters) => (courseId) => {
    const stageIds: number[] = rootGetters['course/getCourseStageIds'](courseId)
    if (!stageIds) {
      return []
    }
    return Object.values(all).filter(day => {
      return stageIds.includes(day.stageId)
    })
  }
}

const addDay = (state, day) => {
  state.all[day.id] = day
}

const mutations: DayMutationsTree = {
  addDay,

  receiveDay: addDay,

  receiveDays (state, days) {
    state.all = {
      ...state.all,
      ...keyBy(days, 'id')
    }
  },

  logout (state) {
    state.all = {}
  }
}

/* istanbul ignore next */
const actions: DayActionsTree = {
  setDayWithDayHomeworks ({ commit }, { data }) {
    data.dayHomeworks.forEach(homework => {
      commit('homework/addDayHomework', homework, { root: true })
    })
    data.homeworks.forEach(homework => {
      commit('homework/addHomework', homework, { root: true })
    })

    return commit('receiveDay', data.day)
  },
  fetchDay ({ dispatch }, { courseId, stageId, dayId }) {
    if (!courseId) {
      throw new InvalidParamError('courseId must be set')
    }
    if (!stageId) {
      throw new InvalidParamError('stageId must be set')
    }
    if (!dayId) {
      throw new InvalidParamError('dayId must be set')
    }

    return DayApi.fetch({ courseId, stageId, dayId })
      .then(({ data }) => {
        dispatch('setDayWithDayHomeworks', { data, courseId })
        return data.day
      })
  },

  async fetchDayCached ({ dispatch }, { courseId, stageId, dayId }) {
    await dispatch('course/fetchCourseCached', { id: courseId }, { root: true })

    // TODO: пофиксить логику кеша для дней
    // if (rootGetters['needUpdate/days'].includes(dayId)) {
    return dispatch('fetchDay', { courseId, stageId, dayId })
    // }
  },

  startDay ({ dispatch }, { streamId, stageId, dayId }) {
    if (!streamId) {
      throw new InvalidParamError('streamId must be set')
    }
    if (!stageId) {
      throw new InvalidParamError('stageId must be set')
    }
    if (!dayId) {
      throw new InvalidParamError('dayId must be set')
    }

    return DayApi.startDay({ streamId, dayId })
      .then(() => {
        return dispatch('stream/fetchStatuses', { id: streamId, expand: ['days'] }, { root: true })
      })
  },

  finishDay ({ dispatch }, { streamId, stageId, dayId }) {
    if (!streamId) {
      throw new InvalidParamError('streamId must be set')
    }
    if (!stageId) {
      throw new InvalidParamError('stageId must be set')
    }
    if (!dayId) {
      throw new InvalidParamError('dayId must be set')
    }

    return DayApi.finishDay({ streamId, dayId })
      .then(() => {
        return dispatch('stream/fetchStatuses', { id: streamId, expand: ['days'] }, { root: true })
      })
  },

  fetchProgress ({ commit }, { streamId, dayStatusId }) {
    return DayApi.getProgress({
      streamId,
      dayStatusId
    })
      .then(r => {
        commit('stream/receiveDayStatistic', {
          dayStatusId,
          status: r.data
        }, { root: true })

        return r.data
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
