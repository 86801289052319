import Mapper from './Mapper'

interface ResponseOrder {
  order: {
    status: string;
    // eslint-disable-next-line camelcase
    payout_url: string;
  };
}

/* istanbul ignore next */
export default class OrderMapper extends Mapper {
  static toClient (data): ResponseOrder {
    return Mapper.toClient(data) as ResponseOrder
  }
}
