/* istanbul ignore file */
import Logger from '@web/common/Logger'
import { FirebasePlugin } from 'cordova-plugin-firebasex'
import { Router } from 'vue-router'

export default class Firebase {
  static getInstance (): FirebasePlugin {
    return window.FirebasePlugin
  }

  static init (): void {
    if (!Firebase.getInstance()) {
      Logger.info('Firebase not init')
      return
    }
    Firebase.getInstance().setAnalyticsCollectionEnabled(true)
  }

  static send ({ action, ...options }: EventBase): void {
    if (!Firebase.getInstance()) {
      Logger.info('Firebase not init')
      return
    }
    if (action.replace(/(\w|\d|_)+/g, '')) {
      Logger.warn(`[FA] В названии события могут быть только буквы, числа и нижнее подчёркивание. action = ${action}`)
    }
    Firebase.getInstance().logEvent(action, options)
  }

  static setUserId (id: string): void {
    if (!Firebase.getInstance()) {
      Logger.info('Firebase not init')
      return
    }
    Firebase.getInstance().setUserId(id)
  }

  static setUserProperty (name: string, value: string): void {
    if (!Firebase.getInstance()) {
      Logger.info('Firebase not init')
      return
    }
    Firebase.getInstance().setUserProperty(name, value)
  }

  static setAppVersion (version: string): void {
    if (!Firebase.getInstance()) {
      Logger.info('Firebase not init')
      return
    }
    Firebase.setUserProperty('appVersion', version)
  }

  static pageView (router: Router): void {
    if (!Firebase.getInstance()) {
      Logger.info('Firebase not init')
      return
    }
    Firebase.getInstance().logEvent('screen_view', {
      screen_class: router.currentRoute.value.name,
      screen_name: router.currentRoute.value.path
    })
  }
}
