import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "asExternalDocument"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_modal = _resolveComponent("as-modal")!

  return (_ctx.doc.url)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["asExternalDocument_link", {
        '-icon': _ctx.icon
      }]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handlerClick && _ctx.handlerClick(...args)))
        }, _toDisplayString(_ctx.doc.title), 3),
        (_ctx.isModal)
          ? (_openBlock(), _createBlock(_component_as_modal, {
              key: 0,
              "model-value": _ctx.isShowModal,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModal = false)),
              "type-modal": "fullSize"
            }, {
              default: _withCtx(() => [
                _createElementVNode("iframe", {
                  src: _ctx.doc.url,
                  class: "asExternalDocument_iframe"
                }, null, 8, _hoisted_2)
              ]),
              _: 1
            }, 8, ["model-value"]))
          : _createCommentVNode("", true)
      ]))
    : (_ctx.doc.file)
      ? (_openBlock(), _createElementBlock("a", {
          key: _ctx.doc.id,
          class: _normalizeClass(["asDocuments_doc", `-${_ctx.doc.file.type}`]),
          href: _ctx.doc.file.url,
          target: "_blank"
        }, _toDisplayString(_ctx.doc.title), 11, _hoisted_3))
      : _createCommentVNode("", true)
}