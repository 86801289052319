import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-391aa6de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "asSupportAuth" }
const _hoisted_2 = { class: "asSupportAuth_text" }
const _hoisted_3 = { class: "asSupportAuth_modal" }
const _hoisted_4 = { class: "asSupportAuth_header" }
const _hoisted_5 = {
  key: 0,
  class: "asSupportAuth_notice"
}
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "asSupportAuth_tip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_input = _resolveComponent("as-input")!
  const _component_v_field = _resolveComponent("v-field")!
  const _component_as_textarea = _resolveComponent("as-textarea")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_as_modal = _resolveComponent("as-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "asSupportAuth_btn",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = true))
    }, _toDisplayString(_ctx.$t('writeToSupport')), 1),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('ifHaveAnyQuestions')), 1),
    _createVNode(_component_as_modal, {
      modelValue: _ctx.showModal,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showModal) = $event)),
      btnClose: true
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('weTypicallyReply')), 1),
          (_ctx.errorSend)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", null, _toDisplayString(_ctx.errorSend), 1)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_v_form, {
            class: "asSupportAuth_content",
            as: "div"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_field, {
                modelValue: _ctx.email,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
                modelModifiers: { trim: true },
                name: "email",
                label: _ctx.$t('email'),
                rules: "required|email"
              }, {
                default: _withCtx(({ errors }) => [
                  _createVNode(_component_as_input, {
                    name: "email",
                    label: _ctx.$t('email'),
                    placeholder: _ctx.$t('input.email'),
                    type: "email",
                    modelValue: _ctx.email,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
                    error: _ctx.getFirstError('email', errors),
                    class: "-grey asSupportAuth_input"
                  }, null, 8, ["label", "placeholder", "modelValue", "error"])
                ]),
                _: 1
              }, 8, ["modelValue", "label"]),
              _createVNode(_component_v_field, {
                modelValue: _ctx.username,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.username) = $event)),
                modelModifiers: { trim: true },
                name: "username",
                label: _ctx.$t('firstName'),
                rules: "required"
              }, {
                default: _withCtx(({ errors }) => [
                  _createVNode(_component_as_input, {
                    name: "username",
                    label: _ctx.$t('firstName'),
                    placeholder: _ctx.$t('input.firstName'),
                    type: "text",
                    modelValue: _ctx.username,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.username) = $event)),
                    class: "-grey asSupportAuth_input",
                    error: _ctx.getFirstError('name', errors)
                  }, null, 8, ["label", "placeholder", "modelValue", "error"])
                ]),
                _: 1
              }, 8, ["modelValue", "label"]),
              _createVNode(_component_v_field, {
                modelValue: _ctx.message,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.message) = $event)),
                modelModifiers: { trim: true },
                name: "message",
                label: _ctx.$t('message'),
                rules: "required"
              }, {
                default: _withCtx(({ errors }) => [
                  _createVNode(_component_as_textarea, {
                    name: "message",
                    modelValue: _ctx.message,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.message) = $event)),
                    modelModifiers: { trim: true },
                    class: "asSupportAuth_textarea -grey",
                    label: _ctx.$t('message'),
                    placeholder: _ctx.$t('input.message'),
                    maxlength: "500",
                    autofocus: "",
                    error: _ctx.getFirstError('city', errors)
                  }, null, 8, ["modelValue", "label", "placeholder", "error"])
                ]),
                _: 1
              }, 8, ["modelValue", "label"]),
              _createElementVNode("button", {
                class: _normalizeClass(["asSupportAuth_btn -modal", {
              '-disabled': _ctx.isDisabled
            }]),
                disabled: _ctx.isDisabled,
                onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args))),
                type: "submit"
              }, _toDisplayString(_ctx.$t('send')), 11, _hoisted_6),
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('answerToEmail')), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}