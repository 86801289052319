import getBaseUrl from '@web/common/getBaseUrl'
import { compare } from 'compare-versions'
import { APP_VERSION, APPLICATION_TYPE } from '@web/common/config'

import {
  AssetsState,
  AssetsGettersTree,
  AssetsMutationsTree,
  AssetsActionsTree,
  AssetsLogo,
  AssetsOneSignal,
  AuthCollection,
  Config,
  FrontendSetting
} from '@web/store/types/modules/assets'
import { BASE_URL } from '@web/api/utils'
import locale from '@web/common/locale'
import { socialAuth as getSocialAuth } from '@web/common/ExternalConfig/getters'
import PAGE_NAME from '@web/consts/PAGE_NAME'

const state: AssetsState = {
  domain: '',
  defaults: {
    logo: {
      src: '',
      alt: ''
    },
    contact: '/contact',
    faq: '/'
  },
  logo: {},
  contact: '',
  faq: '',
  theme: '',
  favicon: '',
  footer: [],
  gaTrakingId: '',
  yaTrackingId: process.env.VUE_APP_YANDEX_METRIKA_ID || '',
  title: '',
  techSupport: false,
  validateCache: process.env.VUE_APP_ENABLE_CACHE === '1',
  homepage: PAGE_NAME.HOME,
  isOneSignalEnable: false,
  oneSignal: {
    enabled: false,
    appId: null
  },
  requestUpdateMobileApp: false,
  supportEmail: '',
  whatsAppNumber: '',
  isPrettyPlayerEnable: false,
  authCollection: {
    enabled: false,
    clients: {}
  },
  country: '',
  supportedLanguage: locale.SUPPORTED_LANGUAGE,
  defaultLanguage: locale.getDefault(),
  notificationServiceURI: '',
  savedScrollPositions: {}
}

const getters: AssetsGettersTree = {
  domain: ({ domain }): string => domain,
  logo: ({ logo, defaults }): AssetsLogo => {
    return { ...defaults.logo, ...logo }
  },
  contact: ({ contact, defaults }): string => {
    return contact || defaults.contact
  },
  faq: ({ faq, defaults }): string => {
    return faq || defaults.faq
  },
  footer: ({ footer }) => {
    return footer
  },
  isOneSignalEnable: ({ isOneSignalEnable }): boolean => {
    return isOneSignalEnable
  },
  oneSignal: ({ oneSignal }): AssetsOneSignal => {
    return oneSignal
  },
  authCollection: ({ authCollection }, getters, rootState, rootGetters) => {
    const socialAuth = getSocialAuth()
    const supportedPlatform = Object.keys(socialAuth)
    if (supportedPlatform.length > 0 && authCollection.enabled) {
      const platform: 'ios' | 'android' = supportedPlatform.includes(APPLICATION_TYPE) ? APPLICATION_TYPE as 'ios' | 'android' : supportedPlatform[0] as 'ios' | 'android'
      return socialAuth[platform]
        .filter(name => authCollection.clients[name])
        .map(name => {
          const user = rootGetters['user/user']
          const linked = Boolean(user.userExternalAuths?.find(({ source }) => source === name))
          return {
            name,
            login: authCollection.clients[name].login,
            link: authCollection.clients[name].link,
            linked
          }
        })
    } else {
      return []
    }
  },
  projectTitle: ({ title }): string => {
    return title
  },
  supportEmail: ({ supportEmail }): string => {
    return supportEmail
  },
  whatsAppNumber: ({ whatsAppNumber }): string => {
    return whatsAppNumber
  },
  supportedLanguage: state => state.supportedLanguage,
  county: state => state.country,
  notificationServiceURI: state => state.notificationServiceURI,
  savedScrollPositions: state => state.savedScrollPositions
}

const mutations: AssetsMutationsTree = {
  domain (state, payload: string): void {
    state.domain = payload
  },
  saveField (state, { key, value }): void {
    state[key] = value
  },
  setIsOneSignalEnable (state, oneSignalEnable: boolean): void {
    state.isOneSignalEnable = oneSignalEnable
  },
  setOneSignal (state, { enabled = false, appId = null }): void {
    state.oneSignal = { enabled, appId }
  },
  setAuthCollection (state, { enabled = false, clients = {} }): void {
    state.authCollection = { enabled, clients }
  },
  setSupportEmail (state, supportEmail): void {
    state.supportEmail = supportEmail
  },
  setWhatsAppNumber (state, whatsAppNumber): void {
    state.whatsAppNumber = whatsAppNumber
  },
  saveScrollPositions (state, { name, position }) {
    state.savedScrollPositions[name] = position
  }
}

/* istanbul ignore next */
const actions: AssetsActionsTree = {
  setConfig ({ commit }, response: Config) {
    if (!response) return Promise.resolve()

    const settings: FrontendSetting[] = response.domain.frontendSettings || []
    const footerCol1: FrontendSetting = settings.find(item => item.key === 'spa_footer_1') || {} as FrontendSetting
    const footerCol2: FrontendSetting = settings.find(item => item.key === 'spa_footer_2') || {} as FrontendSetting
    const footerCol3: FrontendSetting = settings.find(item => item.key === 'spa_footer_3') || {} as FrontendSetting
    const footer = [footerCol1.value, footerCol2.value, footerCol3.value]
    commit('saveField', { key: 'footer', value: footer })

    const logoSrc: FrontendSetting = settings.find(item => item.key === 'spa_header_logo') || {} as FrontendSetting
    commit('saveField', {
      key: 'logo',
      value: {
        src: (getBaseUrl() || '') + (logoSrc.value || ''),
        alt: response.domain.name
      }
    })

    const theme: FrontendSetting = settings.find(item => item.key === 'spa_theme') || {} as FrontendSetting
    commit('saveField', {
      key: 'theme',
      value: theme.value || 'default'
    })

    const validateCache = settings.find(item => item.key === 'validate_cache')
    if (typeof validateCache !== 'undefined') {
      commit('saveField', {
        key: 'validateCache',
        value: !!validateCache
      })
    }

    commit('saveField', {
      key: 'gaTrakingId',
      value: response.domain.ga_id
    })

    if (response.domain.ya_id) {
      commit('saveField', {
        key: 'yaTrackingId',
        value: response.domain.ya_id
      })
    }

    commit('setSupportEmail', response?.domain?.supportEmail || `support@${response.domain.name}`)
    commit('setWhatsAppNumber', response?.domain?.whatsAppNumber || '')

    const lang = response.domain?.default_language?.code
    if (lang) {
      commit('saveField', {
        key: 'defaultLanguage',
        value: lang
      })
    }

    if (response.domain?.languages) {
      const langs: string[] = response.domain.languages.map(lang => lang.detector_code)
      commit('saveField', {
        key: 'supportedLanguage',
        value: langs
      })
    }

    if (response.domain?.title) {
      commit('saveField', {
        key: 'title',
        value: response.domain.title
      })
    }

    const project = response.project
    if (project) {
      commit('saveField', {
        key: 'mobileAppVersion',
        value: project.mobile_app_version
      })

      if (project.mobile_app_version) {
        commit('saveField', {
          key: 'requestUpdateMobileApp',
          value: compare(project.mobile_app_version, APP_VERSION, '>')
        })
      }
    }

    commit('saveField', {
      key: 'favicon',
      value: response.domain.favicon
    })

    const components = response.components
    if (components.jira) {
      commit('saveField', {
        key: 'techSupport',
        value: components.jira.enabled
      })
    }
    if (response.domain.onesignalComponentSetting) {
      commit('setOneSignal', {
        enabled: response.domain.onesignalComponentSetting.active === 1,
        appId: response.domain.onesignalComponentSetting.settings.applicationId
      })

      if (response.domain.onesignalComponentSetting.active === 1) {
        document.dispatchEvent(
          new CustomEvent(
            'oneSignalReady',
            {
              detail: {
                appId: response.domain.onesignalComponentSetting.settings.applicationId
              }
            })
        )
      }
    }
    if (components.authCollection) {
      commit('setAuthCollection', components.authCollection as AuthCollection)
    }

    if (response.country) {
      commit('saveField', {
        key: 'country',
        value: response.country
      })
    }

    if (components?.notificationService?.websocketURI) {
      commit('saveField', {
        key: 'notificationServiceURI',
        value: components.notificationService.websocketURI
      })
    }

    return Promise.resolve()
  },
  async fetchConfig ({ dispatch }) {
    return fetch(BASE_URL)
      .then(response => response.json())
      .then(response => dispatch('setConfig', response))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
