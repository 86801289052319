
import { defineComponent, ref } from 'vue'

import AsInput from '@web/components/AsInput/AsInput.vue'
import AsNotice from '@web/components/AsNotice.vue'
import AsModal from '@web/components/AsModal.vue'
import AsSupportModal from '@web/components/AsSupportNotAuth.vue'
import AsDivider from '@web/components/AsDivider.vue'
import AsTypesAuth from '@web/components/AsTypesAuth.vue'
import AsSupportAuth from '@web/components/AsSupportAuth.vue'

import i18n from '@web/plugins/i18n'

import useLoginForm from '@web/composition/useLoginForm'
import useErrors from '@web/composition/useErrors'

import { useRouter } from 'vue-router'
import { Form as VForm, Field as VField } from 'vee-validate'
import { useMeta } from '@web/common/vueMeta'
import { instructionUrl, isRegistrationEnable } from '@web/common/ExternalConfig/getters'

export default defineComponent({
  name: 'LoginForm',
  setup () {
    useMeta({
      title: i18n.global.t('Login')
    })
    const router = useRouter()

    const isShowSupportModal = ref(false)
    const isShowInstruction = ref(false)

    const {
      serverErrors,
      displayServerErrors,
      getFirstError
    } = useErrors()

    const {
      notice,
      ...loginForm
    } = useLoginForm({
      router,
      serverErrors,
      displayServerErrors,
      isLoadDataAfterSuccessLogin: false
    })

    function goTo (link) {
      router.push(link)
    }

    return {
      ...loginForm,
      isRegistrationEnable: isRegistrationEnable(),
      instructionUrl: instructionUrl(),
      isShowSupportModal,
      isShowInstruction,
      getFirstError,
      notice,
      goTo
    }
  },

  components: {
    AsModal,
    AsSupportModal,
    AsInput,
    AsNotice,
    AsDivider,
    AsTypesAuth,
    AsSupportAuth,
    VForm,
    VField
  }
})
