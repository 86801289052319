import ArticleApi from '@web/api/modules/Article'
import {
  ArticleActionsTree,
  ArticleGettersTree,
  ArticleMutationsTree,
  ArticleState
} from '@web/store/types/modules/article'
import { Article } from '@web/types/Article'

const state: ArticleState = {
  all: new Map(),
  previewLoaded: false
}

const getters: ArticleGettersTree = {
  articles: state => Array.from(state.all.values())
    .sort((a, b) => a.sort - b.sort),
  previewLoaded: ({ previewLoaded }) => previewLoaded
}

const mutations: ArticleMutationsTree = {
  receiveArticle (state, payload) {
    state.all.set(payload.id, payload)
  },

  toggleLike (state, { id, liked }) {
    const article = state.all.get(id)
    if (article) {
      article.liked = liked
      state.all.set(id, article)
    }
  },

  toggleFavourite (state, { id, isFavourite }) {
    const article = state.all.get(id)
    if (article) {
      article.isFavourite = isFavourite
      state.all.set(id, article)
    }
  },

  setPreviewLoaded (state, payload) {
    state.previewLoaded = payload
  },

  logout (state) {
    state.all.clear()
    state.previewLoaded = false
  }
}

const actions: ArticleActionsTree = {
  async fetchArticles ({ commit, rootGetters, dispatch }) {
    let communities = rootGetters['community/communities']
      .filter(c => c.userCommunity || c.type === 'recommended')
      .filter(community => !community.chatOnly)
      .map(c => c.uniq)

    await dispatch('community/fetchCachedCommunities', undefined, { root: true })

    communities = rootGetters['community/communities']
      .filter(c => c.userCommunity || c.type === 'recommended')
      .filter(community => !community.chatOnly)
      .map(c => c.uniq)
    if (!communities.length) {
      throw new Error('Communities is not fetched')
    }

    return ArticleApi
      .fetchAll({
        communityExternalIds: communities,
        width: 370,
        height: 210
      })
      .then((articles: Article[]) => {
        commit('setPreviewLoaded', true)
        for (const article of articles) {
          commit('receiveArticle', article)
        }
      })
  },

  async fetchCachedArticles ({ state, dispatch }) {
    if (state.previewLoaded) {
      return true
    }

    return dispatch('fetchArticles')
  },

  toggleLike ({ commit }, { id, liked }) {
    commit('toggleLike', { id, liked: !liked })
  },

  toggleFavourite ({ commit }, { id, isFavourite }) {
    commit('toggleFavourite', { id, isFavourite: !isFavourite })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
// eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
