import Mapper from '@web/mapper/Mapper'
import StageMapper from '@web/mapper/StageMapper'
import LessonMapper from '@web/mapper/LessonMapper'
import VideoMapper from '@web/mapper/VideoMapper'
import FileMapper from '@web/mapper/FileMapper'

/* istanbul ignore next */
export default class CourseMapper extends Mapper {
  static toClient (data): {
    course: Course;
    stages: Stage[];
    stageHomeworks: StageHomework[];
    days: Day[];
    dayHomeworks: DayHomework[];
    homeworks: Homework[];
    lessons: Lesson[];
    videos: FileVideo[];
  } {
    const stages: Stage[] = []
    const stageHomeworks: StageHomework[] = []
    const days: Day[] = []
    const dayHomeworks: DayHomework[] = []
    const lessons: Lesson[] = []
    const videos: FileVideo[] = []
    const homeworks: Homework[] = []

    const course: Course = Mapper.converter({
      data,
      rules: {
        title_short: {
          rename: 'caption'
        },
        mainImage: {
          rename: 'image',
          image: true
        },
        backgroundImage: {
          rename: 'background',
          image: true
        },

        description_short: {
          rename: 'excerpt',
          cb: text => {
            if (typeof text === 'string') {
              return text.replace(/&nbsp;/g, ' ')
            }
            return ''
          }
        },

        created_at: {
          cb: value => value * 1000
        },

        updated_at: {
          cb: value => value * 1000
        },
        trainingOptions: {
          rename: 'courseOptions',
          cb: courseOptions => courseOptions.map(option => Mapper.converter({
            data: option,
            rules: {

              training_option_values: {
                cb: values => values.map(Mapper.toClient),
                rename: 'trainingLessonOptionValues'
              }
            }
          }))
        },
        documents: {
          cb: docs => docs
            .filter(doc => doc.url || doc.file)
            .map(doc => {
              return {
                ...Mapper.toClient(doc) as Document,
                file: doc.file ? FileMapper.toClient(doc.file) : null
              }
            })
        },
        stages: {
          rename: 'stageIds',
          cb: data => data.map(stage => {
            const data = StageMapper.toClient(stage)
            stages.push(data.stage)
            stageHomeworks.push(...data.stageHomeworks)
            days.push(...data.days)
            dayHomeworks.push(...data.dayHomeworks)
            homeworks.push(...data.homeworks)
            return stage.id
          })
        },
        lessons: {
          rename: 'lessonIds',
          cb: data => data.map(lesson => {
            const data = LessonMapper.toClient(lesson)
            lessons.push(data.lesson)
            videos.push(...data.videos)
            return lesson.id
          })
        },

        demo_video_file_id: {
          rename: 'demoVideo',
          cb: VideoMapper.toClient
        }
      },
      defaults: {
        stageIds: [],
        offerIds: [],
        isDemoDocumentsAccessible: true,
        isDemoExpertMessageEnabled: true,
        isDemoUserMessageEnabled: true,
        isDemoOfflineModeAccessible: true
      }
    }) as Course

    return {
      course,
      stages,
      stageHomeworks,
      days,
      dayHomeworks,
      homeworks,
      lessons,
      videos
    }
  }
}
