import Mapper from './Mapper'
import { Category } from '@web/store/types/modules/faq'

/* istanbul ignore next */
export default class FaqMapper extends Mapper {
  static toClient (data): Category {
    return Mapper.converter({
      data,
      rules: {

        target_product: {
          cb: p => {
            switch (p) {
              case 1:
                return 'user_course'
              case 0:
              default:
                return 'none'
            }
          }
        },
        sort: {
          cb: s => Number(s)
        }
      }
    }) as Category
  }
}
