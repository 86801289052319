import ScheduleApi from '@web/api/modules/Schedule'
import {
  ScheduleActionsTree,
  ScheduleGettersTree,
  ScheduleMutationsTree,
  ScheduleState
} from '@web/store/types/modules/schedule'

const state: ScheduleState = {
  schedules: {},
  isLoaded: false
}

const getters: ScheduleGettersTree = {
  schedules: ({ schedules }) => schedules,
  getScheduleById: ({ schedules }) => (id: string) => schedules[id],
  isLoaded: ({ isLoaded }) => isLoaded
}

const mutations: ScheduleMutationsTree = {
  addSchedule (state, schedule) {
    state.schedules[schedule.id] = {
      ...state.schedules[schedule.id],
      ...schedule
    }
  },
  removeSchedule (state, id) {
    delete state.schedules[id]
  },
  setSchedules (state, { schedules }) {
    state.schedules = schedules
  },
  setLoadStatus (state, status: boolean) {
    state.isLoaded = status
  },
  logout (state) {
    state.schedules = {}
    state.isLoaded = false
  }
}

/* istanbul ignore next */
const actions: ScheduleActionsTree = {
  fetch ({ commit }) {
    return ScheduleApi.fetch()
      .then(({ data: schedules }) => {
        commit('setSchedules', { schedules })
        return schedules
      })
      .finally(() => {
        commit('setLoadStatus', true)
      })
  },
  fetchCached ({ dispatch, state }) {
    if (state.isLoaded) {
      return Promise.resolve(state.schedules)
    }
    return dispatch('fetch')
  },
  update ({ state, commit }) {
    return ScheduleApi.update(state.schedules)
      .then(({ data: schedules }) => {
        commit('setSchedules', { schedules })
        return schedules
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
