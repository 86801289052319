/* istanbul ignore file */
import client from '../client'
import DayMapper from '@web/mapper/DayMapper'
import HeaderMapper from '@web/mapper/HeaderMapper'
import StreamMapper from '@web/mapper/StreamMapper'

const options = {
  params: {
    expand: [
      'stage_day_homeworks',
      'stage_day_homeworks.homework.scores',
      'user_options',
      'lesson_sets',
      'scores'
    ].toString()
  }
}

export default class Day {
  static fetchAll ({ courseId, stageId }) {
    return client.get(`/courses/${courseId}/stages/${stageId}/days`, options)
      .then((response) => {
        return {
          data: response.data.map(DayMapper.toClient),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static fetch ({ courseId, stageId, dayId }) {
    return client.get(`/courses/${courseId}/stages/${stageId}/days/${dayId}`, options)
      .then((response) => {
        return {
          data: DayMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static startDay ({ streamId, dayId }) {
    return client.post(`/user-course-streams/${streamId}/user-day-status/${dayId}/start?expand=day.scores`)
      .then((response) => {
        return {
          data: DayMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static finishDay ({ streamId, dayId }) {
    return client.post(`/user-course-streams/${streamId}/user-day-status/${dayId}/finish?expand=day.scores`)
      .then((response) => {
        return {
          data: DayMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static sendStatistic (data) {
    return client.post('/save-lesson-stat', StreamMapper.statisticToServer(data))
      .then((response) => {
        return {
          data: StreamMapper.statisticToClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static getProgress ({ streamId, dayStatusId }: { streamId: number; dayStatusId: number }) {
    return client.get(`/user-course-streams/${streamId}/user-day-status/${dayStatusId}/progress`)
      .then((response) => {
        return {
          data: StreamMapper.statisticToClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static updateFilters ({ streamId, filters }) {
    return client.post(`/user-course-streams/${streamId}/filters`, {
      filters
    })
      .then((response) => {
        return {
          data: response.data,
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }
}
