
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    color: {
      type: String,
      default: () => ''
    }
  },
  setup (props) {
    const style = computed(() => {
      const _style: Dictionary<string> = {}
      if (props.color) {
        _style.color = props.color
      }
      return _style
    })

    return {
      style
    }
  }
})
