import { store } from '../store'
import Token from '../common/token'
import Analytics from '@web/services/Analytics/Analytics'
import Swal from 'sweetalert2'
import Logger from 'js-logger'
import { Router } from 'vue-router'
import i18n from '@web/plugins/i18n'
import { showWarningSubscription } from '@web/common/subscription'

export function requestEmailModal (): Promise<boolean> {
  return Swal.fire({
    title: i18n.global.t('requestEmailModal.title'),
    input: 'text',
    inputAttributes: {
      autocapitalize: 'off',
      placeholder: i18n.global.t('input.email')
    },
    confirmButtonText: i18n.global.t('Save'),
    cancelButtonText: i18n.global.t('Cancel'),
    showCancelButton: true,
    focusConfirm: false,
    allowOutsideClick: () => !Swal.isLoading(),
    preConfirm: async (email: string): Promise<boolean> => {
      if (!email) {
        Swal.showValidationMessage(i18n.global.t('error.pleaseEnterEmail'))
      }

      try {
        await store.dispatch('user/updateEmail', email)
        return true
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        let message = i18n.global.t('error.verifyEmail')
        if (error?.errors?.email && error.errors.email.length > 0) {
          message = error.errors.email.join(',')
        }
        Swal.showValidationMessage(message)
        Logger.error(error)
        return false
      }
    }
  })
    .then(result => result.isConfirmed)
}

export default (router: Router): void => {
  router.beforeEach(async (to, from, next) => {
    const user = store.getters['user/user']
    const isUserLogged = store.getters['user/isLogged']

    if (to.name === 'logout') {
      return next()
    }

    if (isUserLogged) {
      if (user.requiredUpdatePassword && to.name !== 'resetPassword') {
        return next({ name: 'resetPassword' })
      }
      if (
        !user.requiredUpdatePassword &&
        user.requiredUpdateProfile &&
        !['user-info-chat', 'updateProfile'].includes(to.name?.toString() || '')
      ) {
        return next({ name: 'updateProfile' })
      }

      // Проверка на подписку
      if (!['user-info-chat', 'updateProfile', 'resetPassword'].includes(to.name?.toString() || '') && to.name?.toString().indexOf('setting') === -1) {
        if (showWarningSubscription() && to.name !== 'subscriptions') {
          return next({ name: 'subscriptions', params: { warning: 'true' } })
        }
      }
    }

    const isRequiresAuth = to.matched.some(record => record.meta.requiresAuth)
    if (isRequiresAuth && !isUserLogged) {
      await store.dispatch('user/fetch')
    }
    if (isRequiresAuth && !Token.get()) {
      store.commit('setReturnUrl', to.fullPath)
      return next({ name: 'login' })
    }

    if (to.matched.some(record => record.meta.guest) && Token.get()) {
      return next({ name: store.state.assets.homepage })
    }

    if (to.matched.some(record => record.meta.requiresEmail)) {
      if (!user.id) {
        await store.dispatch('user/fetch')
      }
      if (!store.getters['user/email']) {
        if (await requestEmailModal()) {
          next()
        } else {
          return next(from)
        }
      }
    }

    next()
  })

  Analytics.pageView(router)

  router.afterEach(to => {
    Analytics.pageView(router)

    store.commit('router/push', to)
  })
}
