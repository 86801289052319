import flatten from 'lodash.flatten'
import uniqBy from 'lodash.uniqby'
import uniq from 'lodash.uniq'

import {
  VideoState,
  VideoGettersTree,
  VideoMutationsTree,
  VideoActionsTree
} from '@web/store/types/modules/video'
import keyBy from 'lodash.keyby'

const state: VideoState = {
  all: {}
}

const getters: VideoGettersTree = {
  videos: state => state.all,

  getVideoById: state => id => state.all[id],

  getVideosByCourseId: state => id => Object.values(state.all).filter(v => v.courseIds.includes(id)),

  getVideosByLessonId: state => id => Object.values(state.all).filter(v => v.lessonId === id),

  getVideosByLessonIds: state => ids => {
    const videos = Object.values(state.all).filter(v => ids.includes(v.lessonId))
    return uniqBy(flatten(videos), 'id')
  }
}

const mutations: VideoMutationsTree = {
  addVideo (state, video) {
    state.all[video.id] = {
      localUrl: state.all[video.id]?.localUrl || '',
      downloadStatus: state.all[video.id]?.downloadStatus || undefined,
      ...video
    }
  },

  addVideos (state: VideoState, videos: FileVideo[]) {
    state.all = {
      ...state.all,
      ...keyBy(videos, 'id')
    }
  },

  receiveVideo (state, video) {
    const v = state.all[video.id] || {}

    if (Array.isArray(v.courseIds) && Array.isArray(video.courseIds)) {
      video.courseIds = uniq([...v.courseIds, ...video.courseIds])
    } else {
      v.courseIds = []
    }

    state.all[video.id] = {
      localUrl: '',
      downloadStatus: undefined,
      ...v,
      ...video
    }
  },

  receiveDownloadStatus (state, { id, status }) {
    state.all[id] = {
      ...state.all[id],
      downloadStatus: status
    }
  },

  logout (state) {
    state.all = {}
  }
}

/* istanbul ignore next */
const actions: VideoActionsTree = {
  remove ({ commit, dispatch }, video) {
    commit('receiveVideo', {
      ...video,
      localUrl: '',
      downloadStatus: undefined
    })
    commit('file/removeFile', video.url, { root: true })
    commit('file/removeDownload', video.url, { root: true })
    return dispatch('file/remove', video.localUrl, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
