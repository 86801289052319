import Mapper from './Mapper'
import { User } from '@web/store/types/modules/user'
import { dayjs } from '@web/common/Time'

/* istanbul ignore next */
export default class UserMapper extends Mapper {
  static toClient (data): User {
    if (typeof data !== 'object') {
      throw new Error('[UserMapper] User data must be object')
    }

    return UserMapper.converter({
      data,
      rules: {
        name: {
          rename: 'firstName'
        },
        avatar: {
          baseUrl: true
        },
        // eslint-disable-next-line camelcase
        is_require_update_password: {
          rename: 'requiredUpdatePassword'
        },
        // eslint-disable-next-line camelcase
        is_require_update_profile: {
          rename: 'requiredUpdateProfile'
        }
      },
      defaults: {
        viewedHours: 0,
        completedCourses: 0,
        completedTasks: 0,
        viewedLessons: 0,
        referralOrders: 0,
        cashback: 0,
        discountForReferral: 0,
        linkJumps: 0,
        timezone: dayjs.tz.guess()
      }
    }) as User
  }

  static toServer (data) {
    const user = { ...data }

    if (data.id) {
      user.id = data.id
    }

    if (Object.prototype.hasOwnProperty.call(data, 'firstName')) {
      user.name = data.firstName
    }

    if (Object.prototype.hasOwnProperty.call(data, 'lastName')) {
      // eslint-disable-next-line camelcase
      user.last_name = data.lastName
    }

    if (Object.prototype.hasOwnProperty.call(data, 'city')) {
      user.city = data.city
    }

    if (Object.prototype.hasOwnProperty.call(data, 'gender')) {
      user.gender = data.gender
    }

    if (Object.prototype.hasOwnProperty.call(data, 'phone')) {
      user.phone = data.phone
    }

    if (Object.prototype.hasOwnProperty.call(data, 'birthday')) {
      user.birthday = data.birthday
    }

    if (Object.prototype.hasOwnProperty.call(data, 'instagramId')) {
      // eslint-disable-next-line camelcase
      user.instagram_id = data.instagramId
    }

    if (Object.prototype.hasOwnProperty.call(data, 'disableNotification')) {
      // eslint-disable-next-line camelcase
      user.disable_notification = data.disableNotification ? 1 : 0
    }

    if (Object.prototype.hasOwnProperty.call(data, 'currentPassword')) {
      // eslint-disable-next-line camelcase
      user.current_password = data.currentPassword
    }

    if (Object.prototype.hasOwnProperty.call(data, 'passwordRepeat')) {
      // eslint-disable-next-line camelcase
      user.password_repeat = data.passwordRepeat
    }

    if (Object.prototype.hasOwnProperty.call(data, 'locale')) {
      const langs = {
        ru: 'ru-RU',
        en: 'en-US',
        es: 'es-ES'
      }
      // eslint-disable-next-line camelcase
      user.language_code = langs[data.locale] || user.languageCode || ''
      delete user.locale
    }

    return user
  }
}
