
import { defineComponent } from 'vue'
import { IMaskDirective } from 'vue-imask'
import useInput, { props, emits } from '@web/composition/useInput'

export default defineComponent({
  name: 'AsInput',
  directives: {
    imask: IMaskDirective
  },
  props,
  emits,
  setup (props, ctx) {
    return {
      ...useInput(props, ctx.emit)
    }
  }
})
