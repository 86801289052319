import ConsultationStatusConst from '@web/consts/ConsultationStatus'
import ConsultationApi from '@web/api/modules/Consultation'

import {
  ConsultationState,
  ConsultationGettersTree,
  ConsultationMutationsTree,
  ConsultationActionsTree
} from '@web/store/types/modules/consultation'

const state: ConsultationState = {
  all: {},
  statuses: {}
}

const getters: ConsultationGettersTree = {
  statuses: ({ statuses }) => statuses,
  getConsultationById: ({ all }) => id => all[id],
  consultations: ({ statuses }, { getConsultationById }) => {
    const consultations = {}

    for (const id in statuses) {
      if (!Object.prototype.hasOwnProperty.call(statuses, id)) continue
      const status = statuses[id]
      const consultation = getConsultationById(status.id)
      consultations[id] = {
        ...status,
        ...consultation,
        id
      }
    }

    return consultations
  },
  consultationsByChatId: ({ statuses }, { getConsultationById }) => chatId => {
    const consultations = {}

    for (const id in statuses) {
      if (!Object.prototype.hasOwnProperty.call(statuses, id)) continue
      const status = statuses[id]
      if (status.chatChannelId !== chatId) continue
      const consultation = getConsultationById(status.id)
      consultations[id] = {
        ...status,
        ...consultation,
        id
      }
    }

    return consultations
  },
  consultationByChatIdAndMessageId: ({ statuses }) => (chatId: number, messageId: number) => {
    const consultationsByChatId = Object.values(statuses).filter(status => status.chatChannelId === chatId)

    return consultationsByChatId.find(({ status, startMessageId, endMessageId }) => {
      return ((status === ConsultationStatusConst.CLOSE || status === ConsultationStatusConst.RATED) && (startMessageId <= messageId && messageId <= endMessageId)) ||
        (status === ConsultationStatusConst.OPEN && startMessageId <= messageId)
    })
  },
  getConsultationsCountByChatId: ({ statuses }) => id => {
    const consultations = Object.values(statuses)
      .filter(({ chatChannelId, status }) => [ConsultationStatusConst.OPEN, ConsultationStatusConst.CLOSE, ConsultationStatusConst.RATED].includes(status) && chatChannelId === id)
    return consultations.reduce((count, item) => count + item.amount, 0)
  }
}

const mutations: ConsultationMutationsTree = {
  receiveConsultation (state, consultation) {
    state.statuses[consultation.id] = consultation
  },

  receiveStatuses (state, { statuses }) {
    const result = {}
    statuses.forEach(status => {
      result[status.id] = status
    })

    state.statuses = {
      ...state.statuses,
      ...result
    }
  },
  clearStatuses (state) {
    state.statuses = {}
  },
  logout (state) {
    state.all = {}
    state.statuses = {}
  }
}

/* istanbul ignore next */
const actions: ConsultationActionsTree = {
  setConsultation ({ commit }, consultation) {
    commit('receiveConsultation', consultation)
  },
  updateRatingConsultation ({ commit }, consultation) {
    return ConsultationApi.updateRatingConsultation({ consultation })
      .then(({ data: consultation }) => {
        commit('receiveConsultation', consultation)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
