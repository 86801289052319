import Mapper from './Mapper'

/* istanbul ignore next */
export default class HomeworkStatusMapper extends Mapper {
  static toClient (data): HomeworkStatus {
    return Mapper.converter({
      data,
      rules: {

        user_course_stream_id: {
          rename: 'streamId'
        }
      },
      defaults: {
        isLocked: false
      }
    }) as HomeworkStatus
  }
}
