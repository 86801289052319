import Mapper from './Mapper'
import { Offer } from '@web/store/types/modules/offer'
import { Service } from '@web/store/types/modules/service'
import ServiceMapper from '@web/mapper/ServiceMapper'

export default class OfferMapper extends Mapper {
  static toClient (data): {
    offer: Offer;
    service: Service | undefined;
  } {
    let service: Service | undefined

    const offer: Offer = Mapper.converter({
      data,
      rules: {

        course_service: {
          rename: 'serviceId',
          cb: value => {
            const data = ServiceMapper.toClient(value)
            service = data.service
            return value.id
          }
        },

        images_files: {
          rename: 'images',
          cb: items => items.map(item => {
            const img = Mapper.converter({
              data: item,
              rules: {
                urls: {
                  baseUrls: true
                }
              }
            }) as File
            return img.urls
          })
        }
      }
    }) as Offer

    return {
      offer,
      service
    }
  }
}
