import Mapper from './Mapper'
import CourseMapper from './CourseMapper'
import { Stream, DayStatistic, UserCourseStreamFreeze } from '@web/store/types/modules/stream'
import { Service } from '@web/store/types/modules/service'
import ServiceMapper from '@web/mapper/ServiceMapper'

export enum FreezeStatus {
  created = 'Created',
  active = 'Active',
  canceled = 'Canceled'
}

function getFreezeStatus (placement: number | string): string {
  if (typeof placement === 'string') {
    return placement
  }

  switch (placement) {
    case 0:
      return FreezeStatus.created
    case 1:
      return FreezeStatus.active
    case 2:
      return FreezeStatus.canceled
    default:
      return FreezeStatus.created
  }
}

/* istanbul ignore next */
export default class StreamMapper extends Mapper {
  static userCourseStreamFreezeToClient (data): UserCourseStreamFreeze {
    return Mapper.converter({
      data,
      rules: {
        status: {
          cb: status => getFreezeStatus(status)
        }
      }
    }) as UserCourseStreamFreeze
  }

  static toClient (data): {
    stream: Stream;
    course: Course | undefined;
    stages: Stage[];
    stageHomeworks: StageHomework[];
    days: Day[];
    dayHomeworks: DayHomework[];
    lessons: Lesson[];
    videos: FileVideo[];
    homeworks: Homework[];
    service: Service | undefined;
  } {
    let course: Course | undefined
    const stages: Stage[] = []
    const stageHomeworks: StageHomework[] = []
    const days: Day[] = []
    const dayHomeworks: DayHomework[] = []
    const lessons: Lesson[] = []
    const videos: FileVideo[] = []
    const homeworks: Homework[] = []
    let service: Service | undefined

    const stream: Stream = Mapper.converter({
      data,
      rules: {
        // eslint-disable-next-line camelcase
        progress_status: {
          rename: 'progress'
        },
        // eslint-disable-next-line camelcase
        start_date: {
          cb: value => value * 1000
        },
        // eslint-disable-next-line camelcase
        end_date: {
          cb: value => value * 1000
        },
        // eslint-disable-next-line camelcase
        message_expires_date: {
          cb: value => value * 1000
        },
        // eslint-disable-next-line camelcase
        course_id: {
          cb: parseFloat
        },
        // eslint-disable-next-line camelcase
        chat_status: {
          cb: Mapper.toClient
        },
        course: {
          cb: value => {
            const data = CourseMapper.toClient(value)
            course = data.course
            stages.push(...data.stages)
            stageHomeworks.push(...data.stageHomeworks)
            days.push(...data.days)
            dayHomeworks.push(...data.dayHomeworks)
            lessons.push(...data.lessons)
            videos.push(...data.videos)
            homeworks.push(...data.homeworks)
          }
        },
        progress: {
          cb: value => Mapper.converter({
            data: value,
            rules: {
              progress: {
                cb: progress => Math.min(progress, 100)
              }
            }
          })
        },
        order: {
          cb: value => {
            // eslint-disable-next-line camelcase
            if (value.course_service) {
              // eslint-disable-next-line camelcase
              service = ServiceMapper.toClient(value.course_service).service
            }
          }
        },
        // eslint-disable-next-line camelcase
        user_course_stream_freeze: {
          cb: value => StreamMapper.userCourseStreamFreezeToClient(value)
        },
        userCourseStreamFreeze: {
          cb: value => StreamMapper.userCourseStreamFreezeToClient(value)
        }
      },
      defaults: {
        progress: {
          daysCount: 0,
          completeDaysCount: 0,
          progress: 0
        }
      }
    }) as Stream

    // Если в услуге нет appstoreProductId значит нельзя купить на iOS
    if (service && service.appstoreProductId) {
      stream.orderServiceId = service.id
    }

    return {
      stream,
      course,
      stages,
      stageHomeworks,
      days,
      dayHomeworks,
      lessons,
      videos,
      homeworks,
      service
    }
  }

  static userOptions (data) {
    const options = {}
    data.forEach(item => {
      options[item.option_id] = item.value_id
    })
    return options
  }

  static dayStatus (data) {
    return Mapper.converter({ data })
  }

  static statisticToServer (data) {
    return Mapper.converter({
      data,
      rules: {
        dayId: {
          rename: 'stage_day_id'
        },
        streamId: {
          rename: 'user_course_stream_id'
        },
        lessonId: {
          rename: 'stage_day_lesson_set_lesson_id'
        },
        viewedSeconds: {
          rename: 'viewed_seconds',
          cb: value => value / 1000
        },
        playCount: {
          rename: 'play_count'
        },
        dayStatus: {
          rename: 'day_status'
        }
      }
    }) as {
      // eslint-disable-next-line camelcase
      stage_day_id: number;
      // eslint-disable-next-line camelcase
      user_course_stream_id: number;
      // eslint-disable-next-line camelcase
      stage_day_lesson_set_lesson_id: number;
      // eslint-disable-next-line camelcase
      viewed_seconds: number;
      // eslint-disable-next-line camelcase
      play_count: number;
      // eslint-disable-next-line camelcase
      day_status_id: number;
      // eslint-disable-next-line camelcase
      day_status: string;
    }
  }

  static statisticToClient (data) {
    return Mapper.converter({
      data,
      rules: {

        viewed_seconds: {
          cb: value => value * 1000
        }
      }
    }) as DayStatistic
  }
}
