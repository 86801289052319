/* istanbul ignore file */
import client from '../client'
import HeaderMapper from '@web/mapper/HeaderMapper'
import { UserScore as IUserScore } from '@web/store/types/modules/userScore'
import UserScoreMapper from '@web/mapper/UserScoreMapper'

export default class UserScore {
  /**
   * Получение баллов пользователя
   *
   * @returns {*}
   */
  static amount (): Promise<{
    data: number;
    headers: Headers;
  }> {
    return client.get('/user-score/amount')
      .then((response) => {
        return {
          data: response.data,
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  /**
   * Получение истории баллов пользователя
   *
   * @param payload
   * @returns {*}
   */
  static history (payload: { page?: number }): Promise<{
    data: IUserScore[];
    headers: Headers;
  }> {
    const options: { params?: Record<string, unknown> } = {}
    options.params = {
      page: String(payload?.page || 1)
    }
    return client.get('/user-score', options)
      .then((response) => {
        return {
          data: response.data.map(UserScoreMapper.toClient),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }
}
