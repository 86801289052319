
import { defineComponent, computed } from 'vue'

import i18n from '@web/plugins/i18n'
import { getNumberDecliner } from '@web/common/Utils'

export default defineComponent({
  name: 'PointsLabel',
  props: {
    points: {
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const pointsText = computed(() => {
      const text = getNumberDecliner({
        n: props.points,
        single: i18n.global.t('pointsDecliner.point'),
        multiple: i18n.global.t('pointsDecliner.points'),
        multiple2: i18n.global.t('pointsDecliner.points2')
      })

      return `${props.points} ${text}`
    })

    return {
      pointsText
    }
  }
})
