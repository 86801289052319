
import { computed, defineComponent, onMounted } from 'vue'

// Components
import AsPreloader from '@web/components/AsPreloader.vue'
import AcCookie from '@ui-kit/components/AcCookie/index.vue'
import MobileSubscription from '@web/components.v2/MobileSubscription.vue'

// Mixins
import useApp from '@web/composition/useApp'
import { useStore } from '@web/store'

// Plugins
import Logger from '@web/common/Logger'
import sentry from '@web/common/sentry'
import FirebasePerformance from '@web/services/firebasePerformance'

import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    MobileSubscription,
    AsPreloader,
    AcCookie
  },
  setup () {
    const store = useStore()
    const router = useRouter()

    const {
      loading,
      logoutHandler,
      offline,
      online,
      title,
      initHandler
    } = useApp({ router })

    const isLoaded = computed(() => !loading.value)
    const isMobileSubscription = computed(() => store.getters['subscription/isMobileSubscription'])

    async function init () {
      let appLoading = performance.now()
      const startLoading = Date.now() - 1
      store.commit('assets/domain', window.location.host)

      try {
        await initHandler()
      } catch (e) {
        sentry.captureException(e)
        Logger.error(e)
      } finally {
        appLoading = performance.now() - appLoading
        Logger.info(`App loaded: ${appLoading} ms`)
        loading.value = false
        FirebasePerformance.record({
          traceName: 'load_app',
          startTime: startLoading,
          duration: (Date.now() - startLoading > 0 ? Date.now() : Math.round(appLoading)) || 1
        })
      }

      logoutHandler()

      document.addEventListener('offline', offline, false)
      document.addEventListener('online', online, false)
    }

    onMounted(() => {
      init()
    })

    return {
      title,
      isLoaded,
      isMobileSubscription
    }
  }
})
