/* istanbul ignore file */
import client from '../client'
import ConsultationMapper from '@web/mapper/ConsultationMapper'
import HeaderMapper from '@web/mapper/HeaderMapper'

export default class Consultation {
  static updateRatingConsultation ({ consultation }) {
    return client.put(`/consultations/updateRating/${consultation.id}`, consultation)
      .then((response) => {
        return {
          data: ConsultationMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }
}
