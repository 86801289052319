import Mapper from './Mapper'
import VideoMapper from './VideoMapper'
import Logger from '@web/common/Logger'

/* istanbul ignore next */
export default class LessonMapper {
  static toClient (data): {
    lesson: Lesson;
    videos: FileVideo[];
  } {
    const lesson: Lesson = Mapper.converter({
      data,
      rules: {

        video_length: {
          cb: value => value * 1000,
          rename: 'delay'
        },

        training_options: {
          cb: options => options.map(option => {
            return {
              ...Mapper.toClient(option) as TrainingOption,
              value: Mapper.toClient(option.value)
            }
          })
        }
      }
    }) as Lesson

    const regExp = /\((разбор|исполнение|теория)\)/i
    const subtitle = text => {
      const matched = text.match(regExp)
      return Array.isArray(matched) ? matched[matched.length - 1] : ''
    }

    const title = lesson.title || ''
    lesson.title = title.replace(regExp, '')
    lesson.subtitle = subtitle(lesson.title)

    const videos: FileVideo[] = []
    const videoKeys = ['localVideoFile', 'localDescriptionVideoFile', 'verticalVideo', 'verticalDescriptionVideo']
    videoKeys.forEach(key => {
      if (lesson[key]) {
        if (lesson[key].type === 'video') {
          const video = VideoMapper.toClient(lesson[key])
          videos.push(video)
          lesson[key] = video.id
        } else {
          Logger.warn(`В уроке "${lesson.title} (${lesson.id})" у ${key} не верный тип. Тип ${lesson[key].type} ожидается video`)
        }
      }
    })

    return {
      lesson,
      videos
    }
  }
}
