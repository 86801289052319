/* istanbul ignore file */
export default class NetworkError extends Error {
  public response: unknown
  public status: unknown

  constructor (response: Response, ...params: string[]) {
    super(...params)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NetworkError)
    }

    this.response = response
    this.status = response.status
    this.message = `Request ${response.url} failed with status ${response.status}`
  }
}
