import ChatApi from '@web/api/modules/Chat'
import InvalidParamError from '@web/common/errors/InvalidParamError'
import {
  ChatState,
  ChatGettersTree,
  ChatMutationsTree,
  ChatActionsTree
} from '@web/store/types/modules/chat'
import MessageMapper from '@web/mapper/MessageMapper'
import i18n from '@web/plugins/i18n'
import StreamStatus from '@web/consts/StreamStatus'
import { Stream } from '@web/store/types/modules/stream'

const state: ChatState = {
  all: {},
  isLoadedAll: false,
  paginationParam: {
    currentPage: 0,
    pageCount: 0,
    perPage: 10,
    totalCount: 0
  }
}

const getters: ChatGettersTree = {
  chats (state, getters, rootState, rootGetters) {
    const chats = Object.values(state.all)
    return chats.map(chat => {
      const title = chat.title || ''
      const stream = rootGetters['stream/getStreamById'](chat.streamId)

      const messages = chat.messages || []
      const lastMessage = messages.length > 0 ? messages[chat.messages.length - 1] : undefined

      return {
        ...chat,
        author: `${i18n.global.t('experts')} ${title}`,
        text: lastMessage?.message || '',
        isViewed: true,
        time: lastMessage?.updatedAt || Date.now(),
        messages: chat.messages,
        isLocked: Boolean(stream?.isDemo),
        streamStatus: (stream?.status as typeof chat.streamStatus) || chat.streamStatus
      }
    })
  },
  availableChats (state, getters, rootState, rootGetters) {
    // если stream неизвестен то скорее всего это чат демо-потока
    return getters.chats
      .filter(chat => {
        return chat.isExpertMessageEnable && rootGetters['stream/getStreamById'](chat.streamId)
      })
      .sort((a, b) => {
        const streamPrev: Stream | undefined = rootGetters['stream/getStreamById'](a.streamId)
        const streamNext: Stream | undefined = rootGetters['stream/getStreamById'](b.streamId)
        const order = [StreamStatus.OPEN, StreamStatus.NOT_STARTED, StreamStatus.FREEZE, StreamStatus.STOPPED, StreamStatus.ENDED]
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return order.indexOf(streamPrev!.status) - order.indexOf(streamNext!.status)
      })
  },
  getChatById: (state, getters) => id => {
    return getters.chats.find(chat => chat.id === id)
  },
  getPaginationParam (state) {
    return state.paginationParam
  }
}

const addChat = (state, { chat }) => {
  state.all = {
    ...state.all,
    [chat.id]: {
      messages: [],
      ...state.all[chat.id],
      ...chat
    }
  }
}

const addMessage = (state, { id, message }) => {
  const chat = state.all[id] || {}
  let messages = Array.isArray(chat.messages) ? chat.messages : []
  if (messages.find(m => m.id === message.id)) {
    messages = messages.map(m => {
      return m.id === message.id ? { ...m, ...message } : m
    })
  } else {
    messages.push(message)
  }

  if (!state.all[id]) {
    state.all[id] = {}
  }
  state.all[id].messages = messages
}

const mutations: ChatMutationsTree = {
  receiveChats: (state, { chats }) => {
    chats.map(chat => addChat(state, { chat }))
  },

  receiveChat: addChat,

  receiveMessages: (state, { id, messages }) => {
    messages
      .map(message => addMessage(state, { id, message }))
  },

  receiveMessage: addMessage,

  setPaginationParam (state, paginationParam) {
    state.paginationParam = {
      ...state.paginationParam,
      ...paginationParam
    }
  },

  setStatusLoadAll (state, status) {
    state.isLoadedAll = status
  },

  removeDeletedChats (state, ids) {
    const copyStateAll = state.all
    for (const id in copyStateAll) {
      if (!Object.prototype.hasOwnProperty.call(copyStateAll, id)) continue

      if (!ids.includes(Number(id))) {
        delete copyStateAll[id]
      }
    }
    state.all = copyStateAll
  },

  logout (state) {
    state.all = {}
  }
}

/* istanbul ignore next */
const fetchChat = ({ commit, chat }) => {
  commit('receiveChat', { chat })
}

/* istanbul ignore next */
const actions: ChatActionsTree = {
  fetchChats ({ commit }) {
    return ChatApi.fetchAll()
      .then(({ data: chats }) => {
        const chatIds: number[] = []
        chats.forEach(chat => {
          chatIds.push(chat.id)
          fetchChat({ commit, chat })
        })
        commit('setStatusLoadAll', true)

        commit('removeDeletedChats', chatIds)
        return chats
      })
  },

  fetchChatsCached ({ state, dispatch }) {
    if (!state.isLoadedAll) {
      return dispatch('fetchChats')
    }
  },

  fetchChat ({ commit }, { id }) {
    if (!id) {
      throw new InvalidParamError('Id must be set')
    }
    return ChatApi.fetch({ id })
      .then(({ data: chat }) => fetchChat({ commit, chat }))
  },

  fetchNextMessages ({ getters, dispatch }, { id, sort }) {
    if (!id) {
      throw new InvalidParamError('Id must be set')
    }
    const paginationParam = getters.getPaginationParam
    const nextPage = paginationParam.currentPage + 1
    if (paginationParam.pageCount >= nextPage) {
      return dispatch('fetchMessages', { id, sort: sort || '-created_at', page: nextPage })
    }
    return Promise.resolve([])
  },
  fetchMessages ({ commit }, { id, sort = '-created_at', page = 1, perPage = 10 }) {
    if (!id) {
      throw new InvalidParamError('Id must be set')
    }
    return ChatApi.fetchMessages({ id, sort, page, perPage })
      .then(({ data: messages, headers }) => {
        commit('setPaginationParam', {
          currentPage: parseInt(headers.get('x-pagination-current-page'), 10),
          pageCount: parseInt(headers.get('x-pagination-page-count'), 10),
          perPage: parseInt(headers.get('x-pagination-per-page'), 10),
          totalCount: parseInt(headers.get('x-pagination-total-count'), 10)
        })
        commit('receiveMessages', { id, messages })
        return messages
      })
  },

  sendMessage ({ commit }, { id, message }) {
    if (!id) {
      throw new InvalidParamError('Id must be set')
    }
    return ChatApi.sendMessage({ id, message: MessageMapper.toServer(message) })
      .then(({ data: message }) => {
        commit('receiveMessage', { id, message })
      })
  },

  sendHomeworkMessage ({ commit, dispatch }, { id, streamId, userHomeworkStatusId, message }) {
    if (!id) {
      throw new InvalidParamError('Id must be set')
    }
    if (!streamId) {
      throw new InvalidParamError('StreamId must be set')
    }
    if (!userHomeworkStatusId) {
      throw new InvalidParamError('UserHomeworkStatusId must be set')
    }
    return ChatApi.sendHomeworkMessage({ streamId, userHomeworkStatusId, message })
      .then(({ data }) => {
        commit('receiveMessage', { id, message: data.message })
        commit('homework/setHomeworkStatus', data.homeworkStatus, { root: true })
        return dispatch('stream/fetchStatuses', { id: streamId, expand: 'stages' }, { root: true })
      })
  },

  updateMessage ({ commit }, { id, message }) {
    if (!id) {
      throw new InvalidParamError('Id must be set')
    }
    commit('receiveMessage', { id, message })
    return ChatApi.updateMessage({ id, message })
      .then(({ data: message }) => {
        commit('receiveMessage', { id, message })
      })
  },

  updateRatingMessage ({ commit }, { id, message }) {
    if (!id) {
      throw new InvalidParamError('Id must be set')
    }
    return ChatApi.updateRatingMessage({ id, message })
      .then(({ data: message }) => {
        commit('receiveMessage', { id, message })
      })
  },

  updateViewedMessage (context, { id, message }) {
    if (!id) {
      throw new InvalidParamError('Id must be set')
    }
    return ChatApi.updateViewedMessage({
      id,
      message: {
        id: message.id,
        isViewed: true
      }
    })
      .then(({ data }) => data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
// eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
