import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-87e72218"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lAuth -login" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "lAuth_title" }
const _hoisted_4 = ["data-text", "disabled", "onClick"]
const _hoisted_5 = {
  key: 0,
  class: "lAuth_loader"
}
const _hoisted_6 = ["data-text", "data-analytics-element-name"]
const _hoisted_7 = { class: "lAuth_linkGroup" }
const _hoisted_8 = ["data-analytics-element-name"]
const _hoisted_9 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_notice = _resolveComponent("as-notice")!
  const _component_as_input = _resolveComponent("as-input")!
  const _component_v_field = _resolveComponent("v-field")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_as_divider = _resolveComponent("as-divider")!
  const _component_as_types_auth = _resolveComponent("as-types-auth")!
  const _component_as_modal = _resolveComponent("as-modal")!
  const _component_as_support_auth = _resolveComponent("as-support-auth")!
  const _component_as_support_modal = _resolveComponent("as-support-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.logo.src,
      alt: _ctx.logo.alt,
      class: "lAuth_logo"
    }, null, 8, _hoisted_2),
    _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('signIn')), 1),
    _createVNode(_component_as_notice, { notice: _ctx.notice }, null, 8, ["notice"]),
    _createVNode(_component_v_form, {
      as: "div",
      class: "lAuth_form"
    }, {
      default: _withCtx(({ errors }) => [
        _createVNode(_component_v_field, {
          name: "email",
          label: _ctx.$t('email'),
          modelValue: _ctx.username,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.username) = $event)),
          modelModifiers: { trim: true },
          rules: "required|email"
        }, {
          default: _withCtx(({ errors: fErrors }) => [
            _createVNode(_component_as_input, {
              name: "email",
              label: _ctx.$t('email'),
              type: "email",
              modelValue: _ctx.username,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
              modelModifiers: { trim: true },
              placeholder: _ctx.$t('input.email'),
              error: _ctx.getFirstError('email', fErrors),
              onKeyup: _withKeys(($event: any) => (_ctx.submit(errors)), ["enter"])
            }, null, 8, ["label", "modelValue", "placeholder", "error", "onKeyup"])
          ]),
          _: 2
        }, 1032, ["label", "modelValue"]),
        _createVNode(_component_v_field, {
          name: "password",
          label: _ctx.$t('password'),
          modelValue: _ctx.password,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.password) = $event)),
          modelModifiers: { trim: true },
          rules: "required"
        }, {
          default: _withCtx(({ errors: fErrors }) => [
            _createVNode(_component_as_input, {
              name: "password",
              label: _ctx.$t('password'),
              modelValue: _ctx.password,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.password) = $event)),
              modelModifiers: { trim: true },
              "append-icon": _ctx.showPassword ? 'visibility' : 'visibilityOff',
              type: _ctx.showPassword ? 'text' : 'password',
              "onClick:append": _cache[3] || (_cache[3] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword)),
              placeholder: _ctx.$t('input.password'),
              error: _ctx.getFirstError('password', fErrors),
              onKeyup: _withKeys(($event: any) => (_ctx.submit(errors)), ["enter"])
            }, null, 8, ["label", "modelValue", "append-icon", "type", "placeholder", "error", "onKeyup"])
          ]),
          _: 2
        }, 1032, ["label", "modelValue"]),
        _createElementVNode("button", {
          class: _normalizeClass(["lAuth_button login_button", {
          '-disabled': _ctx.isLoginBtnDisabled
        }]),
          "data-text": _ctx.$t('signIn'),
          "data-cy": "login_btn",
          disabled: _ctx.isLoginBtnDisabled,
          onClick: ($event: any) => (_ctx.submit(errors)),
          type: "submit"
        }, [
          _createTextVNode(_toDisplayString(_ctx.$t('signIn')) + " ", 1),
          (!_ctx.save)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5))
            : _createCommentVNode("", true)
        ], 10, _hoisted_4),
        (_ctx.showRestorePasswordButton)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              "data-text": _ctx.$t('restorePassword'),
              "data-analytics": "",
              "data-analytics-element-type": "other",
              "data-analytics-element-id": "restore_password",
              "data-analytics-element-name": _ctx.$t('restorePassword'),
              class: "lAuth_button login_button",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.goTo({ name: 'accessRecovery' })))
            }, _toDisplayString(_ctx.$t('restorePassword')), 9, _hoisted_6))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", {
        class: "lAuth_help",
        "data-analytics": "",
        "data-analytics-element-type": "other",
        "data-analytics-element-id": "restore_password",
        "data-analytics-element-name": _ctx.$t('forgotPassword'),
        "data-cy": "restore_password_btn",
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.goTo({ name: 'accessRecovery' })))
      }, _toDisplayString(_ctx.$t('forgotPassword')), 9, _hoisted_8),
      (_ctx.clients.length)
        ? (_openBlock(), _createBlock(_component_as_divider, {
            key: 0,
            class: "lAuth_divider"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('partsSpeech.or')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_as_types_auth, {
        class: "lAuth_typesAuth",
        "short-btn": "",
        action: "login"
      }),
      (_ctx.clients.length)
        ? (_openBlock(), _createBlock(_component_as_divider, {
            key: 1,
            class: "lAuth_divider"
          }))
        : _createCommentVNode("", true),
      (_ctx.isRegistrationEnable)
        ? (_openBlock(), _createElementBlock("a", {
            key: 2,
            class: "lAuth_register lAuth_link",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.goTo({ name: 'signup' })))
          }, _toDisplayString(_ctx.$t('register')), 1))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.isRegistrationEnable && _ctx.instructionUrl)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.isShowInstruction = true)),
          class: "lAuth_register lAuth_link"
        }, [
          _createTextVNode(_toDisplayString(_ctx.$t('userInstruction')) + " ", 1),
          _createVNode(_component_as_modal, {
            modelValue: _ctx.isShowInstruction,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.isShowInstruction) = $event)),
            "type-modal": "fullSize"
          }, {
            default: _withCtx(() => [
              _createElementVNode("iframe", {
                src: _ctx.instructionUrl,
                style: {"width":"100%","height":"100%"}
              }, null, 8, _hoisted_9)
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_as_support_auth),
    _createVNode(_component_as_modal, {
      btnClose: true,
      modelValue: _ctx.isShowSupportModal,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.isShowSupportModal) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_as_support_modal)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}