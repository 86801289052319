
import { defineComponent, ref, computed } from 'vue'
import { MqResponsive } from 'vue3-mq'

import AsHeaderMobile from '@web/components/headers/AsHeaderMobile.vue'
import AsHeaderMain from '@web/components/headers/AsHeaderMain.vue'

import i18n from '@web/plugins/i18n'

import { useStore } from '@web/store'
import { useMeta } from '@web/common/vueMeta'

export default defineComponent({
  name: 'VHome',
  components: {
    MqResponsive,
    AsHeaderMobile,
    AsHeaderMain
  },
  setup () {
    const store = useStore()

    const isOpenMenu = ref(false)

    const user = computed(() => store.getters['user/user'])
    const username = computed(() => store.getters['user/username'])
    const menu = computed(() => store.getters['menu/general'])
    const text = computed(() => i18n.global.t('This version of the site is not available'))

    useMeta({
      title: text
    })

    return {
      isOpenMenu,
      username,
      user,
      text,
      menu
    }
  }
})
