/* istanbul ignore file */
import client from '../client'
import OfferMapper from '@web/mapper/OfferMapper'
import HeaderMapper from '@web/mapper/HeaderMapper'

export default class Offer {
  static fetch (courseId: number, offerTypeId?: number) {
    const params: {
      courseId: number;
      courseOfferRelationTypeId?: number;
    } = {
      courseId
    }
    if (offerTypeId) {
      params.courseOfferRelationTypeId = offerTypeId
    }
    return client.get('/course-offer/find', { params })
      .then(response => {
        return {
          headers: HeaderMapper.toClient(response.headers),
          data: response.data.map(OfferMapper.toClient)
        }
      })
  }
}
