import {
  RouterState,
  LocalRoute,
  RouterGettersTree,
  RouterMutationsTree
} from '@web/store/types/modules/router'

const state: RouterState = {
  history: [],
  returnUrl: null
}

const findPage = names => ({ name }) => names.includes(name)

const includedPaths = {
  home: ['home'],
  course: ['courses', 'calendar', 'day'],
  day: ['day'],
  chat: ['chat', 'chats'],
  task: ['task', 'tasks', 'tasks-stream'],
  catalog: ['catalog'],
  // profile: ['user', 'userEdit', 'userSettings', 'userPassword', 'settingsNotifications', 'settingsReminders', 'support', 'catalog']
  profile: ['user', 'userEdit', 'userSettings', 'userPassword', 'settingsNotifications', 'settingsReminders']
}

const getters: RouterGettersTree = {
  history: ({ history }) => history,
  home: state => {
    const lastPage: LocalRoute = state.history.find(findPage(includedPaths.home)) || {} as LocalRoute
    return lastPage.fullPath || '/home'
  },
  course: state => {
    const lastPage: LocalRoute = state.history.find(findPage(includedPaths.course)) || {} as LocalRoute
    return lastPage.fullPath || '/courses'
  },
  day: state => state.history.find(findPage(includedPaths.day)),
  chat: state => {
    const lastPage: LocalRoute = state.history.find(findPage(includedPaths.chat)) || {} as LocalRoute
    return lastPage.fullPath || '/chats'
  },
  task: state => {
    const lastPage: LocalRoute = state.history.find(findPage(includedPaths.task)) || {} as LocalRoute
    return lastPage.fullPath || '/tasks'
  },
  catalog: () => {
    return { name: 'catalog' }
  },
  profile: state => {
    const lastPage: LocalRoute = state.history.find(findPage(includedPaths.profile)) || {} as LocalRoute
    return lastPage.fullPath || '/user'
  },
  menu (state, { home, course, chat, task, catalog, profile }) {
    return {
      home: {
        link: home,
        includedPaths: includedPaths.home
      },
      course: {
        link: course,
        includedPaths: includedPaths.course
      },
      chat: {
        link: chat,
        includedPaths: includedPaths.chat
      },
      task: {
        link: task,
        includedPaths: includedPaths.task
      },
      catalog: {
        link: catalog,
        includedPaths: includedPaths.catalog
      },
      profile: {
        link: profile,
        includedPaths: includedPaths.profile
      }
    }
  },
  faceMenu (state, { menu }) {
    const _menu = menu
    _menu.chat.includedPaths = [...includedPaths.chat, ...includedPaths.task]
    return _menu
  },
  returnUrl: ({ returnUrl }) => returnUrl
}

const clear = state => {
  state.history = []
}

const mutations: RouterMutationsTree = {
  push (state, { fullPath, name, path, params }) {
    if (state.history.length > 0 && state.history[0].fullPath === fullPath) return

    state.history.unshift({
      fullPath,
      name,
      path,
      params
    })
  },
  setReturnUrl (state, url) {
    state.returnUrl = url
  },

  clear,
  logout: clear
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
