import Mapper from './Mapper'
import DayMapper from './DayMapper'
import HomeworkMapper from '@web/mapper/HomeworkMapper'

/* istanbul ignore next */
export default class StageMapper extends Mapper {
  static toClient (data): {
    stage: Stage;
    days: Day[];
    dayHomeworks: DayHomework[];
    stageHomeworks: StageHomework[];
    homeworks: Homework[];
  } {
    const days: Day[] = []
    const dayHomeworks: DayHomework[] = []
    const stageHomeworks: StageHomework[] = []
    const homeworks: Homework[] = []

    const stage: Stage = Mapper.converter({
      data,
      rules: {
        days: {
          rename: 'dayIds',
          cb: items => items.map(item => {
            const data = DayMapper.toClient(item)
            days.push(data.day)
            dayHomeworks.push(...data.dayHomeworks)
            homeworks.push(...data.homeworks)
            return data.day.id
          })
        },
        // eslint-disable-next-line camelcase
        course_stage_homeworks: {
          rename: 'stageHomeworkIds',
          cb: items => items.map(h => {
            const _h = HomeworkMapper.stageHomework(h)
            stageHomeworks.push(_h.stageHomework)
            homeworks.push(_h.homework)
            return h.id
          })
        }
      }
    }) as Stage

    return {
      stage,
      days,
      dayHomeworks,
      stageHomeworks,
      homeworks
    }
  }
}
