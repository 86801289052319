import { ProxyExternalConfig } from '@web/common/ExternalConfig/proxyExternalConfig'
import Logger from 'js-logger'
import Sentry from '@web/common/sentry'

const firebase = () => window.FirebasePlugin

const FIELDS = {
  welcomeMessages: 'data.userInfoChat.welcomeMessages',
  goodbyeMessages: 'data.userInfoChat.welcomeMessages',
  questions: 'data.userInfoChat.questions'
}

export default class FirebaseConfig {
  static setDefaults (state: ProxyExternalConfig): Promise<unknown> {
    if (!firebase()) {
      throw new Error('Firebase not init')
    }

    return new Promise((resolve, reject) => {
      if (firebase()) {
        const defaults: Dictionary<unknown> = {}

        for (const key in FIELDS) {
          defaults[key] = state.get(key)
        }

        firebase().setDefaults(defaults, resolve, reject)
      }
    })
  }

  static fetch (): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!firebase()) {
        throw new Error('Firebase not init')
      }

      if (process.env.NODE_ENV === 'development') {
        firebase().fetch(0, resolve, reject)
      } else {
        firebase().fetch(resolve, reject)
      }
    })
  }

  static activateFetched (): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!firebase()) {
        throw new Error('Firebase not init')
      }

      firebase().activateFetched(resolve, reject)
    })
  }

  static getAll (): Promise<Dictionary<string>> {
    return new Promise((resolve, reject) => {
      firebase().getAll(values => resolve(values as Dictionary<string>), reject)
    })
  }

  static updateConfig (state: ProxyExternalConfig): Promise<void> {
    return FirebaseConfig.fetch()
      .then(FirebaseConfig.activateFetched)
      .then(FirebaseConfig.getAll)
      .then(values => {
        for (const key in values) {
          if (key in FIELDS) {
            try {
              state.set(FIELDS[key], JSON.parse(values[key]))
            } catch (e) {
              Logger.warn(`Не получилось сохранить поле { key: '${key}', value: '${values[key]}'}`)
              Sentry.captureException(e)
              Logger.error(e)
            }
          }
        }
      })
  }
}
