import Logger from '@web/common/Logger'

export default class LocalStorage {
  static get (key: string): string | null {
    let value: string | null = null
    try {
      value = localStorage.getItem(key)
    } catch (e) {
      Logger.error(`Can't read item from localStorage. ITEM_KEY = ${key}`)
      Logger.error(e)
    }
    return value
  }

  static set (key: string, value: string): void {
    try {
      localStorage.setItem(key, value)
    } catch (e) {
      Logger.error(`Can't record item in localStorage. ITEM_KEY = ${key}. ITEM_VALUE = ${value}`)
      Logger.error(e)
    }
  }

  static remove (key: string): void {
    localStorage.removeItem(key)
  }
}
