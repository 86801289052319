import Mapper from '@web/mapper/Mapper'

/* istanbul ignore next */
export default class CookieMapper extends Mapper {
  static toClient (data): { isEnabled: boolean; items: Cookies[] } {
    const convertedData = Mapper.converter({
      data
    }) as { isEnabled: boolean; items: Cookies[] }

    return {
      isEnabled: convertedData.isEnabled,
      items: convertedData.items
    }
  }
}
