
import { defineComponent, computed, ref } from 'vue'
import { RouteLocationRaw, useRouter } from 'vue-router'
import AsMenu from '@web/components/AsMenu.vue'
import { useStore } from '@web/store'
import AcPointsLabel from '@web/components.v2/PointsLabel.vue'
import Analytics from '@web/services/Analytics/Analytics'

export default defineComponent({
  props: {
    avatar: {
      type: String,
      default: ''
    },
    title: {
      title: String,
      default: ''
    },
    nav: {
      type: Boolean,
      default: true
    },
    customBack: {
      type: Boolean,
      default: false
    },
    back: {
      type: Boolean,
      default: false
    },
    prevPage: {
      type: [String, Object]
    },
    showPoints: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AcPointsLabel,
    AsMenu
  },
  emits: ['goBack', 'menu:toggle', 'menu:open', 'menu:close', 'item:select'],
  setup (props, ctx) {
    const showMenu = ref<boolean>(false)
    function openMenu () {
      showMenu.value = true
      ctx.emit('menu:open')
      ctx.emit('menu:toggle', showMenu.value)
      Analytics.send({
        action: 'open_burger',
        project: 'platform'
      })
    }
    function closeMenu () {
      showMenu.value = false
      ctx.emit('menu:close')
      ctx.emit('menu:toggle', showMenu.value)
      Analytics.send({
        action: 'close_burger',
        project: 'platform'
      })
    }
    function toggleMenu () {
      if (showMenu.value) {
        closeMenu()
      } else {
        openMenu()
      }
    }

    const router = useRouter()
    const showBack = computed(() => props.back || props.prevPage || props.customBack)
    function goBack () {
      if (showMenu.value) {
        closeMenu()
      } else if (props.customBack) {
        ctx.emit('goBack')
      } else {
        if (props.prevPage) {
          router.push(props.prevPage as RouteLocationRaw)
        } else {
          router.go(-1)
        }
      }
    }

    const store = useStore()
    const menu = computed(() => store.getters['menu/mobile'])
    const userScoreAmount = computed(() => store.getters['userScore/amount'])

    function selectMenuItem (item) {
      ctx.emit('item:select', item)
      closeMenu()
    }

    function onHandlerEsc () {
      if (showMenu.value) {
        closeMenu()
      }
    }

    return {
      showBack,
      goBack,
      menu,
      toggleMenu,
      showMenu,
      selectMenuItem,
      userScoreAmount,
      onHandlerEsc
    }
  }
})
