import { getState } from '@web/common/ExternalConfig/ExternalConfig'
import locale from '@web/common/locale'
import { ClubInApp, Question, translate, PointsInfo } from '@web/store/types/modules/assets'

export const isOfflineEnable = (): boolean => Boolean(getState().data.isOfflineEnable)
export const isUserAnonAuthEnable = (): boolean => Boolean(getState().data.isUserAnonAuthEnable)
export const isRegistrationEnable = (): boolean => Boolean(getState().data.isRegistrationEnable)

export const passHomeworkSupportCategoryId = (): number | undefined => getState().data.passHomeworkSupportCategoryId
export const renewCourseSupportCategoryId = (): number | undefined => getState().data.renewCourseSupportCategoryId
export const renewConsultationSupportCategoryId = (): number | undefined => getState().data.renewConsultationSupportCategoryId

export const welcomeMessages = (): translate[] => getState().data?.userInfoChat?.welcomeMessages || []
export const questions = (): Question[] => getState().data?.userInfoChat?.questions || []
export const goodbyeMessages = (): translate[] => getState().data?.userInfoChat?.goodbyeMessages || []

export const minSaveViewedMS = (): number | undefined => getState().data?.statistic?.MIN_SAVE_VIEWED_MS
export const minSaveViewedMSDayStatusFinished = (): number | undefined => getState().data?.statistic?.MIN_SAVE_VIEWED_MS_VIDEO_ENDED
export const timeoutSaveStatistic = (): number | undefined => getState().data?.statistic?.TIMEOUT_SAVE_STATISTIC
export const messageDelay = (): number => getState().data?.messageDelay || 15
export const isDemoAccessible = (): boolean => Boolean(getState().data.isDemoAccessible)
export const isVideoConsultationSupport = (): boolean => Boolean(getState().data.isVideoConsultationSupport)
export const isBannerEnable = (): boolean => Boolean(getState().data.isBannerEnable)
export const saleEmail = (): string => getState().data.saleEmail || 'sale@anyclass.com'
export const isAppStoreSupport = (): boolean => Boolean(getState().data.isAppStoreSupport)
export const daysShowWarning = (): number => getState().data.daysShowWarning || 0
export const isChatSupport = (): boolean => Boolean(getState().data.isChatSupport)
export const socialAuth = (): { android: string[]; ios: string[] } => getState().data.socialAuth
export const daysNoticeRenew = (): number => getState().data.daysShowWarning || 3
export const instructionUrl = (): string => getState().data.instructionUrl?.[locale.get()] || ''
export const guidelineUrl = (): string => getState().data.guidelineUrl?.[locale.get()] || ''
export const communityUrlFrontend = (): string => getState().data.communityUrlFrontend || ''
export const communityUrlApi = (): string => {
  const url = getState().data.communityUrlApi || ''
  return url ? new URL(url, window.location.toString()).toString() : ''
}
export const clubInApp = (): ClubInApp => getState().data.clubInApp || {}
export const pointsInfo = (): PointsInfo => getState().data.pointsInfo || {
  countPointsToDebit: 200,
  links: {},
  faq: []
}
export const cookiePolicyLink = (): string => getState().data.cookiePolicyLink || ''
