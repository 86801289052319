import Cookies from 'js-cookie'
import LocalStorage from '@web/common/LocalStorage'

const TOKEN_KEY = 'acProject_token'
let token = ''

export default class Token {
  static get (): string {
    return LocalStorage.get(TOKEN_KEY) || Cookies.get(TOKEN_KEY) || token || ''
  }

  static set (newToken: string): void {
    token = newToken
    LocalStorage.set(TOKEN_KEY, newToken)
    Cookies.set(TOKEN_KEY, newToken, { sameSite: 'Strict', expires: 30 })
  }

  static remove (): void {
    token = ''
    LocalStorage.remove(TOKEN_KEY)
    Cookies.remove(TOKEN_KEY)
    Cookies.remove(TOKEN_KEY, { domain: `.${window.location.host}` })
  }
}
