import { createApp } from 'vue'

import App from './App.vue'
import { router } from '@web/router'
import { store } from './store'

import { Vue3Mq } from 'vue3-mq'

import i18n from './plugins/i18n'
import './common/tingle'

import smoothScroll from 'smoothscroll-polyfill'

import '@web/common/consoleDebug'
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import sentry, { init as sentryInit } from '@web/common/sentry'
import locale from '@web/common/locale'
import Logger from '@web/common/Logger'
import { fetchConfig } from '@web/common/ExternalConfig/ExternalConfig'
import '@web/common/Validator.ts'
import * as AnalyticEvents from '@web/services/Analytics/events'

import 'maz-ui/css/main.css'

import '@ui-kit/styles/main.scss'

smoothScroll.polyfill()

store.dispatch('assets/fetchConfig')
  .then(fetchConfig)
  .then(() => {
    locale.init({
      supportedLanguage: store.state.assets.supportedLanguage,
      defaultLanguage: store.state.assets.defaultLanguage
    })

    const app = createApp(App)

    AnalyticEvents.openApp()

    sentryInit(app, router)

    app
      .use(router)
      .use(store)
      .use(Vue3Mq, {
        breakpoints: { // default breakpoints - customize this
          mobile: 0,
          tablet: 414 * 2,
          // область контента + место под стрелку * 2 + место между стрелкой и контентом * 2
          desktop: 1192 + 12 * 2 + 14 * 2
        }
      })
      .use(i18n)
      .mount('#app')
  })
  .catch(e => {
    Logger.error(e)
    sentry.captureException(e)
    Swal.fire({
      icon: 'error',
      title: i18n.global.t('error.error'),
      text: i18n.global.t('error.errorLoadConfig')
    })
  })
