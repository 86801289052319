import { ExternalConfig } from '@web/store/types/modules/assets'
import { APP_ID, IS_MOBILE } from '@web/common/config'
import { reactive } from 'vue'
import FirebaseConfig from '@web/common/ExternalConfig/FirebaseConfig'
import proxyExternalConfig from '@web/common/ExternalConfig/proxyExternalConfig'
import client from '@web/api/client'

// eslint-disable-next-line @typescript-eslint/no-var-requires
let state: ExternalConfig = reactive(require(`@common/config/${APP_ID}.json`))
const stateProxy = proxyExternalConfig(state)

export const fetchFirebaseConfigAndUpdate = (): void => {
  if (IS_MOBILE) {
    FirebaseConfig.updateConfig(stateProxy)
  }
}

export const setState = (value: ExternalConfig): void => {
  if (state.version === value.version) {
    state = value
    fetchFirebaseConfigAndUpdate()
  }
}

export const fetchConfig = (): Promise<void> => {
  return client.get('/external-application-config')
    .then(response => {
      const config = response.data.config as ExternalConfig
      if (state.version === config.version) {
        setState(config)
      }
    })
}

export const getState = (): ExternalConfig => state
