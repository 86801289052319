import UserCourseStreamApi from '@web/api/modules/Stream'
import DayApi from '@web/api/modules/Day'
import STREAM_STATUS, { USER_COURSE_STREAM_FREEZE_STATUS } from '@web/consts/StreamStatus'
import InvalidParamError from '@web/common/errors/InvalidParamError'
import { getNumberDecliner, isOnline } from '@web/common/Utils'
import sentry from '@web/common/sentry'
import DAY_STATUS from '@web/consts/DayStatus'
import i18n from '@web/plugins/i18n'

import {
  CurrentDay,
  DayStatistic,
  DayStatus,
  StatisticsData,
  Stream,
  StreamActionsTree,
  StreamGettersTree,
  StreamMutationsTree,
  StreamState
} from '@web/store/types/modules/stream'
import { isDemoAccessible, minSaveViewedMS, minSaveViewedMSDayStatusFinished } from '@web/common/ExternalConfig/getters'
import { RawSubscription, Subscription } from '@web/types/Subscription'
import Time from '@web/common/Time'
import OneSignal from '@web/common/OneSignal'

const state: StreamState = {
  all: {},
  stageStatuses: {},
  dayStatuses: {},
  dayStatistic: {},
  lessonStatuses: {},
  chatChannelMetaStatuses: {},
  isLoadedAll: false,
  statistic: {},
  isNeedUpdateStatus: false,
  streamOrderService: {},
  currentDays: {}
}

const cachedStatusRequest = new Map()

function getPaymentSubscriptionId (stream: Stream, rawSubscriptions: RawSubscription[]): null | number {
  // TODO Подумать как можно будет улучшить производительность
  if (stream.isOptionalPurchase) {
    // Если курс куплен через встроенную покупку
    // то проверяем есть ли подписка

    // Ищем подписки с нужным курсом

    const subscriptions = rawSubscriptions.filter(s => s.content.courses.find(c => c.id === stream.courseId))

    // Если есть активная
    const now = Time()
      .set('hours', 0)
      .set('minutes', 0)
      .set('seconds', 0)
      .set('milliseconds', 0)
      .unix() * 1000
    let subscription = subscriptions.find(s => (s.nextPayAt || 0) >= now)
    if (subscription) {
      return subscription.id
    }

    // Ищем последнюю активную
    subscription = subscriptions
      .sort((a, b) => (b.nextPayAt || 0) - (a.nextPayAt || 0))[0]
    if (subscription) {
      return subscription.id
    }

    return null
  }

  return null
}

function getStatus (stream: Stream, hasSubscription: boolean, activeSubscriptions: Subscription[]) {
  if (stream.status === STREAM_STATUS.FREEZE) {
    return stream.status
  }

  const now = Time().unix() * 1000

  // Если текущая дата меньше даты старта курса
  if (now < stream.startDate) {
    return STREAM_STATUS.NOT_STARTED
  }

  if (stream.isOptionalPurchase) {
    // Если курс куплен через встроенную покупку
    // то проверяем есть ли подписка с данным курсом
    if (!hasSubscription) {
      return STREAM_STATUS.STOPPED
    }

    const hasCourse = activeSubscriptions.find(s => s.raw.content.courses.find(c => c.id === stream.courseId))
    return hasCourse ? STREAM_STATUS.OPEN : STREAM_STATUS.STOPPED
  } else if (now >= stream.startDate && now <= stream.endDate) {
    // Если текущая дата больше даты старта курса и меньше даты окончания курса
    // и курс куплен НЕ через встроенную покупку
    return STREAM_STATUS.OPEN
  }

  return STREAM_STATUS.ENDED
}

const getters: StreamGettersTree = {
  /**
   *
   * @param all
   * @param getters
   * @param rootState
   * @param rootGetters
   */
  streams: ({ all }, getters, rootState, rootGetters) => {
    const hasSubscription = rootGetters['subscription/hasSubscription']
    const rawSubscriptions = rootGetters['subscription/rawSubscriptions']
    const activeSubscriptions = rootGetters['subscription/activeSubscriptions']
    return Object.values(all).map(stream => {
      return {
        ...stream,
        status: getStatus(stream, hasSubscription, activeSubscriptions),
        paymentSubscriptionId: getPaymentSubscriptionId(stream, rawSubscriptions)
      }
    })
  },
  getStreamById: (state, getters, rootState, rootGetters) => id => {
    const hasSubscription = rootGetters['subscription/hasSubscription']
    const rawSubscriptions = rootGetters['subscription/rawSubscriptions']
    const activeSubscriptions = rootGetters['subscription/activeSubscriptions']
    return state.all[id]
      ? {
          ...state.all[id],
          status: getStatus(state.all[id], hasSubscription, activeSubscriptions),
          paymentSubscriptionId: getPaymentSubscriptionId(state.all[id], rawSubscriptions)
        }
      : undefined
  },
  streamsWithStatusOpen: (state, { streams }) => {
    return streams
      .filter(({ status }) => status === STREAM_STATUS.OPEN)
  },
  streamsWithStatusEnded: (state, { streams }) => {
    return streams
      .filter(({ status, isDemo }) => (status === STREAM_STATUS.ENDED || status === STREAM_STATUS.STOPPED) && !isDemo)
  },
  streamsWithStatusOpenOrNotStarted: (state, { streams }) => {
    return streams
      .filter(({ status, isDemo }) => [STREAM_STATUS.OPEN, STREAM_STATUS.NOT_STARTED].includes(status) || isDemo)
  },
  streamsWithStatusFreeze: (state, { streams }) => streams.filter(({ status }) => status === STREAM_STATUS.FREEZE),
  streamsInProgress: (state, { streams }) => {
    return streams
      .filter(({ status, progress }) => status === STREAM_STATUS.OPEN && progress && progress.progress < 100)
  },
  isLoadedAll: ({ isLoadedAll }) => isLoadedAll,
  getStageStatus: ({ stageStatuses }) => ({ streamId, stageId }) => {
    return stageStatuses[`${streamId}_${stageId}`]
  },
  getDayStatus: ({ dayStatuses }, getters, rootState, rootGetters) => ({ streamId, dayId }) => {
    let status: DayStatus
    if (dayStatuses[`${streamId}_${dayId}`]) {
      status = dayStatuses[`${streamId}_${dayId}`]
    } else {
      return undefined
    }

    let isLocked = false

    const stream = getters.getStreamById(streamId)
    if (stream && !stream.courseFullAccess && stream.isDemo) {
      const day = rootGetters['day/getDayById'](dayId)
      if (day) {
        isLocked = !day.isDemoAccessible || !status.isOpenByTimeDelay
      }
    }

    return {
      ...status,
      isLocked
    }
  },
  lessonStatuses: ({ lessonStatuses }) => lessonStatuses,
  stageStatuses: ({ stageStatuses }) => stageStatuses,
  getNextDayByStreamId: (state, { getStreamById }, rootState, rootGetters) => streamId => {
    const stream = getStreamById(streamId)
    if (!stream) {
      return
    }
    const currentDay: CurrentDay | undefined = state.currentDays[streamId]
    if (!currentDay || !currentDay.dayId) {
      return
    }
    const course = rootGetters['course/getCourseById'](stream.courseId)
    if (!course?.title) {
      return
    }

    return {
      streamId: stream.id,
      stageId: currentDay.stageId,
      dayId: currentDay.dayId,
      title: course.title,
      note: `${currentDay.title}, ${currentDay.excerpt}`
    }
  },
  nextDay: (state, { streamsInProgress, getNextDayByStreamId }, _, rootGetters) => {
    const lastVisitDay = rootGetters['router/day']
    if (lastVisitDay && lastVisitDay?.params?.streamId) {
      const streamId = Number(lastVisitDay.params.streamId)
      const stream = streamsInProgress.find(stream => stream.id === streamId)
      if (!stream) {
        return
      }
      const course = rootGetters['course/getCourseById'](stream.courseId)
      const day = rootGetters['day/getDayById'](Number(lastVisitDay.params.dayId))
      return {
        streamId: lastVisitDay.params.streamId,
        stageId: lastVisitDay.params.stageId,
        dayId: lastVisitDay.params.dayId,
        title: course?.title || '',
        note: `${day?.title}, ${day?.excerpt}`
      }
    }
    for (const streamId in streamsInProgress) {
      const nextDay = getNextDayByStreamId(streamId)
      if (nextDay) {
        return nextDay
      }
    }
  },
  getConsultationCountTextByStreamId: (state, getters, rootState, rootGetters) => streamId => {
    const stream = getters.getStreamById(streamId)
    if (!stream) {
      return ''
    }
    const { maxConsultationCount } = stream
    const course = rootGetters['course/getCourseById'](stream.courseId)
    if (!course) {
      return ''
    }
    const { consultationLimit } = course
    if (!consultationLimit) {
      return ''
    }
    const consultationCount = rootGetters['consultation/getConsultationsCountByChatId'](stream.chatChannelId)

    const n = maxConsultationCount - consultationCount
    if (n === maxConsultationCount) {
      return getNumberDecliner({
        n: maxConsultationCount,
        single: String(i18n.global.t('consultation.paidConsultationsMax1')),
        multiple: String(i18n.global.t('consultation.paidConsultationsMax')),
        multiple2: String(i18n.global.t('consultation.paidConsultationsMax2'))
      }).replace('$max', maxConsultationCount.toString())
    }

    if (n === 0) {
      return String(i18n.global.t('consultation.paidConsultationsOver'))
    } else {
      return getNumberDecliner({
        n,
        single: String(i18n.global.t('consultation.paidConsultationsLeft1')),
        multiple: String(i18n.global.t('consultation.paidConsultationsLeft')),
        multiple2: String(i18n.global.t('consultation.paidConsultationsLeft2'))
      }).replace('$n', n.toString())
    }
  },
  getFiltersByStreamId: ({ all }) => id => all[id]?.filters,
  getStagesByStreamId: (state, { stageStatuses }, rootState, rootGetters) => streamId => {
    const allStages = rootGetters['stage/stages']
    const stageStatusesArray: StageStatus[] = Object.values(stageStatuses)
    return stageStatusesArray
      .filter(({ streamId: id }) => id === streamId)
      .map((stage) => {
        return {
          ...allStages[stage.id],
          ...stage
        }
      })
  },
  chatChannelMetaStatuses: ({ chatChannelMetaStatuses }) => chatChannelMetaStatuses,
  getChatChannelMetaByStreamId: ({ chatChannelMetaStatuses }) => streamId => chatChannelMetaStatuses[streamId],
  isPaidConsultationsOverByStreamId: (state, { getStreamById }, rootState, rootGetters) => streamId => {
    const stream = getStreamById(streamId)
    if (!stream) {
      return true
    }
    const course = rootGetters['course/getCourseById'](stream.courseId)
    if (!course) {
      return true
    }
    if (course && !course.consultationLimit) {
      return false
    }
    const n = stream.maxConsultationCount - rootGetters['consultation/getConsultationsCountByChatId'](stream.chatChannelId)
    return n <= 0
  },
  getDayStatistic: state => (id: number) => state.dayStatistic[id],
  getOrderServiceByStreamId: state => id => state.streamOrderService[id]
}

const addUserCourseStream = (state, stream) => {
  state.all[stream.id] = {
    ...state.all[stream.id],
    ...stream
  }
}

const appendStat = (state, payload: { streamId: number; dayId: number; lessonId: number; time?: number; play?: boolean; dayStatusId: number; dayStatus: string }) => {
  const { streamId, dayId, lessonId, time = 0, play = false, dayStatusId, dayStatus } = payload
  const id = `${streamId}_${lessonId}`
  const lessonStat = state.lessonStatuses[id] || { viewedSeconds: 0, playCount: 0, streamId, lessonId }
  const statistic = state.statistic ? state.statistic : {}
  const dayStatistic = statistic[id] ? statistic[id] : { viewedSeconds: 0, playCount: 0, streamId, dayId, lessonId, dayStatusId, dayStatus }

  if (time > 0 && Number.isInteger(time)) {
    lessonStat.viewedSeconds += time
    dayStatistic.viewedSeconds += time
  }

  if (play) {
    lessonStat.playCount++
    dayStatistic.playCount++
  }

  state.lessonStatuses[id] = lessonStat
  state.statistic[id] = dayStatistic
}

const mutations: StreamMutationsTree = {
  receiveUserCourseStream: addUserCourseStream,

  receiveUserCourseStreams (state, courses) {
    courses.map(course => addUserCourseStream(state, course))
  },

  receiveStreamCurrentDay (state, { streamId, currentDay }) {
    state.currentDays[streamId] = currentDay
  },

  setStatusLoadAll (state, status) {
    state.isLoadedAll = status
  },

  receiveStageStatuses (state, { streamId, statuses }) {
    statuses.forEach(status => {
      state.stageStatuses[`${streamId}_${status.id}`] = {
        ...status,
        streamId
      }
    })
  },

  receiveDayStatuses (state, { streamId, statuses }) {
    statuses.forEach(status => {
      state.dayStatuses[`${streamId}_${status.stageDayId}`] = {
        ...status
      }
    })
  },

  receiveLessonStatuses (state, { statuses, streamId }) {
    if (Array.isArray(statuses)) {
      statuses.forEach(stat => {
        state.lessonStatuses[`${streamId}_${stat.lessonSetLessonId}`] = {
          ...stat,
          streamId
        }
      })
    }
  },

  receiveChatChannelMetaStatuses (state, { chatChannelMeta, streamId }) {
    state.chatChannelMetaStatuses[streamId] = {
      ...chatChannelMeta,
      streamId
    }
  },

  appendTimeSpent (state, { dayId, streamId, time, lessonId, dayStatusId, dayStatus }) {
    appendStat(state, { dayId, streamId, lessonId, time, dayStatusId, dayStatus })
  },

  appendPlay (state, { dayId, streamId, lessonId, dayStatusId, dayStatus }) {
    appendStat(state, { dayId, streamId, lessonId, play: true, dayStatusId, dayStatus })
  },

  statisticClear (state) {
    state.statistic = {}
  },

  setNeedUpdateStatus (state, isNeedUpdateStatus) {
    state.isNeedUpdateStatus = isNeedUpdateStatus
  },

  setFilter (state, { streamId, key, value }) {
    state.all[streamId].filters = {
      ...state.all[streamId].filters,
      [key]: value
    }
  },

  removeDeletedStreams (state, ids) {
    const copyStateAll = state.all
    for (const id in copyStateAll) {
      if (!Object.prototype.hasOwnProperty.call(copyStateAll, id)) continue

      if (!ids.includes(Number(id))) {
        delete copyStateAll[id]
      }
    }
    state.all = copyStateAll
  },

  clearDayStatuses (state) {
    state.dayStatuses = {}
  },

  clearLessonStatuses (state) {
    state.lessonStatuses = {}
  },

  clearStageStatuses (state) {
    state.stageStatuses = {}
  },

  receiveDayStatistic (state, { dayStatusId, status }: { dayStatusId: number; status: DayStatistic }) {
    state.dayStatistic[dayStatusId] = status
  },

  statisticRemove (state, id: string) {
    delete state.statistic[id]
  },

  logout (state) {
    state.all = {}
    state.dayStatuses = {}
    state.lessonStatuses = {}
    state.stageStatuses = {}
    state.chatChannelMetaStatuses = {}
    state.isLoadedAll = false
    state.statistic = {}
    cachedStatusRequest.clear()
  },

  receiveOrderService (state, payload) {
    state.streamOrderService[payload.streamId] = payload.serviceId
  }
}

const fetchUserCourseStream = async ({ data, dispatch, commit }): Promise<Stream> => {
  if (data.course) {
    await dispatch('course/setCourse', data.course, { root: true })
  }

  const stream = data.stream
  if (stream.chatStatus) {
    commit('receiveChatChannelMetaStatuses', { streamId: stream.id, chatChannelMeta: stream.chatStatus })
  }

  if (data.service) {
    // потому что когда услуга из заказа (order.service)
    // мы не загружаем course (order.service.course)
    const service = {
      courseId: stream.courseId,
      ...data.service
    }
    commit('service/receiveService', service, { root: true })
    commit('receiveOrderService', {
      serviceId: data.service.id,
      streamId: stream.id
    })
  }

  commit('receiveUserCourseStream', stream)
  return stream
}

/* istanbul ignore next */
const actions: StreamActionsTree = {
  fetchUserCourseStreams ({ commit, dispatch }, params) {
    // const oneSignalTags = await OneSignal.getTags()
    // if (oneSignalTags) {
    //   for (const k in oneSignalTags) {
    //     if (k.indexOf('has_course_') === 0) {
    //       OneSignal.sendTag(k, '0')
    //     }
    //   }
    // }
    commit('setStatusLoadAll', false)
    const fields = 'id, is_demo, status, course_version, course_full_access, mobile_access'
    return UserCourseStreamApi.fetchAll({
      fields,
      ...params
    })
      .then(({ data }) => {
        return data
          .filter(item => item.stream.isDemo ? isDemoAccessible : true)
          .map(item => {
            if (item.course) {
              OneSignal.prepareTags({ [`has_course_${item.course.id}`]: item.course.categoryId })
            }
            return fetchUserCourseStream({ data: item, commit, dispatch })
          })
      })
      .then(data => Promise.all(data))
      .then(data => {
        commit('setStatusLoadAll', true)
        OneSignal.readyTags.has_course = true
        OneSignal.sendPrepareTags()
        return Promise.all(data)
      })
  },

  fetchUserCourseStreamsCached ({ state, getters, commit, dispatch }, params) {
    if (!state.isLoadedAll) {
      commit('setNeedUpdateStatus', false)
      return dispatch('fetchUserCourseStreams', {
        ...params
      })
    } else {
      commit('setNeedUpdateStatus', true)
      return Promise.resolve(getters.streams)
      // return dispatch('fetchUserCourseStreamsUpdateStatus')
    }
  },

  fetchUserCourseStreamsUpdateStatus ({ dispatch, commit }) {
    const fields = 'id, is_demo, status, course_version, course_full_access, mobile_access'
    return UserCourseStreamApi.fetchAll({
      fields
    })
      .then(({ data }) => {
        const streamsIds: number[] = []
        const streams = data.map(item => {
          streamsIds.push(item.stream.id)
          return fetchUserCourseStream({ data: item, commit, dispatch })
        })
        commit('removeDeletedStreams', streamsIds)
        return Promise.all(streams)
      })
  },

  fetchUpdateUserCoursesStreams ({ commit, dispatch }, ids) {
    return UserCourseStreamApi.fetchUpdateAll(ids)
      .then(({ data }) => {
        return data.map((item) => fetchUserCourseStream({ data: item, commit, dispatch }))
      })
  },

  async fetchUserCourseStream ({ commit, dispatch }, { id, ...params }) {
    if (!id) {
      throw new InvalidParamError('Id must be set')
    }
    return UserCourseStreamApi.fetch({ id, ...params })
      .then(response => {
        return fetchUserCourseStream({ data: response.data, commit, dispatch })
      })
  },

  async fetchUserCourseStreamCached ({ getters, dispatch }, { id, ...params }) {
    if (!getters.getStreamById(id)) {
      await dispatch('fetchUserCourseStream', { id, ...params })
    }
    return getters.getStreamById(id)
  },

  fetchUpdateUserStreamsStatus ({ state, getters, dispatch }) {
    const updatePromises: Promise<Stream>[] = []
    if (state.isNeedUpdateStatus) {
      getters.streams
        .filter(({ status }) => status !== STREAM_STATUS.ENDED && status !== STREAM_STATUS.STOPPED)
        .forEach(({ id }) => {
          updatePromises.push(dispatch('fetchUpdateUserCourseStream', id))
        })
    }
    return Promise.all(updatePromises)
  },

  fetchUpdateUserCourseStream ({ commit, dispatch }, id) {
    if (!id) {
      throw new InvalidParamError('Id must be set')
    }
    return UserCourseStreamApi.fetchUpdate(id)
      .then(({ data }) => fetchUserCourseStream({ data, commit, dispatch }))
  },

  fetchStatuses ({ commit }, { id, ...options }) {
    if (!id) {
      throw new InvalidParamError('Id must be set')
    }
    const key = JSON.stringify({ id, ...options })
    cachedStatusRequest.set(key, Date.now())

    return UserCourseStreamApi.fetchStatuses({ id, ...options })
      .then(({ data: statuses }) => {
        if (statuses.stages) {
          commit('receiveStageStatuses', { streamId: id, statuses: statuses.stages })
        }
        if (statuses.days) {
          commit('receiveDayStatuses', { streamId: id, statuses: statuses.days })
        }
        if (statuses.lessons) {
          commit('receiveLessonStatuses', { streamId: id, statuses: statuses.lessons })
        }
        if (statuses.homeworks) {
          commit('homework/receiveStatuses', { statuses: statuses.homeworks }, { root: true })
        }
        if (statuses.consultations) {
          commit('consultation/receiveStatuses', { statuses: statuses.consultations }, { root: true })
        }
        if (statuses.chatChannelMeta) {
          commit('receiveChatChannelMetaStatuses', { streamId: id, chatChannelMeta: statuses.chatChannelMeta })
        }
        if (statuses.currentDay) {
          commit('receiveStreamCurrentDay', {
            streamId: id,
            currentDay: statuses.currentDay
          })
        }

        return statuses
      })
      .catch(error => {
        cachedStatusRequest.delete(key)
        return Promise.reject(error)
      })
  },

  fetchStatusesCached ({ dispatch }, payload) {
    const key = JSON.stringify(payload)
    if (cachedStatusRequest.has(key)) {
      if (cachedStatusRequest.get(key) < Date.now() - 1000 * 60 * 5) {
        return dispatch('fetchStatuses', payload)
      }
      return Promise.resolve()
    } else {
      return dispatch('fetchStatuses', payload)
    }
  },

  async sendStatistic ({ state, commit }) {
    if (!isOnline()) {
      return Promise.resolve()
    }

    for (const id in state.statistic) {
      if (!Object.prototype.hasOwnProperty.call(state.statistic, id)) continue

      const data: StatisticsData = { ...state.statistic[id] }

      try {
        let MIN_SAVE_VIEWED_MS = minSaveViewedMS() || 2000
        if (data.dayStatus === DAY_STATUS.ENDED) {
          MIN_SAVE_VIEWED_MS = minSaveViewedMSDayStatusFinished() || 30000
        }
        if (data.viewedSeconds && data.viewedSeconds < MIN_SAVE_VIEWED_MS) {
          continue
        }
      } catch (e) {

      }

      let response
      try {
        response = await DayApi.sendStatistic(data)
        if (data?.dayStatusId) {
          commit('receiveDayStatistic', {
            dayStatusId: data.dayStatusId,
            status: response.data as DayStatistic
          })
        } else {
          // eslint-disable-next-line no-console
          console.log('data', data)
          // eslint-disable-next-line no-console
          console.log('response.data', response.data)
          sentry.captureMessage('Что-то пошло не так при отправке статистики')
        }
      } catch (e) {
        sentry.captureException(e)
      }

      commit('statisticRemove', id)
    }
  },

  updateFilter ({ commit, dispatch }, { streamId, key, value }) {
    commit('setFilter', { streamId, key, value })

    dispatch('saveFilters', { streamId })
  },

  async saveFilters ({ getters }, { streamId }) {
    if (!streamId) {
      throw new InvalidParamError('streamId must be set')
    }
    const filters = getters.getFiltersByStreamId(streamId)

    await DayApi.updateFilters({
      streamId,
      filters
    })
  },
  async freeze ({ getters, commit }, { streamId, startDate, endDate }) {
    const response = await UserCourseStreamApi.freezeStream({
      streamId,
      startDate,
      endDate
    })
    const stream = getters.getStreamById(streamId)
    if (stream) {
      commit('receiveUserCourseStream', {
        ...stream,
        userCourseStreamFreeze: response.data
      })
    }
    return response.data
  },
  async unfreeze ({ getters, commit }, { streamId, ...params }) {
    const response = await UserCourseStreamApi.unfreezeStream({ streamId, ...params })
    const stream = getters.getStreamById(streamId)
    if (stream) {
      commit('receiveUserCourseStream', {
        ...stream,
        userCourseStreamFreeze: {
          ...stream.userCourseStreamFreeze,
          status: USER_COURSE_STREAM_FREEZE_STATUS.canceled
        }
      })
    }
    return response.data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
// eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
