import Mapper from '@web/mapper/Mapper'
import HomeworkMapper from '@web/mapper/HomeworkMapper'

/* istanbul ignore next */
export default class DayMapper extends Mapper {
  static lessonSets (data): LessonSet {
    return Mapper.converter({
      data,
      rules: {
        // eslint-disable-next-line camelcase
        lesson_set: {
          cb: value => Mapper.toClient(value)
        },
        lessons: {
          cb: items => items.map(Mapper.toClient)
        }
      }
    }) as LessonSet
  }

  static toClient (data): {
    day: Day;
    dayHomeworks: DayHomework[];
    homeworks: Homework[];
  } {
    const dayHomeworks: DayHomework[] = []
    const homeworks: Homework[] = []

    const day: Day = Mapper.converter({
      data,
      rules: {
        // eslint-disable-next-line camelcase
        description_short: {
          rename: 'excerpt'
        },
        // eslint-disable-next-line camelcase
        finish_total_time: {
          cb: value => value * 1000
        },
        // eslint-disable-next-line camelcase
        required_to_complete: {
          cb: value => value === 1
        },
        // eslint-disable-next-line camelcase
        training_options: {
          rename: 'courseOptions',
          cb: options => options.map(option => Mapper.converter({
            data: option,
            rules: {
              // eslint-disable-next-line camelcase
              training_option_values: {
                cb: values => values.map(Mapper.toClient),
                rename: 'trainingLessonOptionValues'
              }
            }
          }))
        },
        scores: {
          cb: _scores => {
            const newScores = _scores
            for (const s in newScores) {
              newScores[s] = Mapper.toClient(newScores[s])
            }
            return newScores
          }
        },
        // eslint-disable-next-line camelcase
        lesson_sets: {
          cb: lessonSets => lessonSets.map(DayMapper.lessonSets)
            .sort((a: LessonSet, b: LessonSet) => a.sort - b.sort)
        },
        // eslint-disable-next-line camelcase
        stage_day_homeworks: {
          rename: 'dayHomeworkIds',
          cb: _homeworks => _homeworks.map(h => {
            const _h = HomeworkMapper.toClient(h)
            dayHomeworks.push(_h.dayHomework)
            homeworks.push(_h.homework)
            return h.id
          })
        },
        banners: {
          rename: 'bannerIds'
        },
        // eslint-disable-next-line camelcase
        is_demo_accessible: {
          type: 'boolean'
        }
      },
      defaults: {
        isOpenByTimeDelay: true
      }
    }) as Day

    return {
      day,
      dayHomeworks,
      homeworks
    }
  }
}
