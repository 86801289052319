import UserScoreApi from '@web/api/modules/UserScore'

import {
  UserScoreState,
  UserScoreGettersTree,
  UserScoreMutationsTree,
  UserScoreActionsTree
} from '@web/store/types/modules/userScore'
import { PaginationParam } from '@web/api/modules/Pagination'

const state: UserScoreState = {
  history: [],
  amount: 0,
  paginationParam: {
    currentPage: 1,
    pageCount: 0,
    perPage: 20,
    totalCount: 0
  }
}

const getters: UserScoreGettersTree = {
  history ({ history }) {
    return history
  },
  amount ({ amount }) {
    return amount
  },
  paginationParam ({ paginationParam }): PaginationParam {
    return paginationParam
  }
}

const mutations: UserScoreMutationsTree = {
  setHistory (state, payload) {
    state.history.push(...payload)
  },
  setAmount (state, payload) {
    state.amount = payload
  },
  setPaginationParam (state, headers) {
    state.paginationParam = {
      currentPage: parseInt(headers.get('x-pagination-current-page'), 10),
      pageCount: parseInt(headers.get('x-pagination-page-count'), 10),
      perPage: parseInt(headers.get('x-pagination-per-page'), 10),
      totalCount: parseInt(headers.get('x-pagination-total-count'), 10)
    }
  },
  logout (state) {
    state.history = []
  }
}

/* istanbul ignore next */
const actions: UserScoreActionsTree = {
  history ({ commit }, payload) {
    return UserScoreApi.history(payload)
      .then(({ data: userScore, headers: userPointHeaders }) => {
        commit('setHistory', userScore)
        commit('setPaginationParam', userPointHeaders)
        return userScore
      })
  },
  amount ({ commit }) {
    return UserScoreApi.amount()
      .then(({ data: amount }) => {
        commit('setAmount', amount)

        return amount
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
// eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
