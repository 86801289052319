import FaqApi from '@web/api/modules/Faq'

import {
  FaqState,
  FaqGettersTree,
  FaqMutationsTree,
  FaqActionsTree
} from '@web/store/types/modules/faq'

const state: FaqState = {
  categories: [],
  isLoadedAll: false
}

const getters: FaqGettersTree = {
  categories: ({ categories }) => Object.values(categories).sort((a, b) => a.sort - b.sort),
  getCategoryById: ({ categories }) => categoryId => categories.find(({ id }) => id === categoryId),
  isLoadedAll: ({ isLoadedAll }) => isLoadedAll
}

const mutations: FaqMutationsTree = {
  setCategories (state, categories) {
    state.categories = categories
  },
  setStatusLoadAll (state, status) {
    state.isLoadedAll = status
  },
  logout (state) {
    state.categories = []
    state.isLoadedAll = false
  }
}

/* istanbul ignore next */
const actions: FaqActionsTree = {
  fetchCategories ({ commit }) {
    return FaqApi.fetchCategories()
      .then(({ data }) => {
        commit('setCategories', data)
        commit('setStatusLoadAll', true)
        return data
      })
      .catch(e => {
        throw e
      })
  },
  fetchCategoriesCached ({ getters, dispatch }) {
    if (!getters.isLoadedAll) {
      return dispatch('fetchCategories')
    }
  },
  sendTechQuestionAnonymous (_, payload) {
    return FaqApi.sendTechQuestionAnonymous(payload)
  },
  sendTechQuestion (_, payload) {
    return FaqApi.sendTechQuestion(payload)
  },
  sendTechQuestionAuto (_, payload) {
    return FaqApi.sendTechQuestionAuto(payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
