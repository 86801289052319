import CourseApi from '@web/api/modules/Course'

import {
  CourseState,
  CourseGettersTree,
  CourseActionsTree,
  CourseMutationsTree
} from '@web/store/types/modules/course'

const state: CourseState = {
  all: {},
  stageIds: new Map(),
  offerIds: new Map()
}

const getters: CourseGettersTree = {
  courses: ({ all }) => {
    const courses = {}
    Object.values(all).forEach((course) => {
      const tags: Array<string> = []
      if (course.category) {
        tags.push(course.category)
      }
      if (course.complexity) {
        tags.push(course.complexity)
      }

      courses[course.id] = {
        ...course,
        tags
      }
    })
    return courses
  },

  getCourseById: (_, { courses }) => id => courses[id],

  getCourseStageIds: state => courseId => state.stageIds.get(courseId) || [],

  getOfferByCourseIdAndOfferTypeId: (state, getters, rootState, rootGetters) => (courseId, offerTypeId) => {
    const courseOfferIds = state.offerIds.get(courseId)
    if (!courseOfferIds || !courseOfferIds.length) {
      return undefined
    }
    const offerIds = rootGetters['offer/getOfferIdsByRelation'](offerTypeId)
    if (!Array.isArray(offerIds) || !offerIds.length) {
      return undefined
    }

    const offerId = courseOfferIds.find(id => offerIds.includes(id))
    if (!offerId) {
      return undefined
    }

    return rootGetters['offer/getOfferById'](offerId)
  }
}

const mutations: CourseMutationsTree = {
  receiveCourse (state, course) {
    state.all[course.id] = course
  },

  addStages (state: CourseState, { courseId, stageIds }) {
    state.stageIds.set(courseId, stageIds)
  },

  addOffers (state, { courseId, offerIds }) {
    state.offerIds.set(courseId, offerIds)
  },

  logout (state) {
    state.all = {}
  }
}

/* istanbul ignore next */
const actions: CourseActionsTree = {
  setCourse: ({ commit }, course) => {
    commit('receiveCourse', course)
  },
  setData: async ({ commit }, data) => {
    const course: Course = data.course

    commit('receiveCourse', course)

    if (data.stages.length) {
      commit('stage/receiveStages', data.stages, { root: true })
      const stageIds = data.stages.map(s => s.id)
      commit('stage/receiveCourseStages', { courseId: course.id, stageIds }, { root: true })
      commit('course/addStages', { courseId: course.id, stageIds }, { root: true })
    }

    if (data.days.length) {
      commit('day/receiveDays', data.days, { root: true })
    }

    if (data.dayHomeworks.length) {
      commit('homework/addDayHomeworks', data.dayHomeworks, { root: true })
    }

    if (data.lessons.length) {
      commit('lesson/receiveLessons', data.lessons, { root: true })
    }

    if (data.videos.length) {
      commit('video/addVideos', data.videos, { root: true })
    }

    if (data.stageHomeworks.length) {
      commit('homework/addStageHomeworks', data.stageHomeworks, { root: true })
    }

    if (data.homeworks.length) {
      commit('homework/addHomeworks', data.homeworks, { root: true })
    }

    return course
  },

  fetchCourses ({ dispatch }, options) {
    return CourseApi.fetchAll(options)
      .then(response => {
        return response.data.forEach(data => {
          return dispatch('setData', data)
        })
      })
  },

  fetchCourse ({ dispatch }, { id, ...options }) {
    return CourseApi.fetch({ id, ...options })
      .then(response => dispatch('setData', response.data))
  },

  fetchCourseCached ({ getters, dispatch }, { id, ...options }) {
    if (!getters.courses[id]) {
      return dispatch('fetchCourse', { id, ...options })
    }
    return getters.courses[id]
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
// eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
