/* istanbul ignore file */
import tingle from 'tingle.js'
import 'tingle.js/dist/tingle.css'

function init (): void {
  document.body.addEventListener('click', (e: MouseEvent) => {
    const target = e.target as HTMLLinkElement
    if (target.hasAttribute('data-tingle')) {
      e.stopPropagation()
      e.preventDefault()

      const type = target.dataset.tingleType || 'video'

      // eslint-disable-next-line new-cap
      const modalSurprise = new tingle.modal({
        cssClass: [type],
        onClose () {
          modalSurprise.destroy()
        }
      })
      const src = target.dataset.iframe || target.href
      modalSurprise.setContent('<iframe style="width: 100%; height: 70vh;" src="' + src + '" frameborder="0" allowfullscreen></iframe>')
      modalSurprise.open()
    }
  })
}

document.addEventListener('DOMContentLoaded', init)
