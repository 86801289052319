/* istanbul ignore file */
import client from '../client'
import ScheduleMapper from '@web/mapper/ScheduleMapper'
import HeaderMapper from '@web/mapper/HeaderMapper'
import { Schedule as ScheduleType } from '@web/store/types/modules/schedule'

export default class Schedule {
  static fetch () {
    return client.get('/schedule')
      .then((response) => {
        return {
          data: ScheduleMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static update (schedule: Dictionary<ScheduleType>) {
    return client.put('/schedule/update', ScheduleMapper.toServer(schedule))
      .then((response) => {
        return {
          data: ScheduleMapper.toClient(response.data.repeatDays),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }
}
