const HOMEWORK_STATUS: {
  [id: string]: homeworkStatus;
} = {
  NOT_UPLOADED: 'NOT_UPLOADED',
  UPLOADED: 'UPLOADED',
  DONE: 'DONE',
  FAIL: 'FAIL'
}

export default HOMEWORK_STATUS
