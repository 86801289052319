import Cookies from 'js-cookie'
import LocalStorage from '@web/common/LocalStorage'
import Logger from '@web/common/Logger'
import Swal from 'sweetalert2'
import { APP_ID, APP_IDS, IS_MOBILE } from '@web/common/config'
import isEqual from 'lodash.isequal'
import isEmpty from 'lodash.isempty'
import queryParse from 'query-parse'

const STORE_KEY = 'acProject_locale'
const STORE_KEY_DEFAULT = 'acProject_locale_default'
const STORE_KEY_SUPPORTED_LANGUAGE = 'acProject_locale_SUPPORTED_LANGUAGE'

const getLangCode = (str: string): string => str.slice(0, 2).toLowerCase()
const BROWSER_LANG = getLangCode(window.navigator.language)

let SUPPORTED_LANGUAGE: string[] = ['en', 'ru']

// set default lang from query param "lang"
const queryLocale = queryParse.toObject(location.search.replace('?', ''))

export const LANG_LIST = {
  ru: 'Русский',
  en: 'English',
  es: 'Español'
}

export let availableLang = {}

export default {
  set SUPPORTED_LANGUAGE (langs: string[]) {
    LocalStorage.set(STORE_KEY_SUPPORTED_LANGUAGE, langs.join(','))
    Cookies.set(STORE_KEY_SUPPORTED_LANGUAGE, langs.join(','), { sameSite: 'strict' })
    SUPPORTED_LANGUAGE = langs
  },

  get SUPPORTED_LANGUAGE (): string[] {
    const str: string | undefined = LocalStorage.get(STORE_KEY_SUPPORTED_LANGUAGE) || Cookies.get(STORE_KEY_SUPPORTED_LANGUAGE)
    if (str) {
      SUPPORTED_LANGUAGE = str.split(',')
    }

    return SUPPORTED_LANGUAGE
  },

  set (lang: string): void {
    lang = this.getLangCode(lang)
    if (!this.SUPPORTED_LANGUAGE.includes(lang)) {
      throw new Error('Can\'t set locale unsupported locale')
    }

    Cookies.set(STORE_KEY, lang, { sameSite: 'strict' })
    LocalStorage.set(STORE_KEY, lang)
    document.documentElement.lang = lang
  },

  get (): string {
    let locale = LocalStorage.get(STORE_KEY) || Cookies.get(STORE_KEY)

    if (!locale) {
      locale = this.getDefault()
    }

    if (APP_ID === APP_IDS.TAKECLASS) {
      return 'en'
    }

    if (IS_MOBILE) {
      return 'en'
    }

    return this.SUPPORTED_LANGUAGE.includes(locale) ? locale : this.SUPPORTED_LANGUAGE[0]
  },

  setDefault (lang: string): void {
    lang = this.getLangCode(lang)
    if (!this.SUPPORTED_LANGUAGE.includes(lang)) {
      return Logger.warn('Can\'t set locale unsupported locale')
    }

    Cookies.set(STORE_KEY_DEFAULT, lang, { sameSite: 'strict' })
    LocalStorage.set(STORE_KEY_DEFAULT, lang)
    document.documentElement.lang = lang
  },

  getDefault (): string {
    let locale = queryLocale?.lang

    if (!locale) {
      locale = this.SUPPORTED_LANGUAGE.includes(BROWSER_LANG) ? BROWSER_LANG : ''
    }

    if (!locale) {
      locale = LocalStorage.get(STORE_KEY_DEFAULT) || Cookies.get(STORE_KEY_DEFAULT)
    }

    return locale || process.env.VUE_APP_I18N_LOCALE || 'en'
  },

  getLangCode,
  showChangeLanguageModal (lang?: string): Promise<unknown> {
    const locale = lang ? this.getLangCode(lang) : this.get()
    const translates = {
      ru: {
        title: 'Для переключения языка на русский требуется перезагрузка страницы. Нажмите "Да" для перезагрузки или интерфейс останется прежнем языке.',
        yes: 'Да',
        no: 'Нет'
      },
      en: {
        title: 'To switch the language to English, you need to reload the page. Click "Yes" to restart or the interface will remain the same',
        yes: 'Yes',
        no: 'No'
      },
      es: {
        title: 'Para cambiar el idioma al español, es necesario volver a cargar la página. Haga clic en "Sí" para reiniciar o la interfaz seguirá siendo el mismo idioma',
        yes: 'Sí',
        no: 'No hay'
      }
    }
    return Swal.fire({
      title: translates[locale].title,
      confirmButtonText: translates[locale].yes,
      cancelButtonText: translates[locale].no,
      showCancelButton: true,
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        Promise.resolve()
      } else {
        Promise.reject(new Error('Cancel'))
      }
    })
  },
  checkLang (lang: string): void {
    const locale = this.getLangCode(lang)
    if (locale && locale !== this.get() && this.SUPPORTED_LANGUAGE.includes(locale)) {
      this.set(locale)
      this.reloadPage(true)
    }
  },
  reloadPage (force = false): void {
    const KEY = 'lang_reloaded'
    const url = new URL(window.location.href)
    if (!url.searchParams.has(KEY) || force) {
      url.searchParams.set(KEY, 'true')
      window.location.href = url.toString()
    }
  },
  init ({ supportedLanguage, defaultLanguage }: { supportedLanguage: string[], defaultLanguage: string }): void {
    const isEqualLang = isEqual(supportedLanguage, this.SUPPORTED_LANGUAGE)
    if (isEmpty(supportedLanguage)) {
      Logger.warn('У домена не заданы поддерживаемые языки')
    } else {
      this.SUPPORTED_LANGUAGE = supportedLanguage
    }

    availableLang = Object.keys(LANG_LIST).reduce((acc, lang) => {
      if (supportedLanguage.includes(lang)) {
        return {
          ...acc,
          [lang]: LANG_LIST[lang]
        }
      }

      return acc
    }, {})

    document.documentElement.lang = this.get()
    if (this.getDefault() !== this.getLangCode(defaultLanguage)) {
      this.setDefault(defaultLanguage)
    }

    if (!isEqualLang && !isEmpty(supportedLanguage)) {
      this.reloadPage()
    }
  }
}
