/* istanbul ignore file */
import client from '../client'
import FaqMapper from '@web/mapper/FaqMapper'
import HeaderMapper from '@web/mapper/HeaderMapper'

export default class Faq {
  static fetchCategories () {
    return client.get('/support-category', {
      params: {
        sort: 'id'
      }
    })
      .then((response) => {
        return {
          data: response.data.map(FaqMapper.toClient),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static sendTechQuestion ({ message, supportCategoryId, targetId, name }) {
    return client.post('/tech-questions', {
      target_id: targetId,
      support_category_id: supportCategoryId,
      name,
      message
    })
      .then((response) => {
        return {
          data: FaqMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static sendTechQuestionAuto ({ targetId, supportCategoryId, name }) {
    return client.post('/tech-questions/auto', {
      target_id: targetId,
      support_category_id: supportCategoryId,
      name
    })
      .then((response) => {
        return {
          data: FaqMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static sendTechQuestionAnonymous ({ message, name, email, supportCategoryId }: { message: string; email: string; name: string; supportCategoryId?: number; }) {
    return client.post('/tech-questions/anonymous', {
      email,
      name,
      message,
      support_category_id: supportCategoryId
    })
      .then((response) => {
        return {
          data: FaqMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }
}
