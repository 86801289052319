/* istanbul ignore file */
import client from '../client'
import BannerMapper from '@web/mapper/BannerMapper'
import HeaderMapper from '@web/mapper/HeaderMapper'

export default class Banner {
  static fetchAll () {
    return client.get('/banners')
      .then((response) => {
        return {
          data: response.data.map(BannerMapper.toClient),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static fetchNewFeatures (position: number) {
    return client.get('/banner/new-features', {
      params: {
        position
      }
    })
      .then((response) => {
        return {
          data: BannerMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }
}
