import {
  createStore,
  Store as VuexStore,
  CommitOptions,
  DispatchOptions
} from 'vuex'

import State from '@web/store/types/index'
import Getters from '@web/store/types/getters'
import Mutations from '@web/store/types/mutations'
import Actions from '@web/store/types/actions'

import main from './modules/main'
import user from './modules/user'
import userScore from './modules/userScore'
import assets from './modules/assets'
import catalog from './modules/catalog'
import cookie from './modules/cookie'
import course from './modules/course'
import menu from './modules/menu'
import homework from './modules/homework'
import consultation from './modules/consultation'
import stage from './modules/stage'
import day from './modules/day'
import chat from './modules/chat'
import stream from './modules/stream'
import banner from './modules/banner'
import router from './modules/router'
import lesson from './modules/lesson'
import extend from './modules/extend'
import faq from './modules/faq'
import video from './modules/video'
import file from './modules/file'
import videoConsultation from './modules/videoConsultation'
import schedule from './modules/schedule'
import offer from './modules/offer'
import service from './modules/service'
import appStore from './modules/appStore'
import article from '@web/store/modules/article'
import community from '@web/store/modules/community'
import subscription from '@web/store/modules/subscription'
import installments from '@web/store/modules/installments'
import { IS_MOBILE } from '@web/common/config'

const isDebug: boolean = process.env.NODE_ENV !== 'production'

export type Store = Omit<
  VuexStore<State>,
  'getters' | 'commit' | 'dispatch'
  > & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[0]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[0],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
} & {
  readonly getters: {
    [K in keyof Getters]: Getters[K];
  };
}

export const store = createStore<State>({
  modules: {
    main,
    assets,
    user,
    userScore,
    catalog,
    cookie,
    course,
    menu,
    homework,
    consultation,
    stage,
    day,
    chat,
    stream,
    banner,
    router,
    lesson,
    extend,
    faq,
    video,
    file: IS_MOBILE ? file : {},
    videoConsultation,
    schedule,
    offer,
    service,
    appStore,
    article,
    community,
    subscription,
    installments
  },
  strict: isDebug
})

export function useStore (): Store {
  return store as Store
}
