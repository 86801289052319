import { AppStoreGettersTree, AppStoreMutationsTree, AppStoreState } from '@web/store/types/modules/appStore'
import clonedeep from 'lodash.clonedeep'

const state: AppStoreState = {
  all: {}
}

const getters: AppStoreGettersTree = {
  getProductById: state => id => state.all[id]
}

const mutations: AppStoreMutationsTree = {
  receive (state: AppStoreState, payload) {
    if (payload.alias === 'application') {
      return
    }
    state.all[payload.id] = clonedeep(payload)
  },
  remove (state, payload) {
    if (state.all[payload.id]) {
      delete state.all[payload.id]
      return true
    } else {
      return false
    }
  },
  logout (state) {
    state.all = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
