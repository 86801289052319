import HomeworkApi from '@web/api/modules/Homework'
import STAGE_STATUS from '@web/consts/StageStatus'

import {
  HomeworkState,
  HomeworkGettersTree,
  HomeworkMutationsTree,
  HomeworkActionsTree
} from '@web/store/types/modules/homework'
import HOMEWORK_STATUS from '@web/consts/HomeworkStatus'
import { Stream } from '@web/store/types/modules/stream'
import keyBy from 'lodash.keyby'
import { isDefined } from 'ts-is-present'

const getDefaultStatus = (targetId: number, targetType: homeworkTypes, streamId: number): HomeworkStatus => {
  return {
    id: Math.floor(Math.random() * 1000 + 1000),
    targetId,
    targetType,
    status: HOMEWORK_STATUS.NOT_UPLOADED,
    streamId,
    createdAt: Date.now(),
    updatedAt: Date.now(),
    isLocked: false
  }
}

const state: HomeworkState = {
  all: {},
  statuses: {},
  dayHomeworks: {},
  stageHomeworks: {}
}

const getters: HomeworkGettersTree = {
  statuses: (state, getters) => Object.values(state.statuses).map(({ id }) => getters.getHomeworkStatus(id)).filter(isDefined),
  getHomeworkStatus: ({ statuses }, getters, rootState, rootGetters) => (id: number): HomeworkStatus | undefined => {
    let isLocked = false
    const status = statuses[id]
    if (!status) {
      return undefined
    }

    const stream = rootGetters['stream/getStreamById'](status.streamId)
    if (stream && !stream.courseFullAccess && stream.isDemo) {
      if (status.targetType === 'course_stage_homework') {
        const stageHomework = state.stageHomeworks[status.targetId]
        if (stageHomework) {
          isLocked = !stageHomework.isDemoAccessible
        }
      } else if (status.targetType === 'stage_day_homework') {
        const dayHomework = state.dayHomeworks[status.targetId]
        if (dayHomework) {
          isLocked = !dayHomework.isDemoAccessible
        }
      }
    }

    return {
      ...status,
      isLocked
    }
  },
  getHomeworkByStatusId: (state, getters) => (statusId: number) => {
    const status = getters.getHomeworkStatus(statusId)

    let homework
    let stageHomework
    let dayHomework

    if (status) {
      if (status.targetType === 'course_stage_homework') {
        stageHomework = state.stageHomeworks[status.targetId]
        if (stageHomework) {
          homework = state.all[stageHomework.homeworkId]
        }
      } else if (status.targetType === 'stage_day_homework') {
        dayHomework = state.dayHomeworks[status.targetId]
        if (dayHomework) {
          homework = state.all[dayHomework.homeworkId]
        }
      }
    }
    return {
      status,
      homework,
      stageHomework,
      dayHomework
    }
  },
  getHomeworkStatusByTargetIdAndStreamId: (state, getters) => (targetId: number, streamId: number) => {
    const status = Object.values(state.statuses).find(s => s.targetId === targetId && s.streamId === streamId)
    return status ? getters.getHomeworkStatus(status.id) : undefined
  },
  getHomeworkAvailableByStreamId: (state, getters, rootState, rootGetters) => streamId => {
    const stream: Stream | undefined = rootGetters['stream/getStreamById'](streamId)
    if (!stream) {
      return []
    }
    const homeworks: {
      dayHomework?: DayHomework;
      stageHomework?: StageHomework;
      homework: Homework;
      status: HomeworkStatus;
    }[] = []

    const course = rootGetters['course/getCourseById'](stream.courseId)
    if (!course) {
      return []
    }
    const getStageById: (id: number) => Stage | undefined = rootGetters['stage/getStageById']
    const stageIds: number[] = rootGetters['course/getCourseStageIds'](course.id)
    const stages = stageIds
      .map(getStageById)
      .filter(isDefined)
      .sort((a, b) => a.sort - b.sort)

    stages.forEach(stage => {
      const stageStatus = rootGetters['stream/getStageStatus']({ streamId: stream.id, stageId: stage.id })
      if (stageStatus?.status === STAGE_STATUS.CLOSE) {
        return
      }
      getters.getHomeworksByStageIdAndStreamId(stage.id, stream.id)
        .forEach(el => {
          homeworks.push(el)
        })
      stage.dayIds.forEach(dayId => {
        getters.getHomeworksByDayIdAndStreamId(dayId, stream.id)
          .forEach(el => {
            homeworks.push(el)
          })
      })
    })

    return homeworks
  },
  getHomeworkAvailableGroupedByStagesByStreamId: (state, getters, rootState, rootGetters) => streamId => {
    const stream = rootGetters['stream/getStreamById'](streamId)
    if (!stream) {
      return []
    }
    const stagesWithHomeworks: {
      stage: Stage;
      stageStatus: StageStatus;
      homeworks: {
        dayHomework?: DayHomework;
        stageHomework?: StageHomework;
        homework: Homework;
        status: HomeworkStatus;
      }[];
    }[] = []

    const course = rootGetters['course/getCourseById'](stream.courseId)
    if (!course) {
      return []
    }
    const getStageById: (id: number) => Stage | undefined = rootGetters['stage/getStageById']
    const stageIds: number[] = rootGetters['course/getCourseStageIds'](course.id)
    const stages = stageIds
      .map(getStageById)
      .filter(isDefined)
      .sort((a, b) => a.sort - b.sort)

    stages.forEach(stage => {
      const stageStatus = rootGetters['stream/getStageStatus']({ streamId: stream.id, stageId: stage.id })

      if (!stageStatus) {
        return
      }

      stagesWithHomeworks.push({
        stage,
        stageStatus,
        homeworks: [
          ...getters.getHomeworksByStageIdAndStreamId(stage.id, stream.id),
          ...stage.dayIds
            .map(dayId => {
              return getters.getHomeworksByDayIdAndStreamId(dayId, stream.id)
            })
            .flat()
        ]
      })
    })

    return stagesWithHomeworks
  },
  homeworksAvailable (state, getters, rootState, rootGetters) {
    const streams = rootGetters['stream/streamsWithStatusOpen']
    const homeworks: Dictionary<{
      dayHomework?: DayHomework;
      stageHomework?: StageHomework;
      homework: Homework;
      status: HomeworkStatus;
    }> = {}

    streams.forEach(stream => {
      const course: Course | undefined = rootGetters['course/getCourseById'](stream.courseId)
      if (!course) {
        return []
      }
      const getStageById: (id: number) => Stage | undefined = rootGetters['stage/getStageById']
      const stageIds: number[] = rootGetters['course/getCourseStageIds'](course.id)
      const stages = stageIds
        .map(getStageById)
        .filter(isDefined)
        .sort((a, b) => a.sort - b.sort)
      stages.forEach(stage => {
        const stageStatus = rootGetters['stream/getStageStatus']({ streamId: stream.id, stageId: stage.id })
        if (stageStatus?.status === STAGE_STATUS.CLOSE) {
          return
        }
        getters.getHomeworksByStageIdAndStreamId(stage.id, stream.id)
          .forEach(el => {
            homeworks[el.status.id] = el
          })
        stage.dayIds.forEach(dayId => {
          getters.getHomeworksByDayIdAndStreamId(dayId, stream.id)
            .forEach(el => {
              homeworks[el.status.id] = el
            })
        })
      })
    })

    return homeworks
  },
  homeworksGrouped (state, { homeworksAvailable: homeworks }, rootState, rootGetters) {
    const homeworkGrouped = {}
    for (const id in homeworks) {
      if (!Object.prototype.hasOwnProperty.call(homeworks, id)) continue
      const homework = homeworks[id]
      if (!homeworkGrouped[homework.status.streamId]) {
        const getStreamById = rootGetters['stream/getStreamById']
        const stream = getStreamById(homework.status.streamId)
        if (!stream) {
          return []
        }
        const getCourseById = rootGetters['course/getCourseById']
        const course = getCourseById(stream.courseId)
        homeworkGrouped[stream.id] = {
          isDemo: stream.isDemo,
          caption: course?.caption || '',
          items: []
        }
      }

      homeworkGrouped[homework.status.streamId].items.push({
        homework: homework.homework,
        status: homework.status
      })
    }

    return homeworkGrouped
  },
  getHomeworksByDayIdAndStreamId: (state, getters, rootState, rootGetters) => (dayId: number, streamId: number): {
    homework: Homework;
    dayHomework: DayHomework;
    status: HomeworkStatus;
  }[] => {
    const day: Day | undefined = rootGetters['day/getDayById'](dayId)
    if (!day) {
      return []
    }

    return day.dayHomeworkIds.map(id => {
      const dayHomework = state.dayHomeworks[id]
      const homework = state.all[dayHomework.homeworkId]

      let status = Object.values(state.statuses)
        .find(s => s.streamId === streamId && s.targetId === id && s.targetType === 'stage_day_homework')

      if (status) {
        status = getters.getHomeworkStatus(status.id)
      }

      if (!status) {
        status = getDefaultStatus(dayHomework.id, 'stage_day_homework', streamId)
      }

      return {
        dayHomework,
        homework,
        status
      }
    })
  },
  getHomeworkStatusesByDayIdAndStreamId: (_, { getHomeworksByDayIdAndStreamId }) => (dayId: number, streamId: number): HomeworkStatus[] => {
    return getHomeworksByDayIdAndStreamId(dayId, streamId).map(({ status }) => status)
  },
  getHomeworksByStageIdAndStreamId: (state, getters, rootState, rootGetters) => (stageId: number, streamId: number) => {
    const stage = rootGetters['stage/getStageById'](stageId)
    if (!stage) {
      return []
    }
    return stage.stageHomeworkIds?.map(id => {
      const stageHomework = state.stageHomeworks[id]
      const homework = state.all[stageHomework.homeworkId]

      let status = Object.values(state.statuses)
        .find(s => s.streamId === streamId && s.targetId === id && s.targetType === 'course_stage_homework')

      if (status) {
        status = getters.getHomeworkStatus(status.id)
      }

      if (!status) {
        status = getDefaultStatus(stageHomework.id, 'course_stage_homework', streamId)
      }

      return {
        stageHomework,
        homework,
        status
      }
    })
  },
  getHomeworkById: ({ all }) => id => all[id],
  getStageHomeworkById: ({ stageHomeworks }) => id => stageHomeworks[id],
  getDayHomeworkById: ({ dayHomeworks }) => id => dayHomeworks[id]
}

const mutations: HomeworkMutationsTree = {
  addHomework (state, homework) {
    state.all[homework.id] = homework
  },

  addHomeworks (state, homeworks) {
    state.all = {
      ...state.all,
      ...keyBy(homeworks, 'id')
    }
  },

  addDayHomework (state, dayHomework) {
    state.dayHomeworks[dayHomework.id] = dayHomework
  },

  addDayHomeworks (state, dayHomeworks) {
    state.dayHomeworks = {
      ...state.dayHomeworks,
      ...keyBy(dayHomeworks, 'id')
    }
  },

  receiveHomework (state, homework) {
    if (!state.all) {
      state.all = {}
    }

    state.all[homework.id] = {
      ...state.all[homework.id],
      ...homework
    }
  },

  addStageHomework (state, stageHomework) {
    state.stageHomeworks[stageHomework.id] = stageHomework
  },

  addStageHomeworks (state, stageHomeworks) {
    state.stageHomeworks = {
      ...state.stageHomeworks,
      ...keyBy(stageHomeworks, 'id')
    }
  },

  receiveStatuses (state, { statuses }) {
    state.statuses = {
      ...state.statuses,
      ...keyBy(statuses, 'id')
    }
  },

  setHomeworkStatus (state, { streamId, id, targetId, status }) {
    state.statuses[id] = {
      ...state.statuses[id],
      id,
      status,
      streamId,
      targetId
    }
  },

  logout (state) {
    state.all = {}
    state.stageHomeworks = {}
    state.dayHomeworks = {}
    state.statuses = {}
  }
}

/* istanbul ignore next */
const actions: HomeworkActionsTree = {
  fetchStatuses ({ commit }, streamId) {
    return HomeworkApi.fetchStatuses(streamId)
      .then(({ data: statuses }) => {
        commit('receiveStatuses', { statuses })
      })
  },

  fetchHomeworks () {
    return HomeworkApi.fetchHomeworks()
      .then(({ data }) => data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
