import Mapper from './Mapper'

type FileServe = Omit<File, 'type'> & {
  type: number;
}

/* istanbul ignore next */
export default class FileMapper extends Mapper {
  static toClient (data): File {
    return Mapper.converter({
      data,
      rules: {
        debug: true,
        url: {
          baseUrl: true
        },
        urls: {
          cb: urls => FileMapper.converter({
            data: urls,
            rules: {
              large: {
                baseUrl: true
              },
              medium: {
                baseUrl: true
              },
              small: {
                baseUrl: true
              }
            }
          })
        }
      }
    }) as File
  }

  static toServer (data): FileServe {
    const file = { ...data } as FileServe

    if (data.type) {
      switch (data.type) {
        case 'video':
          file.type = 1
          break
        case 'image':
          file.type = 2
          break
        case 'sound':
          file.type = 3
          break
        case 'subtitle':
          file.type = 5
          break
        case 'document':
        default:
          file.type = 4
          break
      }
    }

    return file
  }
}
