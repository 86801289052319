import Mapper from './Mapper'

interface DataToServer {
  message?: string;
  userId: number;
  dialogId: number;
  files?: number[];
  comment: string;
}

/* istanbul ignore next */
export default class HomeworkMapper {
  static toClient (data): {
    dayHomework: DayHomework;
    homework: Homework;
  } {
    let homework: Homework = {} as Homework

    const dayHomework = Mapper.converter({
      data,
      rules: {
        // eslint-disable-next-line camelcase
        created_at: {
          cb: value => value * 1000
        },
        // eslint-disable-next-line camelcase
        updated_at: {
          cb: value => value * 1000
        },
        // eslint-disable-next-line camelcase
        is_required: {
          cb: value => value > 0
        },
        homework: {
          cb: h => {
            homework = Mapper.toClient(h) as Homework
          }
        }
      },
      defaults: {
        homeworkId: data.homework.id
      }
    }) as DayHomework

    delete dayHomework.homework

    const scores = homework.scores
    for (const s in scores) {
      scores[s] = Mapper.toClient(scores[s]) as Score
    }
    homework.scores = scores

    return {
      dayHomework,
      homework
    }
  }

  static stageHomework (data): {
    stageHomework: StageHomework;
    homework: Homework;
  } {
    let homework: Homework = {} as Homework

    const stageHomework: StageHomework = Mapper.converter({
      data,
      rules: {
        // eslint-disable-next-line camelcase
        created_at: {
          cb: value => value * 1000
        },
        // eslint-disable-next-line camelcase
        updated_at: {
          cb: value => value * 1000
        },
        // eslint-disable-next-line camelcase
        is_required: {
          cb: value => value > 0
        },
        homework: {
          rename: 'homeworkId',
          cb: h => {
            homework = Mapper.toClient(h) as Homework
            return homework.id
          }
        }
      }
    }) as StageHomework

    delete stageHomework.homework

    const scores = homework.scores
    for (const s in scores) {
      scores[s] = Mapper.toClient(scores[s]) as Score
    }
    homework.scores = scores

    return {
      stageHomework,
      homework
    }
  }

  static toServer (data) {
    return Mapper.converter({
      data,
      rules: {
        userId: {
          rename: 'user_id'
        },
        dialogId: {
          rename: 'dialog_id'
        }
      }
    }) as DataToServer
  }
}
