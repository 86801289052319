import UserApi from '@web/api/modules/User'
import isEmpty from 'lodash.isempty'
import UserMapper from '@web/mapper/UserMapper'
import {
  UserState,
  UserGettersTree,
  UserMutationsTree,
  UserActionsTree, User
} from '@web/store/types/modules/user'
import Locale from '@web/common/locale'
import InvalidParamError from '@web/common/errors/InvalidParamError'
import { APP_ID } from '@web/common/config'
import md5 from 'locutus/php/strings/md5'
import { dayjs } from '@web/common/Time'

const state: UserState = {
  // TODO: до авторизации сделать чтобы был null
  user: {} as User,
  notice: ''
}

const getters: UserGettersTree = {
  user ({ user }) {
    return user
  },
  username ({ user }) {
    if (isEmpty(user)) {
      return ''
    }
    if (!user.firstName && !user.lastName) {
      return user.email || ''
    }
    return `${user.firstName || ''} ${user.lastName || ''}`.trim()
  },
  email ({ user }) {
    return user.email || ''
  },
  statistic ({ user }) {
    const fields = ['viewedHours', 'completedCourses', 'completedTasks', 'viewedLessons', 'referralOrders', 'cashback', 'discountForReferral', 'linkJumps']
    const statistic = {}
    fields.forEach(key => {
      statistic[key] = user[key]
    })
    return statistic
  },
  notice: ({ notice }) => notice,
  isLogged: ({ user }) => !isEmpty(user)
}

const mutations: UserMutationsTree = {
  setUser (state, payload) {
    state.user = payload
  },
  setField (state, { key, value }) {
    state.user = {
      ...state.user,
      [key]: value
    }
  },
  setNotice (state, notice) {
    state.notice = notice
  },
  logout (state) {
    state.user = {} as User
    state.notice = ''
  }
}

/* istanbul ignore next */
const actions: UserActionsTree = {
  login ({ commit }, credentials) {
    return UserApi.login(credentials)
      .then(({ data: user }) => {
        commit('setUser', user)
        if (user?.languageCode) {
          Locale.checkLang(user.languageCode)
        }
        return user
      })
  },
  signUp ({ commit }, credentials) {
    const timezone = dayjs.tz.guess()
    return UserApi.create({ email: credentials.email, password: credentials.password, timezone })
      .then(({ data: user }) => {
        commit('setUser', user)
        return Promise.resolve(user)
      })
  },
  fetch ({ commit }) {
    return UserApi.request()
      .then(({ data: user }) => {
        commit('setUser', user)
        if (user?.languageCode) {
          Locale.checkLang(user.languageCode)
        }
        return user
      })
  },
  update ({ commit }, user) {
    return UserApi.update(user)
      .then(({ data: user }) => {
        commit('setUser', user)
        return user
      })
  },
  changeLanguage ({ commit }, { locale }) {
    Locale.set(locale)
    return UserApi.update({ locale } as Partial<User>)
      .then(({ data: user }) => {
        commit('setUser', user)
        return user
      })
  },
  notification ({ commit }, user) {
    return UserApi.notification(user)
      .then(({ data: user }) => {
        commit('setUser', user)
        return user
      })
  },
  avatar ({ commit }, user) {
    return UserApi.avatar(UserMapper.toServer(user))
      .then(({ data: user }) => {
        commit('setUser', user)
        return user
      })
  },
  fetchExternalAuth ({ getters, commit }) {
    return UserApi.fetchExternalAuth()
      .then(({ data: userExternalAuths }) => {
        const user = {
          ...getters.user,
          userExternalAuths
        }
        commit('setUser', user)
        return user
      })
  },
  deleteExternalAuth ({ dispatch }, id) {
    if (!id) {
      throw new InvalidParamError('Id must be set')
    }

    return UserApi.deleteExternalAuth(id)
      .then(() => {
        return dispatch('fetchExternalAuth')
      })
  },
  logout ({ commit }) {
    commit('logout')
  },

  async loginByAppId ({ commit }, { appId, password }) {
    return UserApi.loginByAppId({ appId, password })
      .then(({ data: user }) => {
        commit('setUser', user)
        return user
      })
  },

  async signUpByAppId ({ commit }, { appId, password }) {
    return UserApi.createByAppId(appId, password)
      .then(({ data: user }) => {
        commit('setUser', user)
        return user
      })
  },

  async authUserByAppId ({ dispatch }, deviceId: string) {
    const login = md5(APP_ID + deviceId)
    const salt = APP_ID + '@52qse'
    const password = md5(login + salt)

    const isAppIdValid = await UserApi.validateAppId(login)
    const credentials = {
      appId: login,
      password
    }
    if (isAppIdValid) {
      return dispatch('loginByAppId', credentials)
    } else {
      return dispatch('signUpByAppId', credentials)
    }
  },

  async updateEmail ({ commit }, email) {
    return UserApi.updateEmail(email)
      .then(({ data }) => {
        commit('setUser', data)
        return data
      })
  },

  async updateStatus ({ commit }, status) {
    return UserApi.updateStatus(status)
      .then(({ data }) => {
        commit('setUser', data)
        return data
      })
  },

  async delete ({ getters }) {
    const user = getters.user
    UserApi.delete({
      ...user,
      status: 0
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
// eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
