
import { computed, defineComponent, ref, watch } from 'vue'

import { Form as VForm, Field as VField } from 'vee-validate'
import AsModal from '@web/components/AsModal.vue'
import AsInput from '@web/components/AsInput/AsInput.vue'
import AsTextarea from '@web/components/AsTextarea.vue'
import Logger from '@web/common/Logger'
import sentry from '@web/common/sentry'
import { useStore } from '@web/store'
import i18n from '@web/plugins/i18n'
import Swal from 'sweetalert2'
import useErrors from '@web/composition/useErrors'

export default defineComponent({
  components: {
    AsModal,
    AsInput,
    AsTextarea,
    VForm,
    VField
  },
  setup () {
    const store = useStore()
    const {
      getFirstError
    } = useErrors()

    const errorSend = ref('')
    const showModal = ref(false)
    const email = ref('')
    const username = ref('')
    const message = ref('')
    const sending = ref(false)
    const isDisabled = computed(() => !message.value.length || sending.value)
    const category = ref<{ label: string; value: number } | null>(null)
    watch(() => showModal.value, (showModal: boolean) => {
      if (!showModal) {
        sending.value = false
      }
    })

    async function submit () {
      errorSend.value = ''
      try {
        if (!message.value.length || !email.value.length || !username.value.length) return

        sending.value = true

        const data: {
          name: string,
          email: string,
          message: string,
          supportCategoryId?: number
        } = {
          name: username.value,
          email: email.value,
          message: message.value
        }

        if (category.value) {
          data.supportCategoryId = category.value.value
        }

        await store.dispatch('faq/sendTechQuestionAnonymous', data)
        message.value = ''
        showModal.value = false
        Swal.fire({
          icon: 'success',
          title: `${i18n.global.t('successfully')}!`,
          text: i18n.global.t('messageSent')
        })
      } catch (error) {
        Logger.log('error', error)
        sentry.captureException(error)
        Logger.error(error)
        errorSend.value = i18n.global.t('errorTryLoadData')
      } finally {
        sending.value = true
      }
    }

    return {
      getFirstError,
      errorSend,
      showModal,
      email,
      username,
      message,
      isDisabled,
      submit
    }
  }
})
