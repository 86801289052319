
import { defineComponent, ref, onMounted } from 'vue'

export default defineComponent({
  name: 'AsTextArea',
  props: {
    name: {
      type: String
    },
    label: {
      type: String
    },
    placeholder: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    note: {
      type: String,
      default: ''
    },
    appendIcon: {
      type: String,
      default: ''
    },
    maxlength: {
      type: [String, Number],
      default: ''
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    autoResizable: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'update:modelValue'],
  setup (props, ctx) {
    const inputRef = ref<HTMLInputElement | null>(null)

    function input (event) {
      if (event.target.innerHTML.trim() === '<br>') event.target.innerHTML = ''
      if (inputRef.value) {
        inputRef.value.setAttribute('value', event.target.value || event.target.innerText)
      }
      ctx.emit('update:modelValue', event.target.value || event.target.innerText)
    }

    function triggerFocus (e) {
      e.target.focus()
    }

    onMounted(() => {
      if (inputRef.value) {
        inputRef.value.setAttribute('value', props.modelValue.toString())
      }
    })

    return {
      inputRef,
      input,
      triggerFocus
    }
  }
})
