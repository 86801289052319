import { useRouter } from 'vue-router'
import { computed, ref } from 'vue'

interface Props {
  isModal: boolean;
  mobileAccess: boolean;
  isLocked: boolean;
  icon: boolean;
  doc: Document;
}
export const props = {
  isModal: {
    type: Boolean,
    default: true
  },
  mobileAccess: {
    type: Boolean,
    default: true
  },
  isLocked: {
    type: Boolean,
    default: false
  },
  icon: {
    type: Boolean,
    default: true
  },
  doc: {
    type: Object as () => Document,
    required: true
  }
}

function useExternalDocument (props: Props) {
  const router = useRouter()

  const showModal = ref(false)
  const isShowModal = computed(() => !props.isModal || showModal.value)
  const isPdf = computed(() => (props.doc?.file?.type === 'document' && props.doc?.file?.fileName.indexOf('.pdf') > -1) || props.doc?.url?.indexOf('.pdf') > -1)

  function isApple () {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform) ||
      navigator.userAgent.includes('iPad') ||
      navigator.userAgent.includes('iPhone') ||
      navigator.userAgent.includes('iPod')
  }

  function handlerClick () {
    if (isApple() && props.doc.url) {
      window.open(props.doc.url, '_target')
      return
    }

    if (props.isModal) {
      showModal.value = !showModal.value
    } else if (props.doc.url) {
      const route = router.resolve({
        name: 'document',
        query: {
          url: props.doc.url
        }
      })

      window.open(route.href, '_system')
    }
  }

  return {
    showModal,
    isShowModal,
    isPdf,
    handlerClick
  }
}

export default useExternalDocument
