import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, normalizeClass as _normalizeClass, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "asModal"
}
const _hoisted_2 = {
  key: 1,
  class: "asModal_title"
}
const _hoisted_3 = { class: "asModal_inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "#modals" }, [
    (_ctx.modelValue)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "asModal_overlay",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onBackdropClose && _ctx.onBackdropClose(...args)))
          }),
          _createElementVNode("div", {
            class: _normalizeClass(["asModal_content", `-${_ctx.typeModal}`])
          }, [
            (_ctx.btnClose)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "asModal_close",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
                }))
              : _createCommentVNode("", true),
            (_ctx.title)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.title), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "default")
            ])
          ], 2)
        ]))
      : _createCommentVNode("", true)
  ]))
}