
import { defineComponent } from 'vue'
import AcModal from '@ui-kit/components/AcModal.vue'

export default defineComponent({
  name: 'ModalChooseClub',
  components: {
    AcModal
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    communities: {
      type: Array,
      default: () => ([])
    }
  },
  emits: ['update:modelValue', 'chooseClub'],
  setup (props, ctx) {
    function handlerModelValue (val) {
      ctx.emit('update:modelValue', val)
    }

    function close (isClose) {
      if (!isClose) {
        handlerModelValue(false)
      }
    }

    function handlerChooseClub (club) {
      ctx.emit('chooseClub', club)
    }

    return {
      handlerModelValue,
      close,
      handlerChooseClub
    }
  }
})
