/* istanbul ignore file */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { dirname, normalize } from 'path'
import Logger from '@web/common/Logger'
import InvalidParamError from '@web/common/errors/InvalidParamError'

const IS_DEBUG = false

class FileSystem {
  /**
   * Скачивание файла
   * @param source - ссылка на файл
   * @param target - место куда сохранять файл
   * @returns {Promise<fileEntry: FileEntry>}
   */
  static async download (source, target): Promise<FileEntry> {
    if (!source) {
      throw new InvalidParamError('source must be set')
    }
    if (!target) {
      throw new InvalidParamError('target must be set')
    }
    const url = source
    const dirEntry = await FileSystem.getDataDirectory()
    const folder = normalize(dirname(target))
    if (folder) {
      await this.createDirectory({ dirEntry, path: folder })
    }
    const fileEntry = await FileSystem.createFile({ dirEntry, filename: target })

    // eslint-disable-next-line no-undef
    const fileTransfer = new FileTransfer()

    return new Promise((resolve, reject) => {
      fileTransfer.download(url, fileEntry.toURL(), resolve, error => {
        if (error.exception && (error.exception.indexOf('ENOSPC') > -1 || error.exception.indexOf('No space left on device') > -1)) {
          error.code = 10
        }
        reject(error)
      })
    })
  }

  static async getDirectory ({ dirEntry, path }) {
    return new Promise((resolve, reject) => {
      dirEntry.getDirectory(path, { create: false }, resolve, reject)
    })
  }

  static normalizePath (path) {
    return path.replace(/(\/+)/g, '/')
  }

  static async createDirectory ({ dirEntry, path: fullPath }) {
    if (!fullPath || fullPath === '.') {
      return dirEntry
    }

    const createDirectory = ({ dirEntry, path }) => new Promise((resolve, reject) => {
      dirEntry.getDirectory(path, { create: true }, resolve, error => {
        if (IS_DEBUG) {
          Logger.error(`[FS] Не могу создать папку: ${path}. Полный путь: ${fullPath}`)
          Logger.error(JSON.stringify(error))
        }
        reject(error)
      })
    })

    const folders = fullPath.split('/').filter(path => path.length !== 0)
    for (let i = 0; i < folders.length; i++) {
      dirEntry = await createDirectory({ dirEntry, path: folders[i] })
    }
    return dirEntry
  }

  static pathToFilename (path) {
    return encodeURIComponent(path.split('/').pop())
  }

  static getFile ({ dirEntry, filename }): Promise<FileSystemEntry> {
    if (IS_DEBUG) {
      Logger.info(`[FS] getFile(${filename})`)
    }
    return new Promise((resolve, reject) => {
      dirEntry.getFile(filename, {}, resolve, reject)
    })
  }

  static async createFile ({ dirEntry, filename }) {
    const createFile = ({ dirEntry, filename }) => new Promise((resolve, reject) => {
      dirEntry.getFile(filename, { create: true, exclusive: false }, resolve, reject)
    })
    return createFile({ dirEntry, filename })
  }

  static writeFile ({ fileEntry, data }) {
    return new Promise((resolve, reject) => {
      fileEntry.createWriter(fileWriter => {
        fileWriter.onwriteend = resolve
        fileWriter.onerror = reject

        fileWriter.write(data)
      })
    })
  }

  static requestFileSystem (location, requestQuota) {
    return new Promise((resolve, reject) => {
      window.requestFileSystem(location, requestQuota, resolve, reject)
    })
  }

  static async exists (filename) {
    try {
      const dirEntry = await FileSystem.getDataDirectory()
      await FileSystem.getFile({ dirEntry, filename })
      return true
    } catch (e) {
      return false
    }
  }

  static async readEntries (dirEntry) {
    return new Promise((resolve, reject) => {
      dirEntry
        .createReader()
        .readEntries(resolve, reject)
    })
  }

  static async getTree (dirEntry) {
    if (dirEntry && !dirEntry.isDirectory) {
      return null
    }
    const entries = await FileSystem.readEntries(dirEntry)
    const children = []
    const files = []

    for (const entry of entries) {
      if (entry.isDirectory) {
        const items = await FileSystem.getTree(entry)
        if (items) {
          children.push(items)
        }
      }
      if (entry.isFile) {
        files.push(entry)
      }
    }

    return {
      folder: dirEntry,
      files,
      children
    }
  }

  static async getFilesInDirectory (dirEntry): FileEntry[] {
    if (!dirEntry.isDirectory) {
      return []
    }
    const files = []
    const entries = await FileSystem.readEntries(dirEntry)
    for (const entry of entries) {
      if (entry.isDirectory) {
        const items = await FileSystem.getFilesInDirectory(entry)
        files.push(...items)
      }
      if (entry.isFile) {
        files.push(entry)
      }
    }
    return files
  }

  static getMetadata (entry) {
    return new Promise((resolve, reject) => entry.getMetadata(resolve, reject))
  }

  static getSizeFolder (dirEntry) {
    return FileSystem.getFilesInDirectory(dirEntry)
      .then(files => Promise.all(files.map(FileSystem.getMetadata)))
      .then(meta => meta.reduce((accumulator, { size }) => accumulator + size, 0))
  }

  static removeRecursively (dirEntry) {
    return new Promise((resolve, reject) => {
      dirEntry.removeRecursively(resolve, reject)
    })
  }

  static remove (entry) {
    return new Promise((resolve, reject) => {
      entry.remove(resolve, reject)
    })
  }

  static verification (source: string, target: FileEntry): Promise<boolean> {
    return FileSystem.getMetadata(target)
      .then(async ({ size: clientSize }) => {
        const serverSize = await fetch(source, { method: 'HEAD' })
          .then(response => parseInt(response.headers.get('content-length'), 10))
        return serverSize === clientSize
      })
      .catch(() => false)
  }

  static getDataDirectory () {
    return FileSystem.resolveLocalFileSystemURL(window.cordova.file.dataDirectory)
  }

  static resolveLocalFileSystemURL (path) {
    return new Promise((resolve, reject) => window.resolveLocalFileSystemURL(path, resolve, reject))
  }

  static readAsText (fileEntry): Promise<string> {
    return new Promise((resolve, reject) => {
      fileEntry.file(file => {
        const reader = new FileReader()
        reader.onloadend = function () {
          resolve(this.result)
        }
        reader.readAsText(file)
      }, reject)
    })
  }
}

export default FileSystem
