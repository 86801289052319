
import { defineComponent } from 'vue'

import useTypesAuth from '@web/composition/useTypesAuth'

import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'AsTypesAuth',
  props: {
    action: {
      type: String,
      default: 'login'
    },
    shortBtn: {
      type: Boolean,
      default: false
    }
  },
  emits: ['select'],
  setup (props, ctx) {
    const router = useRouter()
    const {
      onSelectType,
      ...typesAuth
    } = useTypesAuth({ router, action: props.action })

    function selectTypeAuth (client) {
      onSelectType(client)
      ctx.emit('select', client.name)
    }

    function btnClasses (client) {
      const classes: string[] = []
      classes.push(`-${client.name}`)
      if (client.name === 'google') {
        classes.push('-shadow')
      }
      if (client.linked) {
        classes.push('-linked')
      }
      if (props.shortBtn) {
        classes.push('-short')
      }
      return classes
    }

    return {
      ...typesAuth,
      selectTypeAuth,
      btnClasses
    }
  }
})
