import Token from '@web/common/token'
import { BASE_URL } from '@web/common/config'

/* istanbul ignore start */
/* istanbul ignore end */
const getUrl = (url: string): string => BASE_URL + url
const getAuthToken = (): string => {
  return `Bearer ${Token.get()}`
}

export {
  BASE_URL,
  getUrl,
  getAuthToken
}
