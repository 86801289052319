/* istanbul ignore file */
import firebase from 'firebase/app'
import 'firebase/performance'
import FirebaseConfig from './config'

export class FirebasePerformance {
  private static instance: FirebasePerformance
  installed = false
  performance: firebase.performance.Performance | null = null
  traces: Dictionary<firebase.performance.Trace> = {}
  traceName = 'web'
  app: firebase.app.App | null = null

  private constructor () {
    if (this.installed || !FirebaseConfig) return

    this.app = firebase.initializeApp(FirebaseConfig)
    this.performance = this.app.performance()
    this.performance.instrumentationEnabled = true
    this.performance.dataCollectionEnabled = true
    this.installed = true
  }

  static getInstance (): FirebasePerformance {
    if (!FirebasePerformance.instance) {
      FirebasePerformance.instance = new FirebasePerformance()
    }

    return FirebasePerformance.instance
  }

  trace (traceName: string = this.traceName): firebase.performance.Trace | undefined {
    if (!this.installed || !this.performance) {
      return
    }
    if (!this.traces[traceName]) {
      this.traces[traceName] = this.performance.trace(traceName)
    }
    return this.traces[traceName]
  }

  startTrace (traceName: string): void {
    if (this.installed) {
      this.trace(traceName)?.start()
    }
  }

  stopTrace (traceName: string): void {
    if (this.installed) {
      if (this.trace(traceName)) {
        this.trace(traceName)?.stop()
      }
    }
  }

  incrementMetric (metricName: string, traceName: string): void {
    if (this.installed) {
      this.trace(traceName)?.incrementMetric(metricName)
    }
  }

  putMetric (metricName: string, value: number, traceName: string): void {
    if (this.installed) {
      this.trace(traceName)?.putMetric(metricName, value)
    }
  }

  record ({ traceName, startTime, duration, options }: {
    traceName: string;
    startTime: number;
    duration?: number;
    options?: {
      metrics?: Dictionary<number>;
      attributes?: Dictionary<string>;
    }
  }): void {
    if (this.installed) {
      const _d = duration || Date.now() - startTime
      const domainName = location.host
      const _options = options || { attributes: { domainName } }
      if (_options.attributes) {
        _options.attributes.domainName = domainName
      }
      this.trace(traceName)?.record(startTime, Math.abs(_d), _options)
    }
  }
}

export default FirebasePerformance.getInstance()
