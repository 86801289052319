import Mapper from './Mapper'
import { Service } from '@web/store/types/modules/service'
import CourseMapper from '@web/mapper/CourseMapper'
import { APP_STORE_PRODUCT_PREFIX } from '@web/common/config'

export default class ServiceMapper extends Mapper {
  static toClient (data): {
    service: Service;
    course: Course | undefined;
  } {
    const service: Service = Mapper.toClient(data) as Service
    let course: Course | undefined

    const regexp = new RegExp('^' + APP_STORE_PRODUCT_PREFIX)
    const appstoreProducts = service.appstoreProducts || []
    const product = appstoreProducts.find(p => p.code.match(regexp))
    service.appstoreProductId = product ? product.code : ''

    if (data.course) {
      const response = CourseMapper.toClient(data.course)
      course = response.course
      service.courseId = course.id
    }

    return {
      service,
      course
    }
  }
}
