<template>
  <div class="asDivider">
    <div class="asDivider_line"/>
    <div class="asDivider_text"><slot></slot></div>
    <div class="asDivider_line"/>
  </div>
</template>

<style lang="scss">
  .asDivider {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    color: black;

    &_line {
      height: 1px;
      background-color: #F2F2F2;
      flex: 1 100%;
    }
    &_text {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      margin: 0 16px;

      &:empty {
        display: none;
      }
    }
  }
</style>
