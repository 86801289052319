/* istanbul ignore file */
import client from '../client'
import StageMapper from '@web/mapper/StageMapper'
import HeaderMapper from '@web/mapper/HeaderMapper'

export default class Stage {
  static fetchAll ({ courseId }) {
    const options = {
      params: {
        expand: 'days.scores,' +
          'days.stage_day_homeworks,' +
          'days.stage_day_homeworks.homework.scores,' +
          'course_stage_homeworks,' +
          'course_stage_homeworks.homework.scores'
      }
    }

    return client.get(`/courses/${courseId}/stages`, options)
      .then((response) => {
        return {
          data: response.data.map(StageMapper.toClient),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }
}
