import ArticleMapper from '@web/mapper/ArticleMapper'
import { Article } from '@web/types/Article'
import { getAuthToken } from '@web/api/utils'
import { communityUrlApi } from '@web/common/ExternalConfig/getters'
import urlParse from 'url-parse'
import getBaseUrl from '@web/common/getBaseUrl'

export default class {
  static fetchAll (options: { communityExternalIds?: string[]; width?: number; height?: number; } = {}): Promise<Article[]> {
    const { communityExternalIds, width, height } = options
    // eslint-disable-next-line camelcase
    const params: { 'external_id[]'?: string[]; thumbnail_width?: number; thumbnail_height?: number; } = {}

    if (communityExternalIds) {
      params['external_id[]'] = communityExternalIds
    }

    if (width) {
      params.thumbnail_width = width
    }

    if (height) {
      params.thumbnail_height = height
    }

    if (!communityUrlApi()) {
      return Promise.reject(new Error('communityUrlApi must be set'))
    }

    let path = `${communityUrlApi()}/api/v1/articles/preview`
    if (path.indexOf(getBaseUrl() || '') !== 0) {
      path = `${getBaseUrl() || ''}${communityUrlApi()}/api/v1/articles/preview`
    }

    const url = urlParse(path, true)
    const query: string[] = []
    communityExternalIds?.forEach(id => {
      query.push(`external_id[]=${id}`)
    })
    if (width) {
      query.push(`thumbnail_width=${width * 2}`)
    }

    if (height) {
      query.push(`thumbnail_height=${height * 2}`)
    }

    return fetch(`${url.toString()}?${query.join('&')}`, {
      headers: {
        Authorization: getAuthToken()
      }
    })
      .then(response => response.json())
      .then(data => {
        if (!data['hydra:member']) {
          return []
        }
        let articles = data['hydra:member'].map(item => ArticleMapper.toClient(item))

        // если бек не добавил поле sort для статей
        if (articles.length && Object.prototype.hasOwnProperty.call(articles[0], 'sort')) {
          articles = articles.map((article, i) => {
            article.sort = i
            return article
          })
        }
        return articles
      })
  }
}
