import ExtendApi from '@web/api/modules/Extend'
import InvalidParamError from '@web/common/errors/InvalidParamError'
import SERVICE_TYPE_CODE from '@web/consts/ServiceType'

import {
  ExtendActionsTree,
  ExtendGettersTree,
  ExtendMutationsTree,
  ExtendParams,
  ExtendState
} from '@web/store/types/modules/extend'

const state: ExtendState = {
  all: {}
}

const getters: ExtendGettersTree = {
  all: ({ all }) => all,
  getExtendsByStreamId: ({ all }) => streamId => {
    return Object.values(all).filter(ext => ext.streamId === streamId) || []
  },
  getExtendsCourseByStreamId: (state, { getExtendsByStreamId }) => streamId => {
    const _extends = getExtendsByStreamId(streamId)
    return _extends.filter(e => e.typeCode === SERVICE_TYPE_CODE.COURSE)
  },
  getExtendsConsultationByStreamId: (state, { getExtendsByStreamId }) => streamId => {
    const _extends = getExtendsByStreamId(streamId)
    return _extends.filter(e => e.typeCode === SERVICE_TYPE_CODE.CONSULTATION)
  }
}

const mutations: ExtendMutationsTree = {
  setExtend (state, { streamId, extend }) {
    state.all[extend.id] = {
      ...state.all[extend.id],
      ...extend,
      streamId
    }
  },
  logout (state) {
    state.all = {}
  }
}

/* istanbul ignore next */
const actions: ExtendActionsTree = {
  async fetchExtends ({ commit }, { streamId }) {
    if (!streamId) {
      throw new InvalidParamError('StreamId must be set')
    }
    const { data: courseExtends } = await ExtendApi.fetchAll({ streamId })
    courseExtends.forEach(extend => {
      commit('setExtend', { streamId, extend })
    })
    return courseExtends
  },
  async fetchExtendsCached ({ getters, dispatch }, { streamId }) {
    if (!getters.getExtendsByStreamId(streamId).length) {
      await dispatch('fetchExtends', { streamId })
    }
    return getters.getExtendsByStreamId(streamId)
  },
  checkDiscount (context, { serviceId, discount }) {
    return ExtendApi.checkDiscount({ serviceId, discount })
      .then(({ data }) => data)
  },
  async extend ({ dispatch }, {
    streamId,
    serviceId,
    discount = '',
    phone,
    name,
    email,
    courseServiceId
  }) {
    if (!streamId) {
      throw new InvalidParamError('StreamId must be set')
    }
    if (!serviceId) {
      throw new InvalidParamError('ServiceId must be set')
    }
    let discountSuccess = true
    if (discount.length > 0) {
      const { success } = await dispatch('checkDiscount', { serviceId, discount })
      discountSuccess = success
    }
    let params: ExtendParams = {
      streamId,
      serviceId,
      phone,
      name,
      email,
      courseServiceId
    }
    if (discountSuccess) {
      params = { ...params, discount }
    }
    return ExtendApi.createExtend(params)
      .then(({ data }) => data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
// eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
