import snakeCase from 'lodash.snakecase'
import { click } from './events'

export function initClickEvent (): void {
  document.body.addEventListener('click', (e: MouseEvent) => {
    const target = e.target as HTMLElement
    const analyticsElement: HTMLElement | null = target.hasAttribute('data-analytics') ? target : target.closest('[data-analytics]')
    if (analyticsElement) {
      const _data = analyticsElement.dataset
      delete analyticsElement.dataset.analytics
      const _event: EventBase = {
        action: 'click'
      }
      for (const a in _data) {
        if (a.indexOf('analytics') === 0) {
          const key = snakeCase(a.replace('analytics', ''))
          _event[key] = _data[a] || ''
        }
      }

      click(_event)
    }
  })
}
