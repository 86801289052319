import { loadScript } from '@web/common/Utils'
import { store } from '@web/store'
import Logger from '@web/common/Logger'
import i18n from '@web/plugins/i18n'

class OneSignal {
  static addedObservers = false
  static isGranted = false
  static tags = {}
  static readyTags = {
    has_course: false,
    community: false
  }

  /**
   * init
   * @see https://documentation.onesignal.com/docs/web-push-sdk
   */
  static init (appId: string): void {
    window.OneSignal = window.OneSignal || []

    loadScript('https://cdn.onesignal.com/sdks/OneSignalSDK.js', () => {
      window.OneSignal.push(() => {
        window.OneSignal.init({
          appId,
          allowLocalhostAsSecureOrigin: process.env.NODE_ENV !== 'production',
          autoResubscribe: true,
          promptOptions: {
            /**
             * welcomeNotification
             * @see https://documentation.onesignal.com/docs/slide-prompt#slide-prompt-customizations
             */
            slidedown: {
              prompts: [
                {
                  type: 'push', // current types are "push" & "category"
                  autoPrompt: true, // Configure how many pages a visitor must see and how long to wait on the page before displaying the prompt.
                  text: {
                    /* limited to 90 characters */
                    actionMessage: i18n.global.t('OneSignal.slidedown.actionMessage'),
                    /* acceptButton limited to 15 characters */
                    acceptButton: i18n.global.t('OneSignal.slidedown.acceptButton'),
                    /* cancelButton limited to 15 characters */
                    cancelButton: i18n.global.t('OneSignal.slidedown.cancelButton')
                  },
                  // отключить совсем возможности не нашёл
                  // если тут убрать, а в настройках onesignal оставить,
                  // то будет появляться модалка
                  delay: {
                    pageViews: 1000,
                    timeDelay: 100000
                  }
                }
              ]
            },
            /**
             * customlink
             * @see https://documentation.onesignal.com/docs/custom-link-prompt
             */
            customlink: {
              enabled: false, /* Required to use the Custom Link */
              style: 'button', /* Has value of 'button' or 'link' */
              size: 'medium', /* One of 'small', 'medium', or 'large' */
              color: {
                button: '#ff0002', /* Color of the button background if style = "button" */
                text: '#FFFFFF' /* Color of the prompt's text */
              },
              text: {
                subscribe: i18n.global.t('OneSignal.customlink.subscribe'), /* Prompt's text when not subscribed */
                unsubscribe: i18n.global.t('OneSignal.customlink.unsubscribe'), /* Prompt's text when subscribed */
                explanation: '' /* Optional text appearing before the prompt button */
              },
              unsubscribeEnabled: true /* Controls whether the prompt is visible after subscription */
            }
          }
          /**
           * welcomeNotification
           * @see https://documentation.onesignal.com/docs/web-push-sdk#init-welcomenotification-parameters
           */
          // welcomeNotification: {
          //   disable: false,
          //   title: store.state.assets.title,
          //   message: i18n.global.t('OneSignal.welcomeNotification.message')
          // }
        })
      })

      window.OneSignal.push(() => {
        // уведомление с просьбой подписаться
        window.OneSignal.showSlidedownPrompt()

        window.OneSignal.on('permissionPromptDisplay', () => {
          Logger.warn('The prompt displayed')
        })

        window.OneSignal.on('notificationDisplay', (event) => {
          Logger.warn(`OneSignal notification displayed: ${event}`)
        })

        window.OneSignal.on('notificationDismiss', (event) => {
          Logger.warn(`OneSignal notification dismissed: ${event}`)
        })

        window.OneSignal.on('subscriptionChange', (isSubscribed) => {
          Logger.log(`The user's subscription state is now: ${isSubscribed}`)
        })

        window.OneSignal.on('notificationPermissionChange', (permissionChange) => {
          const currentPermission = permissionChange.to
          OneSignal.isGranted = currentPermission === 'granted'
          Logger.log(`New permission state: ${currentPermission}`)
        })

        store.commit('assets/setIsOneSignalEnable', true)
      })
    })
  }

  static getSubscriptionState (): Promise<Dictionary<{
    isPushEnabled: boolean;
    isOptedOut: boolean;
  }> | { isPushEnabled: boolean; isOptedOut: boolean; }> {
    return Promise.all([
      OneSignal.isPushNotificationsEnabled(),
      window.OneSignal.isOptedOut()
    ]).then((result) => {
      return {
        isPushEnabled: result[0],
        isOptedOut: result[1]
      }
    })
  }

  /**
   * getNotificationPermission
   * @returns {Promise} in THEN return permission
   */
  static getNotificationPermission (): Promise<string> {
    return window.OneSignal.getNotificationPermission()
  }

  /**
   * isPushNotificationsSupported
   * @returns Boolean
   */
  static isPushNotificationsSupported (): boolean {
    return window.OneSignal.isPushNotificationsSupported()
  }

  /**
   * isPushNotificationsEnabled
   * @returns {Promise}
   */
  static isPushNotificationsEnabled (): Promise<boolean> {
    return window.OneSignal.isPushNotificationsEnabled()
  }

  /**
   * showNativePrompt
   */
  static showNativePrompt (): void {
    window.OneSignal.push(() => {
      window.OneSignal.showNativePrompt()
    })
  }

  /**
   * showSlidedownPrompt
   */
  static showSlidedownPrompt (): void {
    window.OneSignal.push(() => {
      window.OneSignal.showSlidedownPrompt()
    })
  }

  /**
   * registerForPushNotification
   */
  static registerForPushNotifications (): void {
    window.OneSignal.registerForPushNotifications()
  }

  /**
   * getUserId
   * @returns {Promise} in THEN return userId
   */
  static getUserId (): number {
    return window.OneSignal.getUserId()
  }

  static prepareTags (tags): void {
    OneSignal.tags = {
      ...OneSignal.tags,
      ...tags
    }
  }

  static sendPrepareTags (): void {
    if (Object.values(OneSignal.readyTags).every(t => t)) {
      for (const key in OneSignal.tags) {
        OneSignal.sendTag(key, OneSignal.tags[key])
      }
    }
  }

  /**
   * sendTags
   * @param {Array} tags
   * @returns {Promise} in THEN return tagsSent
   */
  static sendTags (tags: string | string[] | Dictionary<string>): Dictionary<string> | string | string[] {
    return window.OneSignal.sendTags(tags)
  }

  /**
   * getTags
   * @returns {Promise} in THEN return tags
   */
  static getTags (): Promise<Dictionary<string> | undefined> {
    return window.OneSignal.getTags()
  }

  static sendTag (tag: string, value: string) {
    return window.OneSignal.sendTag(tag, value)
  }

  /**
   * deleteTags
   * @param {Array} tags
   * @returns {Promise} in THEN return deletedTags
   */
  static deleteTags (tags: string | string[]): Promise<string[]> {
    return window.OneSignal.deleteTags(tags)
  }

  /**
   * postNotification - If prtest method to send notification
   * @param {Object} payload
   * @param {String} title - Title
   * @param {String} message - Message
   * @param {String} url - Url
   * @param {String} icon - Icon
   * @param {Object} additionalDataHash
   * @param {Array} buttons
   * @returns {Promise} in THEN return deletedTags
   */
  static postNotification ({
    title = 'AC Test Push Notification',
    message = 'AC Test Push Notification DESCRIPTION!!!',
    url = 'https://example.com/?_osp=do_not_open',
    icon = 'https://onesignal.com/images/notification_logo.png',
    additionalDataHash = {
      notificationType: 'news-feature'
    },
    buttons = [{
      /* Choose any unique identifier for your button. The ID of the clicked button is passed to you so you can identify which button is clicked */
      id: 'like-button',
      /* The text the button should display. Supports emojis. */
      text: 'Like',
      /* A valid publicly reachable URL to an icon. Keep this small because it's downloaded on each notification display. */
      icon: 'http://i.imgur.com/N8SN8ZS.png',
      /* The URL to open when this action button is clicked. See the sections below for special URLs that prevent opening any window. */
      url: 'https://example.com/?_osp=do_not_open'
    },
    {
      id: 'read-more-button',
      text: 'Read more',
      icon: 'http://i.imgur.com/MIxJp1L.png',
      url: 'https://example.com/?_osp=do_not_open'
    }]
  }: {
     title: string;
     message: string;
     url: string;
     icon: string;
     additionalDataHash: {
      notificationType: string;
    };
   buttons: ({
     id: string;
     text: string;
     icon: string;
     url: string;
   })[]
  }): Promise<void> | void {
    window.OneSignal.sendSelfNotification(
      title,
      message,
      url,
      icon,
      additionalDataHash,
      buttons
    )
  }

  /**
   * setSubscription
   * @param {Boolean} set
   * @returns {Promise}
   */
  static setSubscription (set: boolean): Promise<void> {
    return window.OneSignal.setSubscription(set)
  }

  /**
   * setEmail
   * @param {String} email
   * @returns {Promise}
   */
  static setEmail (email: string): Promise<string|null> {
    return window.OneSignal.setEmail(email)
  }

  /**
   * logoutEmail
   * @returns {Promise}
   */
  static logoutEmail (): Promise<boolean> {
    return window.OneSignal.logoutEmail()
  }

  /**
   * setExternalUserId
   * @param {String} externalId
   * @returns {Promise}
   */
  static setExternalUserId (externalId: number): Promise<void> {
    return window.OneSignal.push(() => {
      window.OneSignal.setExternalUserId(String(externalId))
    })
  }

  /**
   * removeExternalUserId
   * @returns {Promise}
   */
  static removeExternalUserId (): Promise<boolean> {
    return window.OneSignal.removeExternalUserId()
  }

  /**
   * getExternalUserId
   * @returns {Promise}
   */
  static getExternalUserId (): Promise<string> {
    return window.OneSignal.getExternalUserId()
  }

  /**
   * on
   * @return void
   */
  static on (eventName: string, handler: (payload?: unknown) => unknown): void {
    window.OneSignal.push(() => {
      window.OneSignal.on(eventName, handler)
    })
  }
}

document.addEventListener('oneSignalReady', ((event: CustomEvent) => {
  OneSignal.init(event.detail.appId)
}) as EventListener, false)

export default OneSignal
