export const ignoreErrors = [
  /failed with status 401/g,
  'Request failed with status code 401',
  'The play() request was interrupted by a call to pause().',
  /InvalidParamError/g,
  // vue-router
  /Navigating to current location .+ is not allowed/g,
  /Avoided redundant navigation to current location:/g,
  /Navigation cancelled from ".+" to ".+" with a new navigation./g,
  /Redirected when going from ".+" to ".+" via a navigation guard./g,
  /Redirected from ".+" to ".+" via a navigation guard./g,
  // сеть
  'Network Error',
  'The network connection was lost.',
  'Превышен лимит времени на запрос.',
  'Сетевое соединение потеряно.',
  'отменено',
  'AbortError: The operation was aborted.',
  'Failed to fetch',
  'Не удалось извлечь',
  'NetworkError when attempting to fetch resource.',
  'The request timed out.',
  'La connexion Internet semble interrompue.',
  'Не удалось завершить операцию. Программа вызвала разрыв подключения',
  'Вероятно, соединение с интернетом прервано.',
  'Зʼєднання з мережею втрачено.',
  'Die Netzwerkverbindung wurde unterbrochen.',
  'Es besteht anscheinend keine Verbindung zum Internet.',
  'Not allowed to request resource',
  'NotSupportedError: The operation is not supported.',
  'Zeitüberschreitung bei der Anforderung.',
  'Не удалось подключиться к серверу.',
  // jwplayer
  'Module.o',
  'Object.o',
  // playerjs
  'o.frameresize.contentWindow is null',
  'Cannot set property \'onresize\' of null',
  'null is not an object (evaluating \'pjstg.play\')',
  'You must pass either a valid element or a valid id.',
  /{"info":.+/gm,
  'Document not active',
  'InvalidStateError: Failed to read the \'buffered\' property from \'SourceBuffer\': This SourceBuffer has been removed from the parent media source.',
  // ошибка загрузки js бандла. часто появляется после пересборки проекта
  /Loading chunk .* failed/g,
  /Loading CSS chunk .* failed/g,
  /ChunkLoadError: .*/gm,
  // Ошибка переключения между видео
  /AbortError: The fetching process for the media resource was aborted by the user agent at the user's request./,
  'AbortError: The play() request was interrupted by a new load request. https://goo.gl/LdLk22',
  // localStorage
  'SecurityError: Failed to read the \'localStorage\' property from \'Window\': Access is denied for this document.',
  'SecurityError: The operation is insecure.',
  // TypeForm
  // Не понятно откуда берется эта ошибка, жалоб на typeform нету, поэтому пока игнорируем
  'JSONP request to https://admin.typeform.com/app/embed//spa/api/v1/user-homework/redirect-survey-form-url?jsoncallback=?',
  'JSONP request to https://admin.typeform.com/app/embed//spa/api/v1/survey-form/redirect-url?jsoncallback=?',
  /spa\/api\/v1\/survey-form\/redirect-url/g,
  /JSONP request to https:\/\/admin.typeform.com\/app\/embed\/\/spa\/api\/v1/g
]
