export default {
  NOT_STARTED: 'not_started',
  OPEN: 'started',
  ENDED: 'ended',
  FREEZE: 'freeze',
  STOPPED: 'stopped'
}

export enum StreamStatusType {
  NOT_STARTED = 'not_started',
  OPEN = 'started',
  ENDED = 'ended',
  FREEZE = 'freeze',
  STOPPED = 'stopped'
}

export const USER_COURSE_STREAM_FREEZE_STATUS = {
  created: 'Created',
  active: 'Active',
  canceled: 'Canceled'
}
