import {
  LessonState,
  LessonGettersTree,
  LessonMutationsTree
} from '@web/store/types/modules/lesson'
import { VideoGettersTree } from '@web/store/types/modules/video'
import keyBy from 'lodash.keyby'

const state: LessonState = {
  all: {}
}

const getLesson = (lesson: Lesson, getVideoById: ReturnType<VideoGettersTree['getVideoById']>): ReturnType<ReturnType<LessonGettersTree['lesson']>> => {
  if (lesson) {
    return {
      lesson,
      localVideoFile: lesson.localVideoFile ? getVideoById(lesson.localVideoFile) : undefined,
      verticalVideo: lesson.verticalVideo ? getVideoById(lesson.verticalVideo) : undefined,
      localDescriptionVideoFile: lesson.localDescriptionVideoFile ? getVideoById(lesson.localDescriptionVideoFile) : undefined,
      verticalDescriptionVideo: lesson.verticalDescriptionVideo ? getVideoById(lesson.verticalDescriptionVideo) : undefined
    }
  }
}

const getters: LessonGettersTree = {
  lessons: ({ all }, getters, rootState, rootGetters) => {
    const lessons = {}
    Object.values(all).forEach(lesson => {
      lessons[lesson.id] = getLesson(lesson, rootGetters['video/getVideoById'])
    })
    return lessons
  },
  lesson: ({ all }, getters, rootState, rootGetters) => id => {
    return getLesson(all[id], rootGetters['video/getVideoById'])
  }
}

const addLesson = (state, lesson) => {
  state.all[lesson.id] = lesson
}

const mutations: LessonMutationsTree = {
  receiveLesson: addLesson,

  receiveLessons (state, lessons) {
    state.all = {
      ...state.all,
      ...keyBy(lessons, 'id')
    }
  },

  logout (state) {
    state.all = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
