import Mapper from '@web/mapper/Mapper'
import { RawSubscription } from '@web/types/Subscription'
import Time from '@web/common/Time'

export default class SubscriptionMapper extends Mapper {
  static toClient (data: unknown): RawSubscription {
    const convertedData = SubscriptionMapper.converter({
      data,
      rules: {
        period: {
          rename: 'interval'
        },
        period_count: {
          rename: 'period'
        }
      }
    }) as RawSubscription

    if (!convertedData.nextPayAt && convertedData.endDate) {
      convertedData.nextPayAt = Time(convertedData.endDate, 'YYYY-MM-DD')
        .set('hours', 0)
        .set('minutes', 0)
        .set('seconds', 0)
        .set('milliseconds', 0)
        .unix() * 1000
    }

    return convertedData
  }
}
