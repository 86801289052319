import { ref } from 'vue'

export default function () {
  const serverErrors = ref({})

  const displayServerErrors = (errors: Dictionary<string[]>): string[] => {
    return Object.values(errors).map((e) => e.join(','))
  }

  function getFirstError (name, veeErrors) {
    if (veeErrors.length > 0) {
      return veeErrors[0]
    } else if (serverErrors.value[name]?.length > 0) {
      return serverErrors.value[name][0]
    }
    return ''
  }

  return {
    serverErrors,
    displayServerErrors,
    getFirstError
  }
}
