import {
  OfferActionsTree,
  OfferGettersTree,
  OfferMutationsTree,
  OfferState
} from '@web/store/types/modules/offer'
import OfferAPI from '@web/api/modules/Offer'
import uniq from 'lodash.uniq'

const state: OfferState = {
  all: {},
  relation: {}
}

const getters: OfferGettersTree = {
  getOfferById: state => id => state.all[id],
  getOfferIdsByRelation: state => id => state.relation[id]
}

const mutations: OfferMutationsTree = {
  receiveOffers (state, payload) {
    payload.forEach(offer => {
      state.all[offer.id] = offer
    })
  },
  receiveRelation (state, payload) {
    if (Array.isArray(state.relation[payload.relationId])) {
      state.relation[payload.relationId] = uniq([...state.relation[payload.relationId], ...payload.offerIds])
    } else {
      state.relation = {
        ...state.relation,
        [payload.relationId]: payload.offerIds
      }
    }
  }
}

const actions: OfferActionsTree = {
  fetchOffers ({ commit }, payload) {
    return OfferAPI.fetch(payload.courseId, payload.offerTypeId)
      .then(response => {
        const offers = response.data.map(data => data.offer)
        const services = response.data.map(data => data.service)
        commit('receiveOffers', offers)
        const offerIds = offers.map(({ id }) => id)
        commit('receiveRelation', { relationId: payload.offerTypeId, offerIds })
        commit('course/addOffers', { courseId: payload.courseId, offerIds }, { root: true })
        commit('service/receiveServices', services, { root: true })

        return offers
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
