/* istanbul ignore file */
const key = 'console_log'
const sLog = Symbol(`log_${key}`)
const sGroup = Symbol(`group_${key}`)
const sInfo = Symbol(`info_${key}`)

const debugLog = message => {
  const list = document.querySelector(`#${key} ol`)
  if (list) {
    const nodeLi = document.createElement('li')
    nodeLi.innerText = message
    list.appendChild(nodeLi)
  }
}

function consoleDebug (): undefined | void {
  if (window[sLog]) {
    return
  }

  window[sLog] = window.console.log
  window[sGroup] = window.console.group
  window[sInfo] = window.console.info

  window.console.log = (message, ...params) => {
    debugLog(message)
    window[sLog](message, ...params)
  }
  window.console.group = (message, ...params) => {
    debugLog(message)
    window[sGroup](message, ...params)
  }
  window.console.info = (message, ...params) => {
    debugLog(message)
    window[sInfo](message, ...params)
  }
  const div = document.createElement('div')
  div.setAttribute('id', key)
  div.appendChild(document.createElement('ol'))
  document.body.appendChild(div)
}

if (window.location.search.search('debug=1') > 0) {
  consoleDebug()
}

export default consoleDebug
