import Mapper from './Mapper'
import { Chat } from '@web/store/types/modules/chat'

/* istanbul ignore next */
export default class ChatMapper extends Mapper {
  static toClient (data): Chat {
    return Mapper.converter({
      data,
      rules: {

        user_course_stream_id: {
          rename: 'streamId'
        },

        created_at: {
          cb: value => value * 1000
        },

        stream_start_date: {
          cb: value => value * 1000
        },

        stream_end_date: {
          cb: value => value * 1000
        },
        avatar: {
          baseUrl: true
        }
      }
    }) as Chat
  }
}
