
import { defineComponent, onBeforeUnmount, watch } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    backdropClose: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue'],
  setup (props, ctx) {
    onBeforeUnmount(() => {
      document.body.style.overflowY = ''
    })

    function close () {
      ctx.emit('update:modelValue', false)
    }

    function onBackdropClose () {
      if (props.backdropClose) {
        close()
      }
    }

    watch(() => props.modelValue, (val) => {
      if (val) {
        document.body.style.overflowY = 'hidden'
      } else {
        document.body.style.overflowY = ''
      }
    },
    {
      immediate: true
    })

    return {
      close,
      onBackdropClose
    }
  }
})
