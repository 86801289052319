import client from '@web/api/client'
import ServiceMapper from '@web/mapper/ServiceMapper'
import HeaderMapper from '@web/mapper/HeaderMapper'

export default class Service {
  static fetch (id: number) {
    return client.get(`/course-services/view?id=${id}&expand=childs.course,childs.stream,course,stream`)
      .then(response => ({
        headers: HeaderMapper.toClient(response.headers),
        data: ServiceMapper.toClient(response.data)
      }))
  }

  static catalog () {
    return client.get('/course-services?expand=childs.course,childs.stream,course,stream')
      .then(response => ({
        headers: HeaderMapper.toClient(response.headers),
        data: response.data.map(ServiceMapper.toClient)
      }))
  }
}
