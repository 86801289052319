import {
  InstallmentsActionsTree,
  InstallmentsGettersTree,
  InstallmentsMutationsTree,
  InstallmentsState
} from '@web/store/types/modules/installments'
import { Installment } from '@web/types/Installment'
import InstallmentApi from '@web/api/modules/Installment'

const state: InstallmentsState = {
  isLoaded: false,
  installments: new Map<number, Installment>()
}

const getters: InstallmentsGettersTree = {
  installments: state => Array.from(state.installments.values()),
  isLoaded: state => state.isLoaded,
  isEmpty: state => state.isLoaded && !state.installments.size
}

const mutations: InstallmentsMutationsTree = {
  receiveInstallments (state: InstallmentsState, payload: Installment[]) {
    for (const subscription of payload) {
      state.installments.set(subscription.id, subscription)
    }
  },
  receiveInstallment (state: InstallmentsState, payload: Installment) {
    state.installments.set(payload.id, payload)
  },
  setIsLoaded (state: InstallmentsState, payload: boolean) {
    state.isLoaded = payload
  },
  logout (state: InstallmentsState) {
    state.isLoaded = false
    state.installments.clear()
  }
}

const actions: InstallmentsActionsTree = {
  fetchInstallments ({ commit }): Promise<void> {
    commit('setIsLoaded', false)
    return InstallmentApi.fetchAll()
      .then(installments => {
        commit('receiveInstallments', installments)
        commit('setIsLoaded', true)
      })
  },
  fetchCachedInstallments ({ getters, dispatch }): Promise<void> {
    if (getters.isLoaded) {
      return Promise.resolve()
    }
    return dispatch('fetchInstallments')
  },
  fetchInstallment ({ commit }, id: number): Promise<void> {
    return InstallmentApi.fetch(id)
      .then(installment => {
        commit('receiveInstallment', installment)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
