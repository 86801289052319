/* istanbul ignore file */
import client from '../client'
import CourseMapper from '@web/mapper/CourseMapper'
import HeaderMapper from '@web/mapper/HeaderMapper'

export default class Course {
  static fetchAll ({ requestAll } = {} as Dictionary<string | number | boolean>) {
    const expand: string[] = []

    if (requestAll) {
      expand.push('stages.days.lesson_sets')
      expand.push('stages.days.course_options')
      expand.push('stages.days.user_options')
      expand.push('stages.days.stage_day_homeworks')
    }

    return client.get('/courses', {
      params: {
        expand: expand.join(',')
      }
    })
      .then((response) => {
        return {
          data: response.data.map(CourseMapper.toClient),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static fetch ({ id, requestDocuments, requestStages, requestDays, requestHomeworks, requestLessons, requestAll }: { [key: string]: string | number | boolean; id: number }) {
    const expand: string[] = []

    if (requestDocuments) {
      expand.push('trainingOptions')
    }

    if (requestStages) {
      expand.push('stages.course_stage_homeworks')
    }

    if (requestDays) {
      expand.push('stages.days')
    }

    if (requestHomeworks) {
      expand.push('stages.days.stage_day_homeworks')
    }

    if (requestLessons) {
      expand.push('lessons')
    }

    if (requestAll) {
      expand.push('documents')
      expand.push('stages.days.lesson_sets')
      expand.push('stages.days.course_options')
      expand.push('stages.days.user_options')
      expand.push('stages.days.stage_day_homeworks')
      expand.push('lessons')
      expand.push('stages.course_stage_homeworks')
    }

    return client.get(`/courses/${id}`, {
      params: {
        expand: expand.join(',')
      }
    })
      .then((response) => {
        return {
          data: CourseMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static fetchDocuments ({ id }) {
    return client.get(`/courses/${id}`, {
      params: {
        fields: 'id,documents',
        expand: 'documents'
      }
    })
      .then((response) => {
        return {
          data: CourseMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static fetchVersionAll () {
    return client.get('/courses/versions')
      .then((response) => {
        return {
          data: response.data,
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }
}
