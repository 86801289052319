import { computed, Ref, watch } from 'vue'
import { useStore } from '@web/store'

export function useMeta ({ title, showDomain = true }: { title: Ref<string | undefined> | string | undefined, showDomain?: boolean }) {
  const store = useStore()
  const domain = computed(() => store.state.assets.title)
  const domainTitle = computed(() => showDomain ? domain.value ? `| ${domain.value}` : '' : '')

  function setTitle (text: string): void {
    if (text) {
      document.title = `${text} ${domainTitle.value}`
    }
  }
  if (typeof title === 'string') {
    setTitle(title)
  }
  if (typeof title === 'object') {
    watch(title, (_title) => {
      if (_title) {
        setTitle(_title)
      }
    }, {
      immediate: true
    })
  }
}
