import Mapper from '@web/mapper/Mapper'
import { Community, UserCommunity } from '@web/types/Community'
import Time from '@web/common/Time'

/* istanbul ignore next */
export default class CommunityMapper extends Mapper {
  static userCommunity (data): UserCommunity {
    return Mapper.converter({
      data,
      rules: {
        end_date: {
          // eslint-disable-next-line camelcase
          cb: end_date => Time(end_date, 'YYYY-MM-DD').unix() * 1000
        },
        start_date: {
          // eslint-disable-next-line camelcase
          cb: start_date => Time(start_date, 'YYYY-MM-DD').unix() * 1000
        }
      }
    }) as UserCommunity
  }

  static toClient (data): Community {
    return Mapper.converter({
      data
    }) as Community
  }
}
