import { computed, ref } from 'vue'
import useArticles from '@web/composition/useArticles'
import { communityUrlFrontend, communityUrlApi } from '@web/common/ExternalConfig/getters'
import { Community } from '@web/types/Community'
import getBaseUrl from '@web/common/getBaseUrl'
import { getUrlWithAuthParamsCommunity } from '@web/common/Utils'
import { useStore } from '@web/store'
import { useRouter } from 'vue-router'
import urlParse from 'url-parse'

function useClub () {
  const store = useStore()
  const router = useRouter()

  const {
    communities,
    articles,
    fetchArticles,
    isShowArticles,
    previewLoaded
  } = useArticles()

  const countItems = computed(() => articles.value?.length || 0)

  const showModalChooseClub = ref(false)
  const typeModalChooseClub = ref<null | 'faq' | 'lookAll' | 'chat' | 'aboutClub'>(null)
  const communitiesModalChooseClub = ref<null | Community[]>(null)

  const isHaveClubSubscribe = computed(() => {
    if (!communityUrlApi() || !communities.value.length) {
      return false
    }

    return communities.value.some(c => c.type === 'owned')
  })

  const isShowClubBlock = computed(() => isShowArticles.value)
  const hasSubscription = computed(() => store.getters['subscription/hasSubscription'])

  function getUrlWithParamsForAboutClub (url: string): string {
    const urlInstance = urlParse(url, true)
    urlInstance.set('query', {
      utm_source: 'lk',
      utm_medium: 'main',
      utm_campaign: 'button',
      ...urlInstance.query
    })
    return urlInstance.toString()
  }

  function goToClub (community: Community, type: 'faq' | 'lookAll' | 'chat' | 'aboutClub' = 'aboutClub') {
    let path = community.faqUrl || community.customUrl || ''
    let route: { name: string; params: { [key: string]: string; }; } | null = null

    switch (type) {
      case 'chat':
        if (community.telegramChatUrl) {
          path = community.telegramChatUrl
        }
        break
      case 'lookAll':
        if (communityUrlFrontend() || communityUrlApi()) {
          path = `${communityUrlFrontend() || communityUrlApi()}/${community.slug}`
          if (path.indexOf(getBaseUrl() || '') !== 0) {
            path = `${getBaseUrl() || ''}${communityUrlFrontend()}/${community.slug}`
          }
        }
        break
      case 'faq':
        if (community.faqUrl) {
          path = community.faqUrl
        }
        break
      case 'aboutClub': default:
        if (hasSubscription.value) {
          if (community.haveSubscriptionLandingUrl) {
            path = getUrlWithParamsForAboutClub(community.haveSubscriptionLandingUrl)
          }
        } else {
          if (community.type === 'recommended') {
            if (community.noHaveSubscriptionLandingUrl) {
              path = getUrlWithParamsForAboutClub(community.noHaveSubscriptionLandingUrl)
            }
          } else if (community.type === 'expired') {
            route = { name: 'subscriptions', params: { warning: 'true' } }
          }
        }
        break
    }

    if (route) {
      router.push(route)
    } else {
      const urlInstance = urlParse(path, true)
      urlInstance.set('query', {
        ...urlInstance.query,
        utm_source: 'lk',
        utm_medium: 'main',
        utm_campaign: type
      })
      window.open(getUrlWithAuthParamsCommunity(urlInstance.toString()), '_system')
    }
  }

  const notHiddenCommunities = computed(() => communities.value.filter(c => c.type !== 'hidden'))
  const notRecommendedCommunities = computed(() => notHiddenCommunities.value.filter(c => c.type !== 'recommended'))

  const lookAllCommunities = computed(() => {
    let _communities = notRecommendedCommunities.value
      .filter(community => !community.chatOnly)
    if (!_communities.length) {
      _communities = notHiddenCommunities.value
        .filter(community => !community.chatOnly)
    }

    return _communities
  })
  const showAll = computed(() => true)
  function handlerClubLookAll () {
    if (!showAll.value) {
      return
    }
    const type = 'lookAll'
    if (lookAllCommunities.value.length > 1) {
      typeModalChooseClub.value = type
      showModalChooseClub.value = true
      communitiesModalChooseClub.value = lookAllCommunities.value
    } else {
      goToClub(lookAllCommunities.value[0], type)
    }
  }

  const faqCommunities = computed(() => {
    let _communities = notRecommendedCommunities.value
      .filter(c => c.faqUrl)
      .filter(community => !community.chatOnly)
    if (!_communities.length) {
      _communities = notHiddenCommunities.value
        .filter(c => c.faqUrl)
        .filter(community => !community.chatOnly)
    }

    return _communities
  })
  const showFaq = computed(() => faqCommunities.value.length)
  const showInfo = computed(() => isHaveClubSubscribe.value && Boolean(showFaq.value))
  function handlerClubFaq () {
    const type = 'faq'
    if (faqCommunities.value.length > 1) {
      typeModalChooseClub.value = type
      showModalChooseClub.value = true
      communitiesModalChooseClub.value = faqCommunities.value
    } else {
      goToClub(faqCommunities.value[0], type)
    }
  }

  const chatCommunities = computed(() => {
    let _communities = notRecommendedCommunities.value
      .filter(c => c.telegramChatUrl)

    if (!_communities.length) {
      _communities = notHiddenCommunities.value
        .filter(c => c.telegramChatUrl)
    }

    return _communities
  })
  const showChat = computed(() => chatCommunities.value.length)
  function handlerClubChat () {
    const type = 'chat'
    if (chatCommunities.value.length > 1) {
      typeModalChooseClub.value = type
      showModalChooseClub.value = true
      communitiesModalChooseClub.value = chatCommunities.value
    } else {
      goToClub(chatCommunities.value[0], type)
    }
  }

  const aboutClubCommunities = computed(() => {
    let _communities = notRecommendedCommunities.value
      .filter(c => c.noHaveSubscriptionLandingUrl && c.haveSubscriptionLandingUrl)
      .filter(community => !community.chatOnly)

    if (!_communities.length) {
      _communities = notHiddenCommunities.value
        .filter(c => c.noHaveSubscriptionLandingUrl && c.haveSubscriptionLandingUrl)
        .filter(community => !community.chatOnly)
    }

    return _communities
  })
  const showAboutClub = computed(() => aboutClubCommunities.value.length)
  function handlerAboutClub () {
    const type = 'aboutClub'
    if (aboutClubCommunities.value.length > 1) {
      typeModalChooseClub.value = type
      showModalChooseClub.value = true
      communitiesModalChooseClub.value = aboutClubCommunities.value
    } else {
      goToClub(aboutClubCommunities.value[0], type)
    }
  }

  function handlerChooseClub (community: Community) {
    if (typeModalChooseClub.value) {
      goToClub(community, typeModalChooseClub.value)
      typeModalChooseClub.value = null
    }
    showModalChooseClub.value = false
  }

  return {
    communities,
    articles,
    fetchArticles,
    isShowArticles,
    previewLoaded,
    countItems,
    showModalChooseClub,
    typeModalChooseClub,
    communitiesModalChooseClub,
    isHaveClubSubscribe,
    isShowClubBlock,
    hasSubscription,
    getUrlWithParamsForAboutClub,
    goToClub,
    notHiddenCommunities,
    notRecommendedCommunities,
    lookAllCommunities,
    showAll,
    handlerClubLookAll,
    faqCommunities,
    showFaq,
    showInfo,
    handlerClubFaq,
    chatCommunities,
    showChat,
    handlerClubChat,
    aboutClubCommunities,
    showAboutClub,
    handlerAboutClub,
    handlerChooseClub
  }
}

export default useClub
