import Locale from '@web/common/locale'
import dayjs, { Dayjs } from 'dayjs'
// use .from .to .fromNow .toNow
import relativeTime from 'dayjs/plugin/relativeTime'
// use timezone
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

// use custom input format
import customParseFormat from 'dayjs/plugin/customParseFormat'

// use weekday
import weekday from 'dayjs/plugin/weekday'

// use LocaleData
import localeData from 'dayjs/plugin/localeData'
import updateLocale from 'dayjs/plugin/updateLocale'

// use ArraySupport
import arraySupport from 'dayjs/plugin/arraySupport'

// use ObjectSupport
import objectSupport from 'dayjs/plugin/objectSupport'

// use minMax
import minMax from 'dayjs/plugin/minMax'

import isToday from 'dayjs/plugin/isToday'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

// import locales [ru, en, es]
import 'dayjs/locale/ru'
import 'dayjs/locale/en'
import 'dayjs/locale/es'

dayjs.locale(Locale.get())

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(updateLocale)
dayjs.extend(arraySupport)
dayjs.extend(objectSupport)
dayjs.extend(minMax)
dayjs.extend(isToday)
dayjs.extend(isSameOrBefore)

export default function time (date?: string | number | Date | Dayjs | null, format = 'DD.MM.YYYY', strict = true, keepLocalTime = true): Dayjs {
  let _date: Dayjs = (date ? dayjs(date, format, strict).utc(keepLocalTime) : dayjs())
  if (typeof date === 'string') {
    _date = dayjs(date, format, strict).utc(keepLocalTime)
  }

  if (typeof date === 'number') {
    _date = dayjs.unix(date / 1000)
  }

  return _date
}

export {
  dayjs
}
