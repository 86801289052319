import { APPLE_ID, APPS_FLYER_DEV_KEY } from '@web/common/config'
import Logger from '@web/common/Logger'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAppsflyer = (): any => window?.plugins?.appsFlyer

export default class Appsflyer {
  static init (): void {
    const appsflyer = getAppsflyer()
    if (!appsflyer) {
      Logger.warn('Can\'t init Appsflyer')
      return
    }

    appsflyer.initSdk({
      devKey: APPS_FLYER_DEV_KEY, // your AppsFlyer devKey
      isDebug: false,
      appId: String(APPLE_ID), // your ios appID
      waitForATTUserAuthorization: 10, // time for the sdk to wait before launch - IOS 14 ONLY!
      collectIMEI: true,
      collectAndroidID: true
    },
    result => {
      Logger.log('appsFlyer init')
      Logger.log(result)
    },
    error => {
      Logger.log('appsFlyer error')
      Logger.error(error)
    })
  }

  static handleOpenUrl (url: string): void {
    const appsflyer = getAppsflyer()
    if (!appsflyer) {
      return
    }

    appsflyer.handleOpenUrl(url)
  }

  static logEvent (eventName: string, eventValues: Dictionary<string>): Promise<unknown> {
    const appsflyer = getAppsflyer()
    if (!appsflyer) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject()
    }

    return new Promise((resolve, reject) => appsflyer.logEvent(eventName, eventValues, resolve, reject))
  }
}
