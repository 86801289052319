import { computed, watch } from 'vue'
import getBaseUrl from '@web/common/getBaseUrl'
import { IS_MOBILE, URL_SCHEME } from '@web/common/config'
import Swal from 'sweetalert2'
import Token from '@web/common/token'
import { useStore } from '@web/store'
import sentry from '@web/common/sentry'
import Logger from '@web/common/Logger'
import urlParse from 'url-parse'
import { getExternalAuthsError } from '@web/common/Utils'
import i18n from '@web/plugins/i18n'
import { Router } from 'vue-router'

export function generateMobileReturnUrls ({ action, client, routeName }): { successUrl: string; cancelUrl: string } {
  const q = `action=${action}&method=${client.name}`
  return {
    successUrl: `${URL_SCHEME}://route/${routeName}?${q}&status=success`,
    cancelUrl: `${URL_SCHEME}://route/${routeName}?${q}&status=fail`
  }
}
export function generateWebReturnUrls ({ url, action, client, routeName }): { successUrl: string; cancelUrl: string } {
  const returnUrlInstance = urlParse(url, true)
  returnUrlInstance.set('query', {
    ...returnUrlInstance.query,
    action,
    method: client.name,
    route: routeName
  })
  returnUrlInstance.set('hash', '')

  const successUrlInstance = urlParse(returnUrlInstance.toString(), true)
  successUrlInstance.set('query', {
    ...successUrlInstance.query,
    status: 'success'
  })

  const cancelUrlInstance = urlParse(returnUrlInstance.toString(), true)
  cancelUrlInstance.set('query', {
    ...cancelUrlInstance.query,
    status: 'fail'
  })

  return {
    successUrl: successUrlInstance.toString(),
    cancelUrl: cancelUrlInstance.toString()
  }
}

export function generateUrl ({ url, action, client, routeName, mobile = IS_MOBILE }) {
  let successUrl = ''
  let cancelUrl = ''

  if (mobile) {
    const mobileReturnUrls = generateMobileReturnUrls({ action, client, routeName })
    successUrl = mobileReturnUrls.successUrl
    cancelUrl = mobileReturnUrls.cancelUrl
  } else {
    const webReturnUrls = generateWebReturnUrls({ url, action, client, routeName })
    successUrl = webReturnUrls.successUrl
    cancelUrl = webReturnUrls.cancelUrl
  }

  const URLInstance = urlParse(getBaseUrl(client[action]), true)
  if (Token.get()) {
    URLInstance.set('query', {
      ...URLInstance.query,
      token: Token.get()
    })
  }
  URLInstance.set('query', {
    ...URLInstance.query,
    successUrl,
    cancelUrl
  })

  return URLInstance.toString()
}

export default function ({ router, action, openUrl }: { router: Router; action: string; openUrl?: (url: string) => void }) {
  const store = useStore()
  const clients = computed(() => store.getters['assets/authCollection'])
  const user = computed(() => store.getters['user/user'])

  async function onSelectType (client) {
    if (client.linked) {
      Swal.fire({
        title: String(i18n.global.t('attention')),
        text: String(i18n.global.t('unlinkSocialNetworksDescription')),
        confirmButtonText: String(i18n.global.t('unbind')),
        icon: 'error'
      }).then(result => {
        if (result.value) {
          const externalAuth = user.value.userExternalAuths?.find(({ source }) => source === client.name)
          if (externalAuth) {
            store.dispatch('user/deleteExternalAuth', externalAuth.id)
              .catch(e => {
                sentry.captureException(e)
                Logger.error(e)
              })
          }
        }
      })
    } else {
      if (action) {
        const url = generateUrl({
          url: location.href,
          action,
          client,
          routeName: router.currentRoute.value.name
        })
        if (IS_MOBILE && openUrl) {
          openUrl(url)
        } else {
          location.href = url
        }
      }
    }
  }

  function getTextBtn (key) {
    return {
      apple: 'Apple',
      google: 'Google',
      facebook: 'Facebook',
      vkontakte: 'Вконтакте'
    }[key] || key
  }

  if (user.value?.id && router.currentRoute.value.query.action !== 'link') {
    store.dispatch('user/fetchExternalAuth')
  }

  watch(() => router.currentRoute.value, (newRoute) => {
    const query: Dictionary<string> = newRoute.query as Dictionary<string>
    if (query.action === 'link') {
      if (query.status === 'fail') {
        Swal.fire({
          title: String(i18n.global.t('Error')),
          text: getExternalAuthsError({ errorCode: query.errorCode, errorMessage: query.errorMessage }),
          confirmButtonText: String(i18n.global.t('good')),
          icon: 'error'
        })
      } else if (query.status === 'success') {
        store.dispatch('user/fetchExternalAuth')
      }
      router.replace({ query: {} })
    }
  }, {
    immediate: true
  })

  return {
    clients,
    onSelectType,
    getTextBtn
  }
}
