import i18n from '@web/plugins/i18n'

import { ref, computed, watch } from 'vue'
import Analytics from '@web/services/Analytics/Analytics'

import { useStore } from '@web/store'
import { User } from '@web/store/types/modules/user'
import { getState } from '@web/common/ExternalConfig/ExternalConfig'
import { IS_WEB } from '@web/common/config'
import GTM from '@web/services/Analytics/modules/GoogleTagManager'
import sentry, { sentryConfigure } from '@web/common/sentry'
import Logger from '@web/common/Logger'
import { showWarningSubscription } from '@web/common/subscription'

/*
* @param object ctx
*/
function useApp ({ router }) {
  const store = useStore()

  const loading = ref(true)
  const status = ref('')

  const theme = computed(() => store.state.assets.theme)
  const title = computed(() => store.state.assets.title)
  const isLoadedStreams = computed(() => store.getters['stream/isLoadedAll'])
  const user = computed(() => store.getters['user/user'])
  const fbqId = computed<string | undefined>(() => getState()?.data?.fbq)
  const gtmId = computed<string | undefined>(() => getState()?.data?.gtm)
  const cookiePolicy = computed(() => store.getters['cookie/policy'])
  const isLoadedSubscriptions = computed(() => store.getters['subscription/isLoaded'])
  const hasSubscription = computed(() => store.getters['subscription/hasSubscription'])
  const streams = computed(() => store.getters['stream/streams'])

  function checkedSubscription () {
    if (showWarningSubscription()) {
      router.push({ name: 'subscriptions', params: { warning: 'true' } })
    }
  }

  function offline () {
    status.value = 'offline'
  }
  function online () {
    status.value = 'online'
  }
  function logoutHandler () {
    document.addEventListener('logout', () => {
      store.dispatch('logout')
      store.commit('user/setNotice', i18n.global.t('loggedInOtherDevice'))

      const returnUrl: string = typeof router.currentRoute.value.query?.returnUrl === 'string' ? router.currentRoute.value.query?.returnUrl : router.currentRoute.value.fullPath
      store.commit('setReturnUrl', returnUrl)
      router.push({ name: 'login' })
    })
  }

  // Watchers
  const themeWatch = watch(theme, (newTheme, oldTheme) => {
    document.body.classList.remove(`-${oldTheme}`)
    document.body.classList.add(`-${newTheme}`)
  }, {
    immediate: true
  })
  watch(fbqId, id => {
    if (id && window.fbq) {
      window.fbq('init', id)
      window.fbq('track', 'PageView')
    }
  })

  if (IS_WEB) {
    let gtmIsInstalled = false
    watch(gtmId, (id, oldId) => {
      if ((id && id !== oldId) && cookiePolicy.value.analytics) {
        if (!gtmIsInstalled) {
          GTM.init(id)
          gtmIsInstalled = true
        }
      }
    }, {
      immediate: true
    })

    watch(cookiePolicy, (newCP, oldCP) => {
      if (gtmId.value && (newCP.analytics !== oldCP?.analytics && newCP.analytics)) {
        if (!gtmIsInstalled) {
          GTM.init(gtmId.value)
          gtmIsInstalled = true
        }
      }
    }, {
      deep: true,
      immediate: true
    })
  }

  async function initHandler () {
    if (!user.value?.id) {
      return
    }

    await store.dispatch('subscription/fetchCachedSubscriptions')

    await Promise.all([
      store.dispatch('stream/fetchUserCourseStreamsCached'),
      store.dispatch('community/fetchCachedCommunities'),
      store.dispatch('userScore/amount'),
      store.dispatch('banner/fetchBanners'),
      store.dispatch('installments/fetchCachedInstallments')
    ]).catch(e => {
      sentry.captureException(e)
      Logger.error(e)
    }).finally(() => {
      checkedSubscription()
    })

    sentryConfigure(user.value, store.getters['user/username'])

    Analytics.setUserId(user.value.id)
    if (user.value.isAdmin) {
      Analytics.setTrafficType('internal')
    }
  }

  watch(user, (user: User | undefined, oldUser: User | undefined) => {
    if (user?.id && user?.id !== oldUser?.id) {
      initHandler()
    }
  }, {
    deep: true,
    flush: 'sync',
    immediate: true
  })

  watch(hasSubscription, () => {
    checkedSubscription()
  }, {
    deep: true,
    flush: 'sync',
    immediate: true
  })

  watch(streams, () => {
    checkedSubscription()
  }, {
    deep: true,
    flush: 'sync',
    immediate: true
  })

  return {
    // local variable
    loading,
    status,

    // store variable
    theme,
    title,
    isLoadedStreams,
    isLoadedSubscriptions,
    user,

    offline,
    online,
    logoutHandler,

    // watchers
    themeWatch,

    initHandler
  }
}

export default useApp
