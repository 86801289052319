export interface FirebasePerformanceConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string | null;
}
const config: FirebasePerformanceConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_PERFORMANCE_API_KEY || '',
  authDomain: process.env.VUE_APP_FIREBASE_PERFORMANCE_AUTH_DOMAIN || '',
  databaseURL: process.env.VUE_APP_FIREBASE_PERFORMANCE_DATABASE_URL || '',
  projectId: process.env.VUE_APP_FIREBASE_PERFORMANCE_PROJECT_ID || '',
  storageBucket: process.env.VUE_APP_FIREBASE_PERFORMANCE_STORAGE_BUCKET || '',
  messagingSenderId: process.env.VUE_APP_FIREBASE_PERFORMANCE_MESSAGE_SENDER_ID || '',
  appId: process.env.VUE_APP_FIREBASE_PERFORMANCE_APP_ID || '',
  measurementId: process.env.VUE_APP_FIREBASE_PERFORMANCE_MEASUREMENT_ID || null
}
const isExistConfig = () => {
  return config.apiKey &&
    config.authDomain &&
    config.databaseURL &&
    config.projectId &&
    config.storageBucket &&
    config.messagingSenderId &&
    config.appId
}
export default isExistConfig() ? config : null
