/* istanbul ignore file */
import { loadScript } from '@web/common/Utils'
import Logger from '@web/common/Logger'
import { IS_WEB } from '@web/common/config'
import snakeCase from 'lodash.snakecase'
import { Router } from 'vue-router'
import sentry from '@web/common/sentry'

export default class GoogleTagManager {
  static id: string
  static config: Record<string, unknown>

  static setConfig (config: Record<string, unknown>) {
    GoogleTagManager.config = {
      ...GoogleTagManager.config,
      ...config
    }
    window.gtag('config', GoogleTagManager.id, GoogleTagManager.config)
  }

  static logException () {
    if (!window.gtag) {
      if (process.env.NODE_ENV === 'production') {
        sentry.captureMessage('GTag not init')
      }
      Logger.info('GTag not init')
    }
  }

  static init (id: string): void {
    loadScript(`https://www.googletagmanager.com/gtag/js?id=${id}`)
    window.dataLayer = window.dataLayer || []
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function gtag (...args) {
      window.dataLayer = window.dataLayer || []
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments)
    }
    gtag('js', new Date())
    GoogleTagManager.id = id
    window.gtag = gtag
    GoogleTagManager.setConfig({
      // eslint-disable-next-line camelcase
      send_page_view: false
    })
  }

  static send ({ action, ...options }: EventBase): void {
    if (!window.gtag) {
      GoogleTagManager.logException()
      return
    }
    window.gtag('event', action, options)
  }

  static setUserId (id: string | null): void {
    if (!window.gtag) {
      GoogleTagManager.logException()
      return
    }
    GoogleTagManager.setConfig({
      // eslint-disable-next-line camelcase
      user_id: id
    })
  }

  static pageView (router: Router): void {
    if (!window.gtag) {
      GoogleTagManager.logException()
      return
    }
    const params = {}
    for (const name in router.currentRoute.value.params) {
      params[snakeCase(name)] = String(router.currentRoute.value.params[name])
    }
    // SEE: https://developers.google.com/analytics/devguides/collection/ga4/page-view
    window.gtag('event', 'page_view', {
      ...params,
      // eslint-disable-next-line camelcase
      page_title: router.currentRoute.value.meta?.title || '',
      // eslint-disable-next-line camelcase
      page_location: window.location.href,
      // eslint-disable-next-line camelcase
      page_path: IS_WEB ? window.location.pathname : router,
      // eslint-disable-next-line camelcase
      send_to: GoogleTagManager.id
    })
  }
}
