import FileSystem from '@web/common/FileSystem'
import InvalidParamError from '@web/common/errors/InvalidParamError'
import DownloadStatus from '@web/consts/DownloadStatus'
import { isOnline } from '@web/common/Utils'
import Logger from '@web/common/Logger'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { normalize } from 'path'

import {
  FileState,
  FileGettersTree,
  FileMutationsTree,
  FileActionsTree
} from '@web/store/types/modules/file'

const state: FileState = {
  all: {},
  download: {}
}

const getters: FileGettersTree = {
  getFile: state => id => {
    return state.all && state.all[id]
  },
  getDownload: state => url => {
    return state.download && state.download[url]
  }
}

const mutations: FileMutationsTree = {
  setFile (state, file) {
    const id = file.id || file.url
    state.all[id] = {
      ...file,
      id
    }
  },
  removeFile (state, id) {
    delete state.all[id]
  },
  removeDownload (state, url) {
    delete state.download[url]
  },
  setDownload (state, { url, download }) {
    state.download[url] = {
      ...state.download[url],
      ...download
    }
  },
  logout (state) {
    state.all = {}
    state.download = {}
  }
}

/* istanbul ignore next */
const actions: FileActionsTree = {
  async downloadFile ({ getters, commit }, { url, path }) {
    const loadStart = performance.now()
    const download = getters.getDownload(url)
    if (download && [DownloadStatus.LOADING, DownloadStatus.LOADED].includes(download.status)) {
      return download.request
    }

    const request = FileSystem.download(url, path)
    commit('setDownload', {
      url,
      download: {
        status: DownloadStatus.LOADING,
        request
      }
    })

    return request
      .then((fileEntry) => {
        commit('setFile', { url, path: fileEntry.toURL() })
        commit('setDownload', {
          url,
          download: {
            status: DownloadStatus.LOADED
          }
        })
        const loadEnd = performance.now()
        const time = (loadEnd - loadStart).toFixed(2)
        Logger.info(`[WPO] file ${url} success loaded: ${time} ms`)
        return fileEntry
      })
      .catch(e => {
        commit('setDownload', {
          url,
          download: {
            status: DownloadStatus.FAIL
          }
        })
        Logger.error(`[vuex] download file failed. \r\n Url: ${url} \r\n Path: ${path}`)
        throw e
      })
  },

  async getFile ({ commit, dispatch }, { url, fileId, fileName = '', folder = '' }) {
    Logger.info(`[store][FS] getFile({ url: ${url}, fileId: ${fileId} fileName: ${fileName}, folder: ${folder} })`)

    if (!url) {
      throw new InvalidParamError('Url must be set')
    }

    if (!fileId) {
      throw new InvalidParamError('FileId must be set')
    }

    let path = `${folder}`
    path = path[path.length - 1] === '/' ? path : path + '/'
    path += fileName || new URL(url).pathname
    path = normalize(path)
    // нужна валидация
    let isExist = await FileSystem.exists(path)

    if (isOnline() && isExist) {
      isExist = await FileSystem.verification(url, path)
    }

    if (!isExist) {
      commit('removeFile', url)
      await dispatch('downloadFile', { url, path })
    }

    const dirEntry = await FileSystem.getDataDirectory()
    const file = await FileSystem.getFile({ dirEntry, filename: path })
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const localUrl = file.toURL() // window.Ionic.WebView.convertFileSrc(file.toURL())
    commit('setFile', { id: fileId, url, path, localUrl })
    return localUrl
  },

  getImage ({ dispatch }, { url, fileName = '', ...options }) {
    let folder = 'images'
    if (options.courseId) {
      folder += `/courses/${options.courseId}`
    }

    return dispatch('getFile', { url, fileName, folder })
  },

  getVideo ({ dispatch }, { url, fileId, fileName = '', ...options }) {
    if (!url) {
      throw new InvalidParamError('Url must be set')
    }

    if (!fileId) {
      throw new InvalidParamError('FileId must be set')
    }

    let folder = 'videos'
    if (options.courseId) {
      folder += `/courses/${options.courseId}`
    }

    if (!fileName) {
      fileName = `${fileId}.mp4`
    }

    return dispatch('getFile', { url, fileId, fileName, folder, ...options })
  },

  async remove (context, path) {
    const fileEntry = await FileSystem.resolveLocalFileSystemURL(path)
    return FileSystem.remove(fileEntry)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
// eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
