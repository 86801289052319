import ServiceAPI from '@web/api/modules/Service'

import {
  ServiceActionsTree,
  ServiceGettersTree,
  ServiceMutationsTree,
  ServiceState
} from '@web/store/types/modules/service'

const state: ServiceState = {
  all: {}
}

const getters: ServiceGettersTree = {
  getServiceById: state => id => state.all[id]
}

const mutations: ServiceMutationsTree = {
  receiveService (state, payload) {
    state.all[payload.id] = payload
  },
  receiveServices (state, payload) {
    payload.forEach(service => {
      state.all[service.id] = service
    })
  }
}

const actions: ServiceActionsTree = {
  fetchService ({ commit, dispatch }, payload) {
    return ServiceAPI.fetch(payload.id)
      .then(response => {
        commit('receiveService', response.data.service)

        if (response.data.course) {
          dispatch('course/setCourse', response.data.course, { root: true })
        }

        return response.data.service
      })
  },
  fetchCatalog ({ commit, dispatch }) {
    return ServiceAPI.catalog()
      .then(response => {
        return response.data.map(data => {
          commit('receiveService', data.service)

          if (data.course) {
            dispatch('course/setCourse', data.course, { root: true })
          }

          return data.service
        })
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
