import Mapper from './Mapper'
import { Schedule } from '@web/store/types/modules/schedule'

/* istanbul ignore next */
export default class ScheduleMapper extends Mapper {
  static toClient (data: Dictionary<string[]>): Dictionary<Schedule> {
    const schedules: Dictionary<Schedule> = {}
    for (const dayNumber in data) {
      data[dayNumber].forEach(time => {
        if (schedules[time]) {
          schedules[time].repeatDays = schedules[time].repeatDays.concat([Number(dayNumber)])
        } else {
          schedules[time] = {
            id: time,
            time: time,
            repeatDays: [Number(dayNumber)]
          }
        }
      })
    }
    return schedules
  }

  static toServer (data: Dictionary<Schedule>): Dictionary<string[]> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = {}

    Object.values(data).forEach(schedule => {
      schedule.repeatDays.forEach(dayNumber => {
        response[dayNumber] = response[dayNumber] ? response[dayNumber].concat([schedule.time]) : [schedule.time]
      })
    })

    return { repeatDays: response }
  }
}
