
import { defineComponent, computed } from 'vue'
import { Menu } from '@web/store/types/modules/menu'
import Analytics from '@web/services/Analytics/Analytics'
import { useRouter } from 'vue-router'
import AsExternalDocument from '@web/components/AsExternalDocument.vue'
import useClub from '@web/composition/useClub'
import ModalChooseClub from '@web/components.v2/Home/Club/ModalChooseClub.vue'

export default defineComponent({
  name: 'AsMenu',
  model: {
    prop: 'mini',
    event: 'minify'
  },
  components: {
    AsExternalDocument,
    ModalChooseClub
  },
  props: {
    menu: {
      type: Object as () => Menu,
      default: () => ({} as Menu)
    },
    status: {
      type: String,
      default: '-open'
    },
    minify: {
      type: Boolean,
      default: false
    },
    mini: {
      type: Boolean,
      default: false
    },
    type: {
      type: String as () => 'menu' | 'sidebar',
      default: 'menu'
    },
    class: {
      type: String,
      default: ''
    }
  },
  emits: ['minify', 'click:item'],
  setup (props, ctx) {
    const router = useRouter()
    const classes = computed(() => props.class)

    const {
      handlerClubLookAll,
      showModalChooseClub,
      communitiesModalChooseClub,
      handlerChooseClub
    } = useClub()

    function handlerMinify () {
      ctx.emit('minify', !props.mini)
    }

    function goTo (i, item) {
      ctx.emit('click:item', item)
      if (i === 'support') {
        Analytics.send({
          category: 'support_activity',
          action: 'open_support',
          label: props.type
        })
      }

      router.push(item.link)
    }

    return {
      classes,
      handlerMinify,
      goTo,
      handlerClubLookAll,
      showModalChooseClub,
      communitiesModalChooseClub,
      handlerChooseClub
    }
  }
})
