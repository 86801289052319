/* istanbul ignore file */
import client from '../client'
import MessageMapper from '@web/mapper/MessageMapper'
import ChatMapper from '@web/mapper/ChatMapper'
import HeaderMapper from '@web/mapper/HeaderMapper'
import HomeworkStatusMapper from '@web/mapper/HomeworkStatusMapper'
import HomeworkMapper from '@web/mapper/HomeworkMapper'

export default class Chat {
  static fetchAll () {
    return client.get('/chats')
      .then((response) => {
        return {
          data: response.data.map(ChatMapper.toClient),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static fetch ({ id }) {
    return client.get(`/chats/${id}`)
      .then((response) => {
        return {
          data: ChatMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static fetchMessages ({ id, sort = '-created_at', page = 1, perPage = 10 }) {
    return client.get(`/chats/${id}/messages`, {
      params: {
        sort,
        page,
        'per-page': perPage
      }
    })
      .then((response) => {
        return {
          data: response.data.map(MessageMapper.toClient),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static sendHomeworkMessage ({ streamId, userHomeworkStatusId, message }) {
    return client.post(`/user-course-streams/${streamId}/user-homework/${userHomeworkStatusId}`, HomeworkMapper.toServer(message))
      .then((response) => {
        return {
          data: {
            message: MessageMapper.toClient(response.data.chatMessage),
            homeworkStatus: HomeworkStatusMapper.toClient(response.data.userHomeworkStatus)
          },
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static sendMessage ({ id, message }) {
    return client.post(`/chats/${id}/messages`, message)
      .then((response) => {
        return {
          data: MessageMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static updateMessage ({ id, message }) {
    return client.put(`/chats/${id}/messages/${message.id}`, message)
      .then((response) => {
        return {
          data: MessageMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static updateRatingMessage ({ id, message }) {
    return client.put(`/chats/${id}/messages/${message.id}/rating`, MessageMapper.toServer(message))
      .then((response) => {
        return {
          data: MessageMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static updateViewedMessage ({ id, message }) {
    return client.put(`/chats/${id}/messages/${message.id}/viewed`, MessageMapper.toServer(message))
      .then((response) => {
        return {
          data: MessageMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }
}
