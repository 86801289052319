/* istanbul ignore file */
import { APP_VERSION, APPLICATION_TYPE, IS_WEB, APP_ID, IS_MOBILE } from '@web/common/config'
import GTM from '@web/services/Analytics/modules/GoogleTagManager'
import Amplitude from '@web/services/Analytics/modules/Amplitude'
import Firebase from '@web/services/Analytics/modules/Firebase'
import snakeCase from 'lodash.snakecase'
import { initClickEvent } from './utils'
import { Router } from 'vue-router'

class Analytics {
  static installed = false
  private static userId: number | null
  private static trafficType: 'external' | 'internal' = 'external'

  private constructor () {
    if (Analytics.installed) return
    Analytics.installed = true

    if (IS_MOBILE) {
      Amplitude.init()
      Firebase.init()
    }

    Analytics.setAppVersion(APP_VERSION)

    initClickEvent()
  }

  static init (): void {
    // eslint-disable-next-line no-new
    new Analytics()
  }

  private static pageData: Dictionary<string> = {}

  static setPageData (payload: Dictionary<unknown>): void {
    for (const key in payload) {
      Analytics.pageData[snakeCase(key)] = String(payload[key])
    }
  }

  static clearPageData (): void {
    Analytics.pageData = {}
  }

  static send (event: EventBase): void {
    const _event: EventBase = {
      ...{ traffic_type: Analytics.trafficType },
      application_id: APP_ID,

      application_type: APPLICATION_TYPE,
      ...Analytics.pageData,
      ...event
    }

    if (IS_WEB) {
      GTM.send(_event as EventBase)
    }

    if (IS_MOBILE) {
      const {
        action: type,
        ...properties
      } = _event
      Amplitude.logEvent({ type, properties })

      Firebase.send(_event)
    }
  }

  static setUserId (id: number | null): void {
    const _id = id === null ? '' : String(id)
    Analytics.userId = id

    if (IS_WEB) {
      GTM.setUserId(_id)
    }
    if (IS_MOBILE) {
      Amplitude.setUserId(_id)
      Firebase.setUserId(_id)
    }
  }

  static setTrafficType (trafficType: 'external' | 'internal'): void {
    Analytics.trafficType = trafficType
  }

  static setAppVersion (version: string): void {
    if (IS_MOBILE) {
      Amplitude.setVersionName(version)
      Firebase.setAppVersion(version)
    }
  }

  static pageView (router: Router): void {
    if (IS_WEB) {
      GTM.pageView(router)
    }

    if (IS_MOBILE) {
      Firebase.pageView(router)
      Amplitude.pageView(router)
    }
  }
}

export default Analytics
