/* istanbul ignore file */
import client from '../client'
import HeaderMapper from '@web/mapper/HeaderMapper'
import CookieMapper from '@web/mapper/CookieMapper'

export default class Cookie {
  static fetchCookies () {
    return client.get('/cookie')
      .then((response) => {
        return {
          data: CookieMapper.toClient(response.data),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static fetchPolicy () {
    return client.get('/cookie/get-policy')
      .then((response) => {
        return {
          data: response.data.policy,
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static updatePolicy (policy: CookiePolicy) {
    return client.post('/cookie/update-policy', policy)
      .then((response) => {
        return {
          data: response.data.policy,
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }
}
